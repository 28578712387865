import {
    Box,
    Card,
    CardActions,
    CardContent,
    Stack,
    TextField,
} from '@mui/material';
import React from 'react';
import { PREISCRIPTIONS_DOCUMENTS } from '../../../../../../service/const';
import Controls from '../../../../../../components/ReusableComponents/controls';

export const ObservationsForm = ({
    doc,
    documentValues,
    documentStatuses,
    handleChangeObservation,
    handleSubmit,
    isButtonEnabled,
    loading,
}) => (
    <Card
        sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            height: '76%%',
        }}>
        <CardContent
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '16px',
            }}>
            <Box>
                <TextField
                    value={documentValues[doc.document_preinscription_id] || ''}
                    onChange={(e) =>
                        handleChangeObservation(
                            doc.document_preinscription_id,
                            e.target.value
                        )
                    }
                    multiline
                    rows={3}
                    fullWidth
                    placeholder="Escriba sus observaciones"
                    label="Observaciones"
                    error={
                        documentStatuses[doc.document_preinscription_id] ===
                            PREISCRIPTIONS_DOCUMENTS.REJECTED &&
                        !(
                            documentValues[doc.document_preinscription_id] || ''
                        ).trim()
                    }
                    helperText={
                        documentStatuses[doc.document_preinscription_id] ===
                            PREISCRIPTIONS_DOCUMENTS.REJECTED &&
                        !(
                            documentValues[doc.document_preinscription_id] || ''
                        ).trim()
                            ? 'Observaciones requeridas para documentos rechazados'
                            : `Caracteres disponibles: ${
                                  (
                                      documentValues[
                                          doc.document_preinscription_id
                                      ] || ''
                                  ).length
                              }/100`
                    }
                    inputProps={{ maxLength: 100 }}
                />
            </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: 'center' }}>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                }}>
                <Controls.LoadingButton
                    loading={loading}
                    text="Guardar"
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={!isButtonEnabled(doc)}
                    onClick={() => handleSubmit(doc)}
                />
            </Stack>
        </CardActions>
    </Card>
);
