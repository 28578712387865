import React from 'react';
import { StyledActionButton } from '../../styles';

export default function ActionButton(props) {
    const { color, onClick, disabled, children } = props;

    return (
        <StyledActionButton color={color || 'primary'} onClick={onClick} disabled={disabled}>
            {children}
        </StyledActionButton>
    );
}
