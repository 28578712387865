import { combineReducers } from '@reduxjs/toolkit';

import fetch from './fetchSlice';
import operations from './operationsSlice';
import items from './itemsSlice';

export default combineReducers({
    fetch,
    operations,
    items,
});
