import React, { useState } from 'react';
import {
    Box,
    Typography,
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Collapse,
    Paper,
    Grid,
    Card,
    CardContent,
    CardHeader,
    Button,
} from '@mui/material';
import { Map, Marker } from 'pigeon-maps';
import {
    ExpandMore,
    ExpandLess,
    Phone,
    Email,
    School,
} from '@mui/icons-material';
import ModalComponent from '../../../../../../components/ReusableComponents/modal';
import DocumentNewPrescription from './DocumentNewPrescription';
import medalla1 from '../../../../../../assets/images/circle-1.png';
import medalla2 from '../../../../../../assets/images/circle-2.png';
import medalla3 from '../../../../../../assets/images/circle-3.png';
import medalla4 from '../../../../../../assets/images/circle-4.png';

const PostulationSummary = ({
    prescriptions,
    handleExportPDF,
    getStatusInfo,
    PostulationsRejected,
    preinscriptionsFiltered,
    cellphoneContact,
    postulationsPending,
}) => {
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [selectedPostulation, setSelectedPostulation] = useState(null);
    const [expandedStudent, setExpandedStudent] = useState(null);
    const [openModalNewPostulation, setOpenModalNewPostulation] =
        useState(false);
    const [rejectedPostulations, setRejectedPostulations] = useState([]);
    const [pendingPostulations, setPendingPostulations] = useState([]);
    const [confirmedPostulations, setConfirmedPostulations] = useState([]);
    const [cellphonePostulations, setCellphonePostulations] = useState([]);
    const [priorityPostulations, setPriorityPostulations] = useState([]);

    const handleStudentClick = (studentId) => {
        setExpandedStudent(expandedStudent === studentId ? null : studentId);
        const student = prescriptions.find(
            (student) => student.preinscription_id === studentId
        );
        setSelectedStudent(student);

        const rejected = student.postulations.filter(
            (postulation) => postulation.status === 0
        );
        const pending = student.postulations.filter(
            (postulation) => postulation.status === 1
        );
        const confirmed = student.postulations.filter(
            (postulation) => postulation.status === 2
        );
        const priority = student.postulations.map(
            (priorityrank) => priorityrank.priority
        );
        const cellphone = student.parent_phone;

        setRejectedPostulations(rejected);
        setPendingPostulations(pending);
        setConfirmedPostulations(confirmed);
        setCellphonePostulations(cellphone);
        setPriorityPostulations(priority);
        setSelectedPostulation(null);
    };
    const handlePostulationClick = (postulation) => {
        setSelectedPostulation(postulation);
    };

    const shouldShowButton = (postulations) => {
        // const countStatus0 = postulations.filter((p) => p.status === 0).length;
        const countStatus1 = postulations.filter((p) => p.status === 1).length;
        const countStatus2 = postulations.filter((p) => p.status === 2).length;

        if (countStatus2 > 0) {
            return false;
        }
        if (countStatus1 === 3) {
            return false;
        }

        return true;
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 0:
                return '#ff6060';
            case 1:
                return '#FFBF43';
            case 2:
                return '#00C483';
            default:
                return '#757575';
        }
    };

    const getChipText = (priority) => {
        switch (priority) {
            case 1:
                return (
                    <Box display="flex" alignItems="center">
                        <img
                            src={medalla1}
                            alt="Medalla 1"
                            style={{
                                width: '60px',
                                height: '60px',
                                marginRight: '8px',
                            }}
                        />
                    </Box>
                );
            case 2:
                return (
                    <Box display="flex" alignItems="center">
                        <img
                            src={medalla2}
                            alt="Medalla 2"
                            style={{
                                width: '60px',
                                height: '60px',
                                marginRight: '8px',
                            }}
                        />
                    </Box>
                );
            case 3:
                return (
                    <Box display="flex" alignItems="center">
                        <img
                            src={medalla3}
                            alt="Medalla 3"
                            style={{
                                width: '60px',
                                height: '60px',
                                marginRight: '8px',
                            }}
                        />
                    </Box>
                );
            case 4:
                return (
                    <Box display="flex" alignItems="center">
                        <img
                            src={medalla4}
                            alt="Medalla 4"
                            style={{
                                width: '60px',
                                height: '60px',
                                marginRight: '8px',
                            }}
                        />
                    </Box>
                );
            default:
                return null;
        }
    };

    const prescriptionsRejected = selectedStudent
        ? selectedStudent.postulations.filter(
              (postulation) => postulation.status === 0
          )
        : [];
    const rejectedCount = prescriptionsRejected.length;

    return (
        <Box display="flex" sx={{ height: '100%', padding: 2, gap: 2 }}>
            <ModalComponent
                OpenModal={openModalNewPostulation}
                setOpenModal={setOpenModalNewPostulation}
                title={'Postulaciones'}
                subtitle={'Agrega postulaciones'}
            >
                <DocumentNewPrescription
                    prescription={prescriptions}
                    setOpenModal={setOpenModalNewPostulation}
                    rejectedCount={rejectedCount}
                    PendingPostulations={postulationsPending}
                    preinscriptionsFiltered={preinscriptionsFiltered}
                    prescriptionsLevel={
                        selectedStudent
                            ? selectedStudent.preinscription_level
                            : 0
                    }
                    cellphonePostulations={cellphonePostulations}
                    confirmedPostulations={confirmedPostulations}
                    pendingPostulations={pendingPostulations}
                    PostulationsRejected={rejectedPostulations}
                />
            </ModalComponent>
            <Paper
                sx={{ width: '30%', padding: 2, borderRadius: 2, boxShadow: 3 }}
            >
                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: 'bold', color: '#333' }}
                >
                    Lista de Alumnos
                </Typography>
                <List>
                    {prescriptions.map((student) => (
                        <React.Fragment key={student.preinscription_id}>
                            <ListItem
                                button
                                onClick={() =>
                                    handleStudentClick(
                                        student.preinscription_id
                                    )
                                }
                                sx={{
                                    borderRadius: 2,
                                    mb: 1,
                                    bgcolor:
                                        expandedStudent ===
                                        student.preinscription_id
                                            ? '#f5f5f5'
                                            : 'transparent',
                                    '&:hover': { bgcolor: '#f5f5f5' },
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar src={student.avatar} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="subtitle1"
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            {student.name} {student.last_name}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                        >
                                            {student.postulations.length}{' '}
                                            postulaciones
                                        </Typography>
                                    }
                                />
                                {expandedStudent ===
                                student.preinscription_id ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </ListItem>
                            <Collapse
                                in={
                                    expandedStudent ===
                                    student.preinscription_id
                                }
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    {student.postulations
                                        .sort((a, b) => a.priority - b.priority)
                                        .map((postulation) => (
                                            <ListItem
                                                key={postulation.postulation_id}
                                                button
                                                onClick={() =>
                                                    handlePostulationClick(
                                                        postulation
                                                    )
                                                }
                                                sx={{
                                                    pl: 4,
                                                    borderRadius: 2,
                                                    bgcolor:
                                                        selectedPostulation?.postulation_id ===
                                                        postulation.postulation_id
                                                            ? '#e0f7fa'
                                                            : 'transparent',
                                                    borderBottom: `4px solid ${getStatusColor(
                                                        postulation.status
                                                    )}`,
                                                    '&:hover': {
                                                        bgcolor: '#e0f7fa',
                                                    },
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '12px 16px',
                                                    marginBottom: '8px',
                                                    transition:
                                                        'background-color 0.2s ease-in-out',
                                                }}
                                            >
                                                {getChipText(
                                                    postulation.priority
                                                )}
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        color: '#333',
                                                        marginLeft: '12px',
                                                    }}
                                                >
                                                    {postulation.schools.name}
                                                </Typography>
                                            </ListItem>
                                        ))}
                                </List>
                            </Collapse>
                        </React.Fragment>
                    ))}
                </List>
            </Paper>
            <Paper sx={{ flex: 1, padding: 2, borderRadius: 2, boxShadow: 3 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Información de la postulacion
                </Typography>
                {selectedStudent ? (
                    <>
                        {selectedStudent &&
                            shouldShowButton(selectedStudent.postulations) && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        setOpenModalNewPostulation(true)
                                    }
                                >
                                    Agregar Postulación
                                </Button>
                            )}
                        <Card sx={{ mb: 3 }}>
                            <CardHeader
                                title={
                                    <Typography
                                        variant="h6"
                                        sx={{ fontWeight: 'bold' }}
                                    >
                                        Datos del Alumno
                                    </Typography>
                                }
                            />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <div>
                                        {getChipText(priorityPostulations)}
                                    </div>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">
                                            <strong>Nombre:</strong>{' '}
                                            {selectedStudent.name}{' '}
                                            {selectedStudent.last_name}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>
                                                Fecha de Nacimiento:
                                            </strong>{' '}
                                            {selectedStudent.birth_date}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">
                                            <strong>Género:</strong>{' '}
                                            {selectedStudent.gender === '1'
                                                ? 'Masculino'
                                                : 'Femenino'}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>CURP:</strong>{' '}
                                            {selectedStudent.curp}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        <Card sx={{ mb: 3 }}>
                            <CardHeader
                                title={
                                    <Typography
                                        variant="h6"
                                        sx={{ fontWeight: 'bold' }}
                                    >
                                        Datos del Contacto
                                    </Typography>
                                }
                            />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">
                                            <Phone
                                                sx={{
                                                    verticalAlign: 'middle',
                                                    mr: 1,
                                                }}
                                            />
                                            {selectedStudent.parent_phone}
                                        </Typography>
                                        <Typography variant="body1">
                                            <Email
                                                sx={{
                                                    verticalAlign: 'middle',
                                                    mr: 1,
                                                }}
                                            />
                                            {selectedStudent.parent_email}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">
                                            <strong>Dirección:</strong>{' '}
                                            {selectedStudent.parent_home_street}
                                            ,{' '}
                                            {
                                                selectedStudent.parent_home_neighborhood
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        {selectedPostulation?.schools && (
                            <Card sx={{ mb: 3 }}>
                                <CardHeader
                                    title={
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="h6"
                                                sx={{ fontWeight: 'bold' }}
                                            >
                                                Información de la Escuela
                                            </Typography>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    sx={{ mr: 1 }}
                                                >
                                                    Prioridad:
                                                </Typography>
                                                {getChipText(
                                                    selectedPostulation.priority
                                                )}
                                            </Box>
                                        </Box>
                                    }
                                />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">
                                                <School
                                                    sx={{
                                                        verticalAlign: 'middle',
                                                        mr: 1,
                                                    }}
                                                />
                                                {
                                                    selectedPostulation.schools
                                                        .name
                                                }
                                            </Typography>
                                            <Typography variant="body1">
                                                <strong>Dirección:</strong>{' '}
                                                {
                                                    selectedPostulation.schools
                                                        .street
                                                }
                                                ,{' '}
                                                {
                                                    selectedPostulation.schools
                                                        .colony
                                                }
                                            </Typography>
                                            <Typography variant="body1">
                                                <strong>CCT:</strong>{' '}
                                                {
                                                    selectedPostulation.schools
                                                        .clave
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">
                                                <strong>Teléfono:</strong>{' '}
                                                {
                                                    selectedPostulation.schools
                                                        .cellphone
                                                }
                                            </Typography>
                                            <Typography variant="body1">
                                                <strong>Nivel:</strong>{' '}
                                                {
                                                    selectedPostulation.schools
                                                        .level
                                                }
                                            </Typography>
                                            <Typography variant="body1">
                                                <strong>Turno:</strong>{' '}
                                                {selectedPostulation.schools
                                                    .turn === 1
                                                    ? 'Matutino'
                                                    : 'Vespertino'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        )}
                        {selectedPostulation?.schools?.latitude && (
                            <Card sx={{ mb: 3 }}>
                                <CardHeader
                                    title={
                                        <Typography
                                            variant="h6"
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            Ubicación de la Escuela
                                        </Typography>
                                    }
                                />
                                <CardContent>
                                    <Box
                                        sx={{
                                            height: '300px',
                                            borderRadius: 2,
                                            overflow: 'hidden',
                                            position: 'relative',
                                        }}
                                    >
                                        {selectedPostulation?.schools
                                            ?.latitude &&
                                            selectedPostulation?.schools
                                                ?.longitude && (
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 8,
                                                        left: '50%',
                                                        transform:
                                                            'translateX(-50%)',
                                                        bgcolor:
                                                            'background.paper',
                                                        borderRadius: 2,
                                                        boxShadow: 3,
                                                        zIndex: 10,
                                                        p: 1,
                                                        width: '90%',
                                                        maxWidth: '600px',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Dirección de la escuela
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {
                                                            selectedPostulation
                                                                ?.schools
                                                                ?.street
                                                        }
                                                        , #
                                                        {
                                                            selectedPostulation
                                                                ?.schools
                                                                ?.inside_number
                                                        }{' '}
                                                        ,{' '}
                                                        {
                                                            selectedPostulation
                                                                ?.schools
                                                                ?.zipcode
                                                        }{' '}
                                                        ,{' '}
                                                        {
                                                            selectedPostulation
                                                                ?.schools
                                                                ?.location
                                                        }
                                                    </Typography>
                                                    <Box
                                                        display="flex"
                                                        justifyContent="center"
                                                        mt={0.5}
                                                    >
                                                        <Button
                                                            variant="text"
                                                            color="primary"
                                                            onClick={() => {
                                                                const url = `https://www.google.com/maps/dir/?api=1&destination=${selectedPostulation?.schools?.latitude},${selectedPostulation?.schools?.longitude}`;
                                                                window.open(
                                                                    url,
                                                                    '_blank'
                                                                );
                                                            }}
                                                        >
                                                            Ver indicaciones de
                                                            llegada
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            )}
                                        <Map
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                            }}
                                            defaultCenter={[
                                                (selectedPostulation?.schools
                                                    ?.latitude || 24.0226) +
                                                    0.0025,
                                                (selectedPostulation?.schools
                                                    ?.longitude || -104.6532) -
                                                    0.0005,
                                            ]}
                                            mouseEvents={true}
                                            touchEvents={true}
                                            attribution={false}
                                            zoomSnap={true}
                                            zoomDelta={0}
                                            defaultZoom={15}
                                        >
                                            {selectedPostulation?.schools
                                                ?.longitude &&
                                                selectedPostulation?.schools
                                                    ?.latitude && (
                                                    <Marker
                                                        width={50}
                                                        anchor={[
                                                            selectedPostulation
                                                                .schools
                                                                .latitude,
                                                            selectedPostulation
                                                                .schools
                                                                .longitude,
                                                        ]}
                                                    >
                                                        <img
                                                            src={
                                                                getStatusInfo(
                                                                    selectedPostulation.status
                                                                ).icon
                                                            }
                                                            width={40}
                                                            height={40}
                                                            alt="marker"
                                                        />
                                                    </Marker>
                                                )}
                                        </Map>
                                    </Box>
                                </CardContent>
                            </Card>
                        )}
                    </>
                ) : (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ height: '100%' }}
                    >
                        <Typography variant="h6" color="textSecondary">
                            Selecciona un alumno para ver más detalles.
                        </Typography>
                    </Box>
                )}
            </Paper>
        </Box>
    );
};

export default PostulationSummary;
