import { Box } from '@mui/material';
import Controls from '../../../../../../components/ReusableComponents/controls';
import { useEffect, useState } from 'react';

export const ViewFile = ({ file, onClose }) => {
    const [archives, setArchives] = useState(null);
    const [iframeKey, setIframeKey] = useState(0);

    useEffect(() => {
        if (file) {
            setIframeKey((prevKey) => prevKey + 1);
            const uniqueUrl = `${file}?t=${iframeKey + 1}`;
            setArchives(uniqueUrl);
        }
    }, [file]);
    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: { md: '77vh' },
                    position: 'relative',
                    width: { md: '130vh' },
                    overflow: 'hidden',
                    top: 2,
                }}>
                <Box sx={{ padding: 5 }}>
                    <iframe
                        key={iframeKey} // Usar la clave para forzar la renderización
                        style={{
                            width: '100%',
                            height: 'calc(100vh - 200px)',
                            border: 'none',
                        }}
                        src={archives}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '10px',
                    borderTop: '1px solid #e0e0e0',
                    background: '#ffff',
                    width: '100%',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    boxShadow: '0px -2px 4px rgba(0,0,0,0.05)',
                }}>
                <Controls.Button
                    text="Cerrar"
                    variant="contained"
                    color="primary"
                    onClick={onClose}
                    sx={{
                        mt: 2,
                        fontSize: '1.2rem !important',
                        padding: '10px 30px !important',
                    }}
                />
            </Box>
        </>
    );
};

export default ViewFile;
