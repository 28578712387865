import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';

export const ConfirmationModal = ({ open, onConfirm, onCancel }) => {
    return (
        <Dialog
            open={open}
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: 5,
                    width: '100%',
                    maxWidth: 400,
                    height: '100%',
                    maxHeight: 300,
                },
            }}>
            <DialogTitle
                sx={{
                    backgroundColor: 'warning.main',
                    textAlign: 'center',
                    fontSize: 20,
                }}
            />
            <DialogContent>
                <DialogTitle sx={{ padding: '16px 0px' }}>
                    <Typography variant="h6">¿Estás seguro?</Typography>
                </DialogTitle>
                <Box display="flex" alignItems="center">
                    <WarningIcon
                        sx={{
                            color: 'warning.main',
                            mr: 1,
                            fontSize: '4rem',
                        }}
                    />
                    <Typography variant="caption" sx={{ flexGrow: 1 }}>
                        No has confirmado los cambios realizados o no has
                        guardado la información, ¿estás seguro de que quieres
                        salir?
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box sx={{ mb: 2 }}>
                    <Button
                        variant="contained"
                        color="warning"
                        fullWidth
                        size="small"
                        onClick={onCancel}>
                        Cancelar
                    </Button>
                </Box>
                <Box sx={{ mb: 2 }}>
                    <Button color="warning" onClick={onConfirm}>
                        Salir sin guardar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};
