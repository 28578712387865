import { Box, Chip, Divider, Stack } from '@mui/material';
import React, { useState } from 'react';
import Controls from '../../../../components/ReusableComponents/controls';
import { PREISCRIPTIONS_STATUS } from '../../../../service/const';
import { Cancel, CheckCircleOutline, Schedule } from '@material-ui/icons';
import { updatePostulation } from '../../../store/operationsSlice';
import { useDispatch } from 'react-redux';
import { useFeedback } from '../../../../hooks';

export const Status = ({ onClosePopover, selectedStudent }) => {
    ////////////////////////// LOCAL STATE //////////////////////////

    const [status, setStatus] = useState(selectedStudent?.status);
    const [loading, setLoading] = useState(false);

    const estados = [
        {
            label: 'Aceptar',
            value: PREISCRIPTIONS_STATUS.ACCEPTED,
            icon: <CheckCircleOutline />,
            color: 'success',
        },
        {
            label: 'Pendiente',
            value: PREISCRIPTIONS_STATUS.CREATED,
            icon: <Schedule />,
            color: 'warning',
        },
        {
            label: 'Rechazar',
            value: PREISCRIPTIONS_STATUS.REJECTED,
            icon: <Cancel />,
            color: 'error',
        },
    ];
    //////////////////////////// SHARED STATE //////////////////////////
    const dispath = useDispatch();
    const feedbackApp = useFeedback();

    //////////////////////////// ACTIONS ////////////////////////////

    const handleChangeStatus = (newStatus) => {
        setStatus(newStatus);
    };

    const handleSave = async () => {
        setLoading(true);

        await dispath(
            updatePostulation({
                postulationId: selectedStudent?.postulation_id,
                data: {
                    status: status,
                },
            })
        )
            .unwrap()
            .then(() => {
                onClosePopover();
                feedbackApp.showFeedback({
                    title: 'Estado de la postulacion cambiada',
                    severity: 'success',
                });
            })
            .catch((error) => {
                onClosePopover();
                feedbackApp.showFeedback({
                    title: error.feedback.title,
                    severity: 'error',
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    /////////////////////////// RENDER ///////////////////////////
    return (
        <>
            <Box
                sx={{
                    padding: 2,
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                    }}>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                        divider={<Divider orientation="vertical" flexItem />}
                        sx={{ marginY: 2 }}>
                        {estados
                            .filter(
                                (estado) =>
                                    estado.value !== selectedStudent?.status
                            )
                            .map((estado) => (
                                <Chip
                                    key={estado.value}
                                    icon={estado.icon}
                                    color={estado.color}
                                    label={estado.label}
                                    variant={
                                        status === estado.value
                                            ? 'contained'
                                            : 'outlined'
                                    }
                                    onClick={() =>
                                        handleChangeStatus(estado.value)
                                    }
                                />
                            ))}
                    </Stack>
                    <Divider flexItem />
                </Box>
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        marginTop: 2,
                    }}>
                    <Controls.Button
                        size="small"
                        variant="outlined"
                        text="Cerrar"
                        color="primary"
                        onClick={onClosePopover}
                    />
                    <Controls.LoadingButton
                        loading={loading}
                        size="small"
                        variant="contained"
                        text="Guardar"
                        color="primary"
                        disabled={status === selectedStudent?.status}
                        onClick={handleSave}
                    />
                </Stack>
            </Box>
        </>
    );
};
