import { createSelector, createSlice } from '@reduxjs/toolkit';
import { selectAllPostulations } from '../../store/slices/entities/postulations';
import { selectAllPreinscriptions } from '../../store/slices/entities/preinscriptions';
import { selectAllDocumentsPreinscriptions } from '../../store/slices/entities/documents_preinscriptions';

const emptyState = [];

export const itemsSlice = createSlice({
    name: 'items',
    initialState: emptyState,
    reducers: {
        addItemsPostulations: (state, action) => {
            return action.payload;
        },
    },

    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', () => {
            return emptyState;
        });
    },
});

export const { addItemsPostulations } = itemsSlice.actions;

export default itemsSlice.reducer;

export const selectPostulationsWithInclues = (state) =>
    state.preinscriptionsUI.items;

export const selectPostulationsFullData = createSelector(
    selectAllPostulations,
    selectAllPreinscriptions,
    selectAllDocumentsPreinscriptions,
    (postulations, preinscriptions, documents) => {
        return postulations.map((postulation) => {
            const preinscription = preinscriptions.find(
                (preinscription) =>
                    preinscription.preinscription_id ===
                    postulation.preinscription_id
            );

            const relatedDocuments = documents?.filter(
                (doc) => doc.preinscription_id === postulation.preinscription_id
            );

            return {
                ...postulation,
                preinscription,
                documents: relatedDocuments,
            };
        });
    }
);
