import React from 'react';
import { Box, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Field, Form, Formik } from 'formik';
import { UploadFileTwoTone } from '@mui/icons-material';
import { validationSchema } from './validations';

const FormSection = ({ isMobile, handleSubmit, setShowContainer, uuid }) => {
    return (
        <Box className="mt-4">
            <Formik
                initialValues={{ identifier: '' || uuid }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}>
                {({ errors, touched, isValid, isSubmitting }) => (
                    <Form>
                        <Box display="flex" alignItems="center" gap={2}>
                            <Field
                                name="identifier"
                                as={TextField}
                                variant="outlined"
                                color="primary"
                                label="Ingresa tu folio de seguimiento o número de teléfono"
                                error={Boolean(
                                    errors.identifier && touched.identifier
                                )}
                                helperText={
                                    errors.identifier && touched.identifier
                                        ? errors.identifier
                                        : ''
                                }
                                fullWidth
                                margin="none"
                                sx={{ height: '3.5rem' }}
                                disabled={isSubmitting}
                            />
                            {!isMobile && (
                                <LoadingButton
                                    loadingPosition="start"
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    loading={isSubmitting}
                                    disabled={isSubmitting || !isValid}
                                    startIcon={<UploadFileTwoTone />}
                                    sx={{
                                        height: '3.5rem',
                                        minWidth: '9.375rem',
                                    }}>
                                    {isSubmitting
                                        ? 'Procesando...'
                                        : 'Consultar'}
                                </LoadingButton>
                            )}
                        </Box>

                        {!isMobile && (
                            <Box className="mt-5">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    sx={{ height: '3.5rem' }}
                                    onClick={() => setShowContainer('inicio')}>
                                    Regresar
                                </Button>
                            </Box>
                        )}

                        {isMobile && (
                            <Box
                                display="flex"
                                alignItems="center"
                                gap={2}
                                className="mt-5">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                        height: '3.5rem',
                                        minWidth: '9.375rem',
                                        flex: 1,
                                    }}
                                    onClick={() => setShowContainer('inicio')}>
                                    Regresar
                                </Button>
                                <LoadingButton
                                    loadingPosition="start"
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    loading={isSubmitting}
                                    disabled={isSubmitting || !isValid}
                                    startIcon={<UploadFileTwoTone />}
                                    sx={{
                                        height: '3.5rem',
                                        minWidth: '9.375rem',
                                        flex: 1,
                                    }}>
                                    {isSubmitting
                                        ? 'Procesando...'
                                        : 'Consultar'}
                                </LoadingButton>
                            </Box>
                        )}
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default FormSection;
