import {
    Alert,
    Box,
    Button,
    CardActions,
    Divider,
    IconButton,
} from '@mui/material';
import React, { useState } from 'react';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import SchoolIcon from '../../../../assets/iconos/school-svg-com.svg';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import ListItemText from '@mui/material/ListItemText';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import CloseDialog from '../Summary/components/dialog';

import Connection from '../../../../../service/Connection';
import { useFeedback } from '../../../../../hooks';

import { Map, Marker } from 'pigeon-maps';
import Feedback from '../../../../../service/Feedback';

import LoadingButton from '@mui/lab/LoadingButton';

import Modal from '@mui/material/Modal';

import VisibilityIcon from '@mui/icons-material/Visibility';

import CloseIcon from '@mui/icons-material/Close';

import Tooltip from '@mui/material/Tooltip';
import {
    parentescoCheck,
    nacionalityCheck,
    genderCheck,
    priorityCheck,
    gradeCheck,
} from '../../../../../libs/utils';
import { grey, blue } from '@mui/material/colors';

const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    maxWidth: 800,
    color: theme.palette.text.primary,
}));

export const Summary = ({ data, handleBack, uuid }) => {
    const primary = grey[800];
    const bluep = blue[600];

    const feedbackApp = useFeedback();
    let FeedbackService = new Feedback();

    const [checked, setChecked] = useState(true);

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') return;
        setOpen(false);
    };

    const [selectFileUrl, setSelectedFileUrl] = useState();

    const [openDoc, setOpenDoc] = useState(false);
    const handleOpenDoc = () => setOpenDoc(true);
    const handleCloseDoc = () => setOpenDoc(false);

    const [loading, setLoading] = useState(false);

    const [isDisabled, setIsDisabled] = useState(true);

    const [respData, setRespData] = useState(null);

    const transformInitialLetter = (data) => {
        return data.replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const latitude = parseFloat(data.latitude);
    const longitude = parseFloat(data.longitude);

    const isForeign = nacionalityCheck(data.student.is_foreign);
    const isInmigrant = nacionalityCheck(data.student.is_immigrant);
    const isIndigenous = nacionalityCheck(data.student.is_indigenous);
    const isStranger = nacionalityCheck(data.student.is_stranger);
    const parentGender = genderCheck(data.parent?.parent_gender);
    const studentGender = genderCheck(data.student.gender);
    const grade = gradeCheck(data.student.grade);

    const confirmPreInscription = async () => {
        setLoading(true);

        let postulationsP = data.postulations.map((postulation) => ({
            priority: postulation.priority,
            school_id: postulation.school.school_id,
            siblings_number: postulation.siblings_number,
        }));

        const preinscripcionData = {
            ...data,
            postulations: postulationsP,
            uuid: uuid,
        };

        let report = 'preinscription-voucher';
        let format = 'pdf';

        try {
            await Connection.setPreRegistration(preinscripcionData).then(
                (res) => {
                    const uuidresp = {
                        uuid: res.data.data.preinscription_id,
                    };

                    Connection.getExportablePreinscription(
                        report,
                        format,
                        uuidresp
                    )
                        .then((resp) => {
                            setRespData(resp);

                            const url = window.URL.createObjectURL(
                                new Blob([resp.data])
                            );
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute(
                                'download',
                                `solicitud_de_preinscripcion.pdf`
                            );
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);

                            handleOpen();
                            setLoading(false);
                        })
                        .catch((error) => {
                            let feedbackError =
                                FeedbackService.getMessage(error);

                            feedbackApp.showFeedback({
                                title: feedbackError.title,
                            });
                        });

                    feedbackApp.showFeedback({
                        title: 'Preinscripción realizada',
                    });
                }
            );
        } catch (error) {
            let feedbackError = FeedbackService.getMessage(error);

            feedbackApp.showFeedback({
                title: feedbackError.title,
            });

            setLoading(false);
        }
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);

        setIsDisabled(!isDisabled);
    };

    const handleViewFile = (fileObject) => {
        if (fileObject && fileObject.file instanceof File) {
            const fileUrl = URL.createObjectURL(fileObject.file);

            setSelectedFileUrl(fileUrl);
            handleOpenDoc(true);
        } else {
            feedbackApp.showFeedback({
                title: 'No se pudo visualizar el archivo',
                severity: 'error',
            });
        }
    };

    return (
        <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 2 }}>
            <Divider>
                <Typography variant="h5" align="center" gutterBottom>
                    Resumen
                </Typography>
            </Divider>
            <Typography
                variant="body1"
                align="center"
                gutterBottom
                sx={{ mb: 2, color: 'gray' }}
            >
                En este apartado podrás visualizar la información personal
                básica del contacto, información del estudiante, documentos
                cargados y postulaciones para una revisión final.
            </Typography>

            <div sx={{ m: 10, p: 10 }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 700,
                        fontSize: 20.25,
                        margin: 0,
                        color: bluep,
                    }}
                >
                    Datos del Alumno
                </Typography>

                <Typography
                    sx={{ fontWeight: 700, fontSize: 18, color: primary }}
                >
                    Datos Personales
                </Typography>
            </div>

            <StyledPaper
                sx={{
                    my: 1,
                    mx: 'auto',
                    p: 2,
                }}
            >
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs zeroMinWidth>
                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={transformInitialLetter(
                                        data.student?.name
                                    )}
                                    secondary="Nombre"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.student.last_name}
                                    secondary="Apellido paterno"
                                />
                            </Grid>
                        </Grid>

                        <Box mb={2} />

                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.student.second_last_name}
                                    secondary="Apellido materno"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.student.born_state_name}
                                    secondary="Estado de nacimiento"
                                />
                            </Grid>
                        </Grid>

                        <Box mb={2} />

                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={
                                        data.student.born_municipality_name
                                    }
                                    secondary="Municipio de nacimiento"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.student.born_city_name}
                                    secondary="Ciudad de nacimiento"
                                />
                            </Grid>
                        </Grid>

                        <Box mb={2} />

                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.student.birth_date}
                                    secondary="Fecha de nacimiento"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.student.size}
                                    secondary="Talla"
                                />
                            </Grid>
                        </Grid>

                        <Box mb={2} />

                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={studentGender.label}
                                    secondary="Género"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={grade.label}
                                    secondary="Grado"
                                />
                            </Grid>
                        </Grid>

                        <Box mb={2} />

                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} sm={4} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={isIndigenous.label}
                                    secondary="Es indígena"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={isStranger.label}
                                    secondary="Es extranjero"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={isInmigrant.label}
                                    secondary="Es Inmigrante"
                                />
                            </Grid>
                        </Grid>

                        <Box mb={2} />

                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} sm={12} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.student.curp}
                                    secondary="Curp"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </StyledPaper>

            <div sx={{ m: 10, p: 10 }}>
                <Typography
                    sx={{ fontWeight: 700, fontSize: 18, color: primary }}
                >
                    Datos del domicilio
                </Typography>
            </div>

            <StyledPaper
                sx={{
                    my: 1,
                    mx: 'auto',
                    p: 2,
                }}
            >
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs zeroMinWidth>
                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.student.home_street}
                                    secondary="Calle"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.student.home_external_number}
                                    secondary="Número exterior"
                                />
                            </Grid>
                        </Grid>

                        <Box mb={1} />

                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.student.home_internal_number}
                                    secondary="Número interior"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.student.home_neighborhood}
                                    secondary="Colonia"
                                />
                            </Grid>
                        </Grid>

                        <Box mb={1} />

                        <Grid container spacing={2} align="center">
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                align="center"
                                style={{ padding: 0 }}
                            >
                                <ListItemText
                                    primary={data.student.home_zipcode}
                                    secondary="Código postal"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.student.address_state_name}
                                    secondary="Estado"
                                />
                            </Grid>
                        </Grid>

                        <Box mb={1} />

                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={
                                        data.student.address_municipality_name
                                    }
                                    secondary="Municipio"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.student.address_city_name}
                                    secondary="Ciudad"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </StyledPaper>

            <div sx={{ m: 10, p: 10 }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 700,
                        fontSize: 20.25,
                        margin: 0,
                        color: bluep,
                    }}
                >
                    Datos del Contacto
                </Typography>

                <Typography
                    sx={{ fontWeight: 700, fontSize: 18, color: primary }}
                >
                    Datos Personales
                </Typography>
            </div>

            <StyledPaper
                sx={{
                    my: 1,
                    mx: 'auto',
                    p: 2,
                }}
            >
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs>
                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={transformInitialLetter(
                                        data.parent?.parent_name
                                    )}
                                    secondary="Nombre"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.parent?.parent_last_name}
                                    secondary="Apellido Paterno"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={
                                        data.parent?.parent_second_last_name
                                    }
                                    secondary="Apellido materno"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.parent?.parent_email}
                                    secondary="Email"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.parent.parent_phone}
                                    secondary="Celular"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.parent?.parent_birth_date}
                                    secondary="Fecha de nacimiento"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={parentGender.label}
                                    secondary="Genero"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={`${
                                        parentescoCheck(
                                            data.parent?.relationship
                                        ).label
                                    }`}
                                    secondary="Relacion/Parentesco"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.parent?.parent_home_street}
                                    secondary="Calle"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={
                                        data.parent?.parent_home_external_number
                                    }
                                    secondary="Numero Exterior"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={
                                        data.parent?.parent_home_internal_number
                                    }
                                    secondary="Numero Interior"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={
                                        data.parent?.parent_home_neighborhood
                                    }
                                    secondary="Colonia"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.parent?.parent_home_zipcode}
                                    secondary="Codigo postal"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={
                                        data.parent?.parent_birth_state_name
                                    }
                                    secondary="Estado"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={
                                        data.parent
                                            ?.parent_birth_minicipality_name
                                    }
                                    secondary="Municipio"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={
                                        data.parent?.parent_birth_city_name
                                    }
                                    secondary="Ciudad"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </StyledPaper>

            <div sx={{ m: 10, p: 10 }}>
                <Typography
                    sx={{ fontWeight: 700, fontSize: 18, color: primary }}
                >
                    Datos del trabajo
                </Typography>
            </div>

            <StyledPaper
                sx={{
                    my: 1,
                    mx: 'auto',
                    p: 2,
                }}
            >
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs>
                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.parent.work_street}
                                    secondary="Calle"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.parent.work_external_number}
                                    secondary="Numero exterior"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.parent.work_internal_number}
                                    secondary="Numero interior"
                                />
                            </Grid>

                            <Grid item xs={12} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.parent.work_neighborhood}
                                    secondary="Colonia"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText
                                    primary={data.parent.work_zipcode}
                                    secondary="Codigo postal"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </StyledPaper>

            <div sx={{ m: 10, p: 10 }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 700,
                        fontSize: 20.25,
                        margin: 0,
                        color: bluep,
                    }}
                >
                    Documentos
                </Typography>
            </div>

            <StyledPaper
                sx={{
                    my: 1,
                    mx: 'auto',
                    p: 2,
                }}
            >
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs>
                        <Grid item xs={12} sm={12}>
                            <Stack direction="row" spacing={1} flexWrap="wrap">
                                {Object.keys(data.archives).length > 0 ? (
                                    Object.keys(data.archives).map((key) => (
                                        <div key={key}>
                                            <Chip label={`${key}`} />

                                            <Tooltip title="Visualizar documento">
                                                <IconButton>
                                                    <VisibilityIcon
                                                        className="cursor-pointer text-blue-500 hover:text-blue-700 transition duration-300 ease-in-out"
                                                        onClick={() =>
                                                            handleViewFile(
                                                                data.archives[
                                                                    key
                                                                ]
                                                            )
                                                        }
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    ))
                                ) : (
                                    <Typography align="center">
                                        Documentos no cargados
                                    </Typography>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </StyledPaper>

            <div sx={{ m: 10, p: 10 }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 700,
                        fontSize: 20.25,
                        margin: 0,
                        color: bluep,
                    }}
                >
                    Postulaciones
                </Typography>
            </div>

            <StyledPaper
                sx={{
                    my: 1,
                    mx: 'auto',
                    p: 2,
                }}
            >
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs>
                        <Grid container spacing={2}>
                            {data.postulations.map((postulation, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Card
                                        sx={{
                                            width: '100%',
                                            height: 240,
                                            marginBottom: 5,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <center>
                                            <Grid
                                                container
                                                rowSpacing={1}
                                                columnSpacing={{
                                                    xs: 1,
                                                    sm: 2,
                                                    md: 3,
                                                }}
                                                sx={{ pb: 1 }}
                                            >
                                                <Grid item xs={12}>
                                                    <Avatar>
                                                        <img
                                                            src={
                                                                postulation.icon
                                                            }
                                                            width={50}
                                                            height={50}
                                                            alt="marker"
                                                        />
                                                    </Avatar>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Typography component="div">
                                                        {
                                                            postulation.school
                                                                .name
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </center>

                                        <CardContent style={{ padding: 0 }}>
                                            <center>
                                                <Grid
                                                    container
                                                    rowSpacing={1}
                                                    columnSpacing={{
                                                        xs: 1,
                                                        sm: 2,
                                                        md: 3,
                                                    }}
                                                >
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            Dirección:{' '}
                                                            {
                                                                postulation
                                                                    .school
                                                                    .colony
                                                            }
                                                        </Typography>

                                                        <Typography>
                                                            Codigo Postal:{' '}
                                                            {
                                                                postulation
                                                                    .school
                                                                    .zip_code
                                                            }
                                                        </Typography>

                                                        <Typography>
                                                            Estado:{' '}
                                                            {
                                                                postulation
                                                                    .school
                                                                    .state
                                                            }
                                                        </Typography>

                                                        <Typography>
                                                            Teléfono:{' '}
                                                            {
                                                                postulation
                                                                    .school
                                                                    .phone
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </center>
                                        </CardContent>

                                        <div style={{ marginTop: 2 }}>
                                            <center>
                                                <Typography>
                                                    <Chip
                                                        label={`${
                                                            priorityCheck(
                                                                postulation.priority
                                                            ).label
                                                        }`}
                                                    />
                                                </Typography>
                                            </center>
                                        </div>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container wrap="nowrap">
                    <Map
                        height={400}
                        defaultCenter={[latitude, longitude]}
                        defaultZoom={11}
                    >
                        {data.postulations.map((postulation, index) => (
                            <Marker
                                width={50}
                                anchor={[
                                    postulation.school.latitude,
                                    postulation.school.longitude,
                                ]}
                            >
                                <Chip
                                    color="success"
                                    label={postulation.school.name}
                                />

                                <img
                                    src={postulation.icon}
                                    width={50}
                                    height={50}
                                    alt="marker"
                                />
                            </Marker>
                        ))}
                    </Map>
                </Grid>
            </StyledPaper>

            <Alert severity="info">
                Se descargará tu solicitud de preinscripción.
            </Alert>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 2,
                    maxWidth: 800,
                }}
                align="center"
            >
                <Button type="default" onClick={handleBack}>
                    Regresar
                </Button>

                <Button
                    size="small"
                    sx={{
                        color: 'black',
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                        padding: '0',
                    }}
                    target="_blank"
                    href={'/TerminosCondiciones'}
                >
                    Términos y condiciones
                </Button>

                <FormControlLabel
                    required
                    control={<Checkbox onChange={handleChange} />}
                    label="Aceptar terminos y condiciones"
                />

                <LoadingButton
                    loading={loading}
                    loadingIndicator="Cargando..."
                    sx={{ background: 'green' }}
                    variant="contained"
                    onClick={confirmPreInscription}
                    disabled={isDisabled}
                >
                    <span>Finalizar</span>
                </LoadingButton>
            </Box>

            <CloseDialog
                open={open}
                handleClose={handleClose}
                uuid={uuid}
                setRespData={respData}
            ></CloseDialog>

            <Modal
                open={openDoc}
                onClose={handleCloseDoc}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 2,
                        }}
                    >
                        <Typography
                            id="modal-title"
                            variant="h6"
                            component="h2"
                        >
                            Visualizar Archivo
                        </Typography>
                        <IconButton onClick={handleCloseDoc}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <iframe
                        src={selectFileUrl}
                        width="100%"
                        height="600px"
                        title="PDF Viewer"
                    />
                </Box>
            </Modal>
        </Box>
    );
};
