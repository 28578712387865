import React, { useState, useEffect } from 'react';
import { Button, Typography, Box, Modal, IconButton } from '@mui/material';
import 'antd/dist/antd.css';

import {
    DeleteOutline,
    FileUploadOutlined,
    UploadFile,
    VisibilityOutlined,
    Close as CloseIcon,
} from '@mui/icons-material';
import { Progress, Space, Tooltip, Upload } from 'antd';
import Connection from '../../../../../service/Connection';
import { useFeedback } from '../../../../../hooks';
import { useShowContainer } from '../../../../services/Container';

const { Dragger } = Upload;

export const StudentArchives = ({
    data,
    setData,
    handleBack,
    handleNext,
    uuid,
}) => {
    /////////////////////////// SHARED STATE ///////////////////////////
    const feedbackApp = useFeedback();

    const { setPreinscription } = useShowContainer();

    /////////////////////////// LOCAL STATE ///////////////////////////
    const [isUploading, setIsUploading] = useState(false);
    const [files, setFiles] = useState(data.archives || {});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedFileUrl, setSelectedFileUrl] = useState('');

    const documentTypes = [
        { key: 'curp', label: 'CURP' },
        { key: 'acta_nacimiento', label: 'Acta de nacimiento' },
        { key: 'comprobante_domicilio', label: 'Comprobante de domicilio' },
        {
            key: 'certificado_nivel_anterior',
            label: 'Certificado (nivel anterior)',
        },
        { key: 'boleta_grado_anterior', label: 'Boleta (grado anterior)' },
    ];

    /////////////////////////// SIDE EFFECTS ///////////////////////////

    useEffect(() => {
        setFiles(data.archives || {});
        setPreinscription(data || {});
    }, [data.archives]);

    /////////////////////////// FUNCTIONS ///////////////////////////

    const handleUploadFile = async ({
        file,
        documentTypeKey,
        documentTypeLabel,
    }) => {
        const newFileName = `${uuid}_${documentTypeKey}.pdf`;
        const renamedFile = new File([file], newFileName, { type: file.type });

        const updatedData = {
            ...data,
            archives: {
                ...data.archives,
                [documentTypeLabel]: renamedFile,
            },
        };
        setData(updatedData);
        setPreinscription(updatedData);

        const formData = new FormData();
        formData.append('file', renamedFile);
        setIsUploading(true);

        Connection.UploadPrescriptionFile(formData, {
            onUploadProgress: (event) => {
                const progress = event.loaded / event.total;

                const updateFilesProgress = () => ({
                    [documentTypeLabel]: {
                        file: renamedFile,
                        Progress: progress,
                    },
                });

                setData((prevData) => ({
                    ...prevData,
                    archives: {
                        ...prevData.archives,
                        ...updateFilesProgress(),
                    },
                }));

                setFiles((prevFiles) => ({
                    ...prevFiles,
                    ...updateFilesProgress(),
                }));

                if (progress === 1) {
                    setIsUploading(false);
                    feedbackApp.showFeedback({
                        title: 'Archivo subido exitosamente',
                        severity: 'success',
                    });
                }
            },
        });
    };

    /////////////////////////// HANDLERS ///////////////////////////

    const handleDeleteFile = (label, documentTypeKey) => {
        const newFileName = `${uuid}_${documentTypeKey}.pdf`;

        Connection.DeletePrescriptionFile({ filename: newFileName })
            .then(() => {
                setFiles((prevFiles) => {
                    const newFiles = { ...prevFiles };
                    delete newFiles[label];
                    return newFiles;
                });

                setData((prevData) => {
                    const newArchives = { ...prevData.archives };
                    delete newArchives[label];
                    return {
                        ...prevData,
                        archives: newArchives,
                    };
                });

                feedbackApp.showFeedback({
                    title: 'Archivo eliminado exitosamente',
                    severity: 'success',
                });
            })
            .catch((error) => {
                console.error('Error deleting file:', error);
                feedbackApp.showFeedback({
                    title: 'Error al eliminar el archivo',
                    severity: 'error',
                });
            });
    };

    const handleViewFile = (fileObject) => {
        if (fileObject && fileObject.file instanceof File) {
            const fileUrl = URL.createObjectURL(fileObject.file);
            setSelectedFileUrl(fileUrl);
            setIsModalOpen(true);
        } else {
            feedbackApp.showFeedback({
                title: 'No se pudo visualizar el archivo',
                severity: 'error',
            });
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedFileUrl('');
    };

    return (
        <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 2 }}>
            <Box className="header">
                <Typography
                    variant="h5"
                    align="center"
                    gutterBottom
                    pt={2}
                    pb={2}
                    sx={{ color: '#919399', fontWeight: 'bold' }}>
                    📄 Registro de Documentos
                </Typography>

                <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ color: '#919399', fontWeight: 'bold' }}>
                    Antes de comenzar, ten en cuenta lo siguiente:
                </Typography>

                <Typography
                    variant="body2"
                    gutterBottom
                    pt={2}
                    pl={2}
                    sx={{ color: '#919399' }}>
                    - Los archivos deben estar en formato{' '}
                    <span style={{ color: '#000000', fontWeight: 'bold' }}>
                        PDF
                    </span>{' '}
                    <span style={{ color: '#919399' }}>
                        y que no excedan los{' '}
                        <span style={{ color: '#000000', fontWeight: 'bold' }}>
                            3 MB
                        </span>{' '}
                        de tamaño.
                    </span>{' '}
                    <br />
                    - Asegúrate de tener los documentos listos si planeas
                    subirlos ahora.
                    <br />-{' '}
                    <span style={{ color: '#000000', fontWeight: 'bold' }}>
                        Subir archivos es opcional
                    </span>
                    ; Si decides no subir los archivos en este momento, podrás
                    hacerlo después.
                </Typography>

                <Typography
                    variant="body1"
                    align="center"
                    gutterBottom
                    pt={2}
                    style={{ color: '#919399' }}>
                    📤 Por favor, sube los siguientes documentos:
                </Typography>
            </Box>

            <Box>
                {documentTypes.map(({ key, label }) => (
                    <div key={key} style={{ marginBottom: '20px' }}>
                        <Typography
                            pb={2}
                            pt={2}
                            variant="h6"
                            align="center"
                            sx={{
                                color: '#919399',
                                fontWeight: '700',
                            }}>
                            {label}
                        </Typography>
                        {files[label] ? (
                            <Space
                                className="w-full p-4 rounded-md shadow-xl border border-solid border-[#00C483] transition duration-300 ease-in-out transform hover:scale-100"
                                direction="vertical">
                                <div className="flex justify-between items-center w-full">
                                    <div className="flex items-center space-x-4">
                                        <UploadFile sx={{ color: '#00C483' }} />
                                        <Typography>{label}.pdf</Typography>

                                        <Tooltip
                                            title="Visualizar archivo"
                                            color="blue"
                                            className="hidden xl:block">
                                            <VisibilityOutlined
                                                className="cursor-pointer text-blue-500 hover:text-blue-700 transition duration-300 ease-in-out"
                                                onClick={() =>
                                                    handleViewFile(files[label])
                                                }
                                            />
                                        </Tooltip>
                                    </div>
                                    <div className="flex space-x-2">
                                        <Tooltip
                                            title="Eliminar archivo"
                                            color="red">
                                            <DeleteOutline
                                                className="cursor-pointer text-red-500 hover:text-red-700 transition duration-300 ease-in-out"
                                                onClick={() =>
                                                    handleDeleteFile(label, key)
                                                }
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                                <Progress
                                    percent={Math.round(
                                        files[label].Progress * 100
                                    )}
                                />
                            </Space>
                        ) : (
                            <div className="transition duration-300 ease-in-out transform hover:scale-105">
                                <Dragger
                                    customRequest={({
                                        file,
                                        onError,
                                        onSuccess,
                                    }) => {
                                        if (file.size / 1024 / 1024 > 3) {
                                            onError(
                                                'El archivo excede el tamaño máximo de 3 MB.'
                                            );
                                            feedbackApp.showFeedback({
                                                title: 'El archivo excede el tamaño máximo de 3 MB.',
                                                severity: 'error',
                                            });
                                            return;
                                        }
                                        if (file.type !== 'application/pdf') {
                                            onError('El archivo no es un PDF.');
                                            feedbackApp.showFeedback({
                                                title: 'El archivo no es un PDF.',
                                                severity: 'error',
                                            });
                                            return;
                                        }
                                        handleUploadFile({
                                            file,
                                            documentTypeKey: key,
                                            documentTypeLabel: label,
                                        })
                                            .then(onSuccess)
                                            .catch(onError);
                                    }}
                                    maxCount={1}
                                    accept=".pdf"
                                    className="w-full p-4 rounded-md shadow-xl border border-solid border-[#00C483] transition duration-300 ease-in-out transform hover:scale-105">
                                    <p className="ant-upload-drag-icon">
                                        <FileUploadOutlined
                                            sx={{
                                                color: '#00C483',
                                                fontSize: 40,
                                            }}
                                        />
                                    </p>
                                    <p className="ant-upload-text">
                                        Click o arrastra el archivo a esta área
                                        para subir el archivo
                                    </p>
                                </Dragger>
                            </div>
                        )}
                    </div>
                ))}
            </Box>

            <Box
                sx={{ display: 'flex', justifyContent: 'space-between' }}
                pt={2}>
                <Button
                    variant="outlined"
                    onClick={() => {
                        handleBack();
                    }}
                    disabled={isUploading}>
                    Regresar
                </Button>
                <Button
                    type="primary"
                    variant="contained"
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                        handleNext();
                    }}
                    disabled={isUploading}>
                    Siguiente
                </Button>
            </Box>

            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description">
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 2,
                        }}>
                        <Typography
                            id="modal-title"
                            variant="h6"
                            component="h2">
                            Visualizar Archivo
                        </Typography>
                        <IconButton onClick={handleCloseModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <iframe
                        src={selectedFileUrl}
                        width="100%"
                        height="600px"
                        title="PDF Viewer"
                    />
                </Box>
            </Modal>
        </Box>
    );
};
