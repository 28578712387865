import { Box, Typography } from '@mui/material';
import React from 'react';
import Controls from '../../../../../../components/ReusableComponents/controls';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

export const PaginationDoc = ({
    currentPage,
    totalPages,
    handlePrevPage,
    handleNextPage,
}) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px',
            borderTop: '1px solid #e0e0e0',
            background: '#ffff',
            width: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
            boxShadow: '0px -2px 4px rgba(0,0,0,0.05)',
        }}>
        <Controls.ActionButton
            color="secondary"
            onClick={handlePrevPage}
            disabled={currentPage === 0}>
            <ArrowBackIos />
        </Controls.ActionButton>
        <Typography
            variant="body1"
            sx={{ minWidth: '60px', textAlign: 'center' }}>
            {`${currentPage + 1} / ${totalPages}`}
        </Typography>
        <Controls.ActionButton
            color="secondary"
            onClick={handleNextPage}
            disabled={currentPage >= totalPages - 1}>
            <ArrowForwardIos />
        </Controls.ActionButton>
    </Box>
);
