import React from 'react';
import { TextField, MenuItem, Grid, Box } from '@mui/material';

const AddressReuse = ({
    value,
    onChange,
    onBlur,
    error,
    helperText,
    onClearAddress,
}) => {
    const handleChange = (event) => {
        const selectedValue = event.target.value;

        if (selectedValue !== '1') {
            onClearAddress();
        }
        onChange(event);
    };

    return (
        <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 3 }}>
            <Grid item xs={12} sm={12}>
                <TextField
                    select
                    fullWidth
                    label="¿Desea usar los datos de domicilio del alumno?*"
                    name="address_Information"
                    value={value || ''}
                    onChange={handleChange}
                    onBlur={onBlur}
                    error={error}
                    helperText={helperText}
                >
                    <MenuItem value="" disabled>
                        Seleccione una opción
                    </MenuItem>
                    <MenuItem value="1">Si</MenuItem>
                    <MenuItem value="2">No</MenuItem>
                </TextField>
            </Grid>
        </Box>
    );
};

export default AddressReuse;
