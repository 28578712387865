import React, { useState } from 'react';

import { Close } from '@material-ui/icons';
import { Box, Divider, Popover, Popper, Typography } from '@mui/material';

import Controls from '../controls';
import palomita from '../../../public/assets/palomita.svg';
import { StyledBoxArrow } from '../styles';

export default function PopperComponent(props) {
    const {
        title,
        subtitle,
        children,
        anchorEl,
        setAnchorEl,
        arrowRef,
        setArrowRef,
    } = props;

    return (
        <>
            <Popover
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(false)}
                placement="bottom"
                transition
                disablePortal={false}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                modifiers={{
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: 'window',
                    },
                    arrow: {
                        enabled: true,
                        element: arrowRef,
                    },
                }}>
                <StyledBoxArrow component="span" ref={setArrowRef} />
                <Box
                    sx={{
                        display: 'flex',
                        padding: '1rem',
                    }}>
                    <Box
                        component="img"
                        src={palomita}
                        alt="Descripción de la imagen"
                        sx={{
                            width: '10%',
                            height: 'auto',
                        }}
                    />
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                            display: 'flex',
                            ml: 2,
                            mr: 2,
                        }}
                    />
                    <Typography
                        variant="h6"
                        component="div"
                        style={{
                            flexGrow: 1,
                            fontWeight: 'bold',
                            color: '#7F8185',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}>
                        {title}
                        <Typography
                            component="p"
                            variant="subtitle1"
                            style={{
                                fontWeight: '100',
                                color: '#7F8185',
                            }}>
                            {subtitle}
                        </Typography>
                    </Typography>
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                            display: 'flex',
                            ml: 2,
                            mr: 2,
                        }}
                    />
                    <Controls.ActionButton
                        color="secondary"
                        onClick={() => setAnchorEl(null)}>
                        <Close />
                    </Controls.ActionButton>
                </Box>
                <Divider flexItem />
                {children}
            </Popover>
        </>
    );
}
