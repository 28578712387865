import { Alert, AlertTitle, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Map, Marker, ZoomControl } from 'pigeon-maps';

import { useDispatch, useSelector } from 'react-redux';
import { useAuth, useFeedback } from '../../../hooks';
import { updateGeneralInfo } from '../../../store/slices/settingsUI';
import { Update } from '@material-ui/icons';
import { selectSchoolById } from '../../../store/slices/entities/schools';
import { Card } from 'antd';
import { LoadingButton } from '@mui/lab';

import SchoolIcon from '../../../public/assets/iconos/Escuela_Azul.svg';
import MapIcon from '../../../public/assets/iconos/map-marker-svgrepo-com.svg';
import Header from '../../../components/utilities/Header';

export const SchoolLocation = () => {
    ///////////////// LOCAL STATE ///////////////////

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [schoolsData, setSchoolData] = useState({
        latitude: '',
        longitude: '',
    });

    const [userLocation, setUserLocation] = useState({
        latitude: '',
        longitude: '',
    });

    ///////////////// SHARE STATE ///////////////////
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    const school = useSelector(selectSchoolById(schoolId));

    ///////////////// ACTIONS ///////////////////

    const UpdateLocation = async (data) => {
        const updatedData = { ...schoolsData, ...data };
        setLoading(true);
        await dispatch(updateGeneralInfo(updatedData))
            .unwrap()
            .then((res) => {
                feedbackApp.showFeedback({
                    title: 'Datos actualizados',
                    severity: 'success',
                });
                setSchoolData(res);
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                    severity: 'error',
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    ///////////////// EFFECTS ///////////////////

    useEffect(() => {
        let schoolMixed = { ...schoolsData, ...school };
        setSchoolData(schoolMixed);
    }, [school]);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;

                setUserLocation({
                    latitude: latitude,
                    longitude: longitude,
                });
            },
            () => {
                setError(true);
            }
        );
    }, []);

    ///////////////// UTILS ///////////////////

    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371;
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) *
                Math.cos(lat2 * (Math.PI / 180)) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;
        return distance;
    };

    const isUserLocationCloseToSchool = () => {
        const distance = calculateDistance(
            userLocation.latitude,
            userLocation.longitude,
            parseFloat(schoolsData.latitude),
            parseFloat(schoolsData.longitude)
        );
        return distance < 0.1;
    };

    ///////////// RENDER /////////////
    return (
        <>
            <Header
                title="Ubicación de la escuela"
                subtitle="Aquí puedes ver la ubicación de la escuela"
            />
            <Card
                style={{
                    width: '100%',
                    borderRadius: '15px',
                    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
                }}
                bodyStyle={{ padding: '0px' }}
                borderStyle={{ borderRadius: '15px' }}
                cover={
                    <>
                        {error ? (
                            <Alert severity="error">
                                <AlertTitle>
                                    Error al obtener la ubicación
                                </AlertTitle>
                                {error}
                            </Alert>
                        ) : (
                            <Alert severity="info">
                                <AlertTitle>
                                    Información de ubicación
                                </AlertTitle>
                                <p style={{ margin: '5px 0' }}>
                                    Para actualizar la ubicación de la escuela,
                                    haz clic en el botón "Actualizar ubicación".
                                </p>
                                <p
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        margin: '5px 0',
                                    }}>
                                    <img
                                        src={MapIcon}
                                        width={30}
                                        height={30}
                                        alt="user location"
                                        style={{
                                            marginRight: '8px',
                                        }}
                                    />
                                    este icono representa tu ubicación actual,
                                    sin embargo si este icono no aparece es
                                    porque estas cerca de la escuela
                                </p>
                                <p
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        margin: '5px 0',
                                    }}>
                                    <img
                                        src={SchoolIcon}
                                        width={30}
                                        height={30}
                                        alt="school location"
                                        style={{
                                            marginRight: '8px',
                                        }}
                                    />
                                    este icono representa la ubicación de la
                                    escuela
                                </p>
                            </Alert>
                        )}
                        <Map
                            height={250}
                            center={
                                (schoolsData.latitude &&
                                    schoolsData.longitude && [
                                        parseFloat(schoolsData.latitude),
                                        parseFloat(schoolsData.longitude),
                                    ],
                                [24.0223, -104.6532])
                            }
                            defaultZoom={13}
                            attribution={false}>
                            <ZoomControl />
                            {schoolsData.latitude && schoolsData.longitude && (
                                <Marker
                                    width={50}
                                    anchor={[
                                        parseFloat(schoolsData.latitude),
                                        parseFloat(schoolsData.longitude),
                                    ]}>
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: 50,
                                            height: 50,
                                        }}>
                                        <img
                                            src={SchoolIcon}
                                            width={50}
                                            height={50}
                                            alt="school"
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                backgroundColor: '#ffffff',
                                                color: 'black',
                                                padding: '5px',
                                                borderRadius: '5px',
                                                textAlign: 'center',
                                                position: 'absolute',
                                                bottom: '50px',
                                                whiteSpace: 'normal',

                                                width: '300px',
                                                '&::after': {
                                                    content: '""',
                                                    position: 'absolute',
                                                    bottom: '-10px',
                                                    left: '50%',
                                                    transform:
                                                        'translateX(-50%)',
                                                    borderWidth: '5px',
                                                    borderStyle: 'solid',
                                                    borderColor:
                                                        '#ffffff transparent transparent transparent',
                                                },
                                            }}>
                                            {schoolsData.name}
                                        </Box>
                                    </Box>
                                </Marker>
                            )}

                            {userLocation.latitude &&
                                userLocation.longitude &&
                                !isUserLocationCloseToSchool() && (
                                    <Marker
                                        width={50}
                                        anchor={[
                                            userLocation.latitude,
                                            userLocation.longitude,
                                        ]}>
                                        <img
                                            src={MapIcon}
                                            width={50}
                                            height={50}
                                            alt="user location"
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                            }}
                                        />
                                    </Marker>
                                )}
                        </Map>
                    </>
                }
                actions={[
                    schoolsData.has_location === 0 && (
                        <LoadingButton
                            loading={loading}
                            onClick={() => UpdateLocation(userLocation)}
                            variant="text"
                            startIcon={<Update />}
                            style={{ width: '100%' }}
                            disabled={loading}>
                            {loading
                                ? 'Actualizando...'
                                : '   Actualizar ubicación'}
                        </LoadingButton>
                    ),
                ]}
            />
        </>
    );
};
