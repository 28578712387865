import React from 'react';
import { Box, Typography } from '@mui/material';
import Meta from 'antd/lib/card/Meta';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const ContactInfo = ({ prescription, loading, isMobile }) => {
    return (
        <Box
            p={2}
            className="rounded-xl shadow-lg"
            sx={{
                width: '100%', // Ocupa el 100% del contenedor padre
                height: '100%', // Ajusta la altura al contenido
                backgroundColor: 'background.paper', // Fondo consistente
            }}
        >
            <Box display="flex" justifyContent="center" mb={1}>
                <Typography
                    variant="h6"
                    className="font-semibold"
                    sx={{
                        color: '#8c8c8c',
                        fontSize: isMobile ? '1.2rem' : '1.5rem',
                    }}
                >
                    Información de Contacto
                </Typography>
            </Box>
            <Meta
                avatar={
                    <AccountCircleIcon className="text-gray-200 h-20 w-20" />
                }
                title={
                    <Typography
                        variant="h6"
                        className="font-semibold"
                        style={{
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word',
                        }}
                    >
                        {prescription.parent_name}{' '}
                        {prescription.parent_last_name}{' '}
                        {prescription.parent_second_last_name}
                    </Typography>
                }
                description={
                    <Box>
                        <Box display="flex" className="font-bold mb-1">
                            <Typography
                                variant="body2"
                                className="font-bold mr-2"
                            >
                                Teléfono:
                            </Typography>
                            <Typography variant="body2">
                                {prescription.parent_phone}
                            </Typography>
                        </Box>
                        <Box display="flex" className="font-bold mb-1">
                            <Typography
                                variant="body2"
                                className="font-bold mr-2"
                            >
                                Email:
                            </Typography>
                            <Typography
                                variant="body2"
                                style={{
                                    wordWrap: 'break-word',
                                    overflowWrap: 'break-word',
                                }}
                            >
                                {prescription.parent_email}
                            </Typography>
                        </Box>
                        <Box display="flex" className="font-bold mb-1">
                            <Typography
                                variant="body2"
                                className="font-bold mr-2"
                            >
                                Dirección:
                            </Typography>
                            <Typography
                                variant="body2"
                                style={{
                                    wordWrap: 'break-word',
                                    overflowWrap: 'break-word',
                                }}
                            >
                                {prescription.parent_home_street} #
                                {prescription.parent_home_external_number}{' '}
                                {prescription.parent_home_neighborhood}
                            </Typography>
                        </Box>
                    </Box>
                }
            />
        </Box>
    );
};

export default ContactInfo;
