import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const postulationsAdapter = createEntityAdapter({
    selectId: (postulation) => postulation.postulation_id,
    sortComparer: (a, b) => a.postulation_id - b.postulation_id,
});
export const postulationsSlice = createSlice({
    name: 'postulations',
    initialState: postulationsAdapter.getInitialState(),
    reducers: {
        addOnePostulation: postulationsAdapter.addOne,
        addManyPostulations: postulationsAdapter.addMany,
        upsertOnePostulation: postulationsAdapter.upsertOne,
        upsertManyPostulations: postulationsAdapter.upsertMany,
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return postulationsAdapter.getInitialState();
        });
    },
});

export const {
    addOnePostulation,
    addManyPostulations,
    upsertOnePostulation,
    upsertManyPostulations,
} = postulationsSlice.actions;

const globalizaedSelectors = postulationsAdapter.getSelectors(
    (state) => state.entities.postulations
);

export const selectPostulations = (state) =>
    globalizaedSelectors.selectEntities(state);

export const selectAllPostulations = (state) =>
    globalizaedSelectors.selectAll(state);

export default postulationsSlice.reducer;
