import React from 'react';
import { StyledLoadingButton } from '../../styles';
import { Save } from '@mui/icons-material';

export default function LoadingButton(props) {
    const {
        text,
        size,
        color,
        variant,
        onClick,
        disabled,
        type,
        loading,
        ...other
    } = props;

    return (
        <StyledLoadingButton
            loading={loading}
            variant={variant || 'contained'}
            size={size || 'small'}
            color={color || 'primary'}
            onClick={onClick}
            type={type || 'button'}
            loadingPosition="start"
            startIcon={<Save />}
            disabled={disabled || false}
            {...other}>
            {text}
        </StyledLoadingButton>
    );
}
