import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, styled } from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        padding: theme.spacing(1),
        position: 'absolute',
        borderRadius: '20px',
    },
}));

export const StyledDialogHeader = styled(Box)(({ theme }) => ({
    paddingRight: '0px',
    borderBottom: '1px solid #e0e0e0',
}));

export const StyledActionButton = styled(Button)(({ theme, color }) => ({
    margin: theme.spacing(0.5),
    minWidth: 0,

    ...(color === 'secondary' && {
        backgroundColor: '#FFFFFF',
        color: '#00C483',
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: '#F5F5F5',
            color: '#00C483',
        },
    }),
}));

export const StyledButton = styled(Button)(({ theme, color, variant }) => ({
    margin: theme.spacing(0.5),
    minWidth: 0,

    ...(color === 'primary' &&
        variant === 'contained' && {
            backgroundColor: '#00C483',
            border: '1px solid #00C483',
            borderRadius: '10px',
            '& .MuiButton-label': {
                color: theme.palette.secondary.main,
            },
            '&:hover': {
                backgroundColor: '#00A36C',
                borderColor: '#00A36C',
            },
        }),

    ...(color === 'primary' &&
        variant === 'outlined' && {
            backgroundColor: '#FFFFFF',
            border: '1px solid #00C483',
            color: '#00C483',
            borderRadius: '10px',

            '&:hover': {
                backgroundColor: '#F5F5F5',
                borderColor: '#00C483',
                color: '#00C483',
            },
        }),

    ...(color === 'error' &&
        variant === 'contained' && {
            backgroundColor: '#FF6060',
            border: '1px solid #FF6060',
            borderRadius: '10px',
            '& .MuiButton-label': {
                color: theme.palette.secondary.main,
            },
            '&:hover': {
                backgroundColor: '#E05050',
                borderColor: '#E05050',
            },
        }),

    ...(color === 'warning' &&
        variant === 'contained' && {
            backgroundColor: '#FFBF43',
            border: '1px solid #FFBF43',
            borderRadius: '10px',
            '& .MuiButton-label': {
                color: theme.palette.secondary.main,
            },
            '&:hover': {
                backgroundColor: '#E5AC3C',
                borderColor: '#E5AC3C',
            },
        }),
}));

export const StyledLoadingButton = styled(LoadingButton)(
    ({ theme, color, variant }) => ({
        margin: theme.spacing(0.5),
        minWidth: 0,

        ...(color === 'primary' &&
            variant === 'contained' && {
                backgroundColor: '#00C483',
                border: '1px solid #00C483',
                borderRadius: '10px',
                '& .MuiButton-label': {
                    color: theme.palette.secondary.main,
                },
                '&:hover': {
                    backgroundColor: '#00A36C',
                    borderColor: '#00A36C',
                },
            }),

        ...(color === 'primary' &&
            variant === 'outlined' && {
                backgroundColor: '#FFFFFF',
                border: '1px solid #00C483',
                color: '#00C483',
                borderRadius: '10px',

                '&:hover': {
                    backgroundColor: '#F5F5F5',
                    borderColor: '#00C483',
                    color: '#00C483',
                },
            }),

        ...(color === 'error' &&
            variant === 'contained' && {
                backgroundColor: '#FF6060',
                border: '1px solid #FF6060',
                borderRadius: '10px',
                '& .MuiButton-label': {
                    color: theme.palette.secondary.main,
                },
                '&:hover': {
                    backgroundColor: '#E05050',
                    borderColor: '#E05050',
                },
            }),

        ...(color === 'warning' &&
            variant === 'contained' && {
                backgroundColor: '#FFBF43',
                border: '1px solid #FFBF43',
                borderRadius: '10px',
                '& .MuiButton-label': {
                    color: theme.palette.secondary.main,
                },
                '&:hover': {
                    backgroundColor: '#E5AC3C',
                    borderColor: '#E5AC3C',
                },
            }),
    })
);

export const StyledBoxArrow = styled(Box)(({ theme, direction = 'up' }) => ({
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        ...(direction === 'up' && {
            borderWidth: '0 0.7em 0.7em 0.7em',
            borderColor: 'transparent transparent #00C483 transparent',
        }),
    },
}));
