import * as Yup from 'yup';
import { DateTime } from 'luxon';

const minDate = DateTime.now().minus({ years: 18 }).toJSDate();

const nameValidation = Yup.string()
    .matches(/^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/, 'Solo se permiten letras y espacios')
    .required('Campo requerido');

const optionalNameValidation = Yup.string()
    .matches(/^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/, 'Solo se permiten letras y espacios')
    .notRequired();

const emailValidation = Yup.string().email('Correo inválido').notRequired();

const postalCodeValidation = Yup.string()
    .matches(/^[0-9]{5}$/, 'Debe ser un código postal válido de 5 dígitos')
    .required('El código postal es obligatorio');

const phoneValidation = Yup.string()
    .matches(/^[0-9]{10}$/, 'Debe ser un número de 10 dígitos')
    .required('El número de celular es obligatorio');

const streetColonyValidation = Yup.string()
    .matches(/^[A-Za-z0-9ÁÉÍÓÚáéíóúÑñ\s]+$/, 'Solo se permiten letras, números')
    .required('Campo requerido');

// Esquema de validación actualizado
export const validationSchema = Yup.object({
    parent_name: nameValidation,
    parent_last_name: nameValidation,
    parent_second_last_name: optionalNameValidation,
    parent_phone: phoneValidation,
    parent_email: emailValidation,
    parent_birth_date: Yup.date()
        .required('La fecha de nacimiento es obligatoria')
        .max(minDate, 'Debes ser mayor de 18 años'),
    parent_gender: Yup.string()
        .required('El género es obligatorio')
        .oneOf(['1', '2'], 'Género inválido'),
    relationship: Yup.string()
        .required('El tipo de parentesco es obligatorio')
        .oneOf(['1', '2', '3', '4'], 'El tipo de parentesco no es válido'),
    address_Information: Yup.string()
        .required('El campo es obligatorio')
        .oneOf(['1', '2'], 'El campo no es válido'),

    //ESTADO MUNICIPIO CIUDAD
    parent_birth_state_id: Yup.string().required('El estado es obligatoria'),
    parent_birth_minicipality_id: Yup.string().required(
        'El municipio es obligatoria'
    ),
    parent_birth_city_id: Yup.string().required('La ciudad es obligatoria'),

    //NOMBRES ESTADO MUNICIPIO CIUDAD
    parent_birth_state_name: Yup.string().required('El estado es obligatoria'),
    parent_birth_minicipality_name: Yup.string().required(
        'El municipio es obligatoria'
    ),
    parent_birth_city_name: Yup.string().required('La ciudad es obligatoria'),

    gpsLocation: Yup.string().notRequired(),
    parent_home_street: streetColonyValidation,
    parent_home_external_number: Yup.string().required(
        'El número exterior es obligatorio'
    ),
    parent_home_internal_number: Yup.string().notRequired(),
    parent_home_neighborhood: streetColonyValidation,
    parent_home_zipcode: postalCodeValidation,

    //DATOS DE TRABAJO
    work_street: Yup.string()
        .matches(
            /^[A-Za-z0-9ÁÉÍÓÚáéíóúÑñ\s]+$/,
            'Solo se permiten letras, números'
        )
        .notRequired('La calle es obligatoria'),
    work_neighborhood: Yup.string().notRequired('La colonia es obligatoria'),
    work_zipcode: Yup.string()
        .matches(/^[0-9]{5}$/, 'Debe ser un código postal válido de 5 dígitos')
        .notRequired('El código postal es obligatorio'),
    work_external_number: Yup.string().notRequired(
        'El número exterior es obligatorio'
    ),
    work_internal_number: Yup.string().notRequired(),
});
