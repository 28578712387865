import {
    Box,
    Typography,
    Button,
    Tooltip,
    IconButton,
    Chip,
} from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { Tabs } from 'antd';
import React, { useState } from 'react';
import Connection from '../../../../../service/Connection';
import { Avatar, Card, Divider, Progress, Skeleton, Space } from 'antd';
import Meta from 'antd/lib/card/Meta';
import RedSchoolIcon from '../../../../assets/iconos/Escuela_Rojo.svg';
import YellowSchoolIcon from '../../../../assets/iconos/Escuela_Amarillo.svg';
import GreenSchoolIcon from '../../../../assets/iconos/Escuela_Naranja.svg';
import BlueSchoolIcon from '../../../../assets/iconos/Escuela_Azul.svg';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Map, Marker } from 'pigeon-maps';
import { useFeedback } from '../../../../../hooks';
import { useShowContainer } from '../../../../services/Container';
import { getLevelName, getTurnLevel } from '../../../../../libs/utils';
import FeatureFlags from '../../../../../service/FeatureFlags';
import featureFLagManager from '../../../../../service/FeatureFlags';
import ModalComponent from '../../../../../components/ReusableComponents/modal';
import DocumentNewPrescription from './components/DocumentNewPrescription';
import { uuidRegex } from './components/validations';
import StudentInfo from './components/StudentInfo';
import ContactInfo from './components/ContactInfo';
import FormSection from './components/FormSection';
import TabsDocumentsAndSchool from './components/TabsDocumentsAndSchool';
import SchoolCard from './components/SchoolCard';
import { VisualCardDocuments } from './components/VisualCardDocuments';
import PostulationSummary from './components/PostulationSummary';

export const Following = () => {
    /////////////////// LOCAL STATE ///////////////////////////

    const [loading, setLoading] = useState(false);
    const [prescriptions, setPrescriptions] = useState([]);
    const isMobile = useMediaQuery('(max-width:1096px)');
    const isMediumScreen = useMediaQuery(
        '(min-width:1100px) and (max-width:1625px)'
    );
    const [openModalNewPostulation, setOpenModalNewPostulation] =
        useState(false);
    const [postulationsRejected, setPostulationsRejected] = useState([]);
    const [postulationsPending, setPostulationsPending] = useState([]);
    const [preinscriptionsFiltered, setPreinscriptionsFiltered] = useState([]);
    const [cellPhoneContact, setcellphoneContact] = useState();
    const [levelPostulationSearch, setLevelPostulationSearch] = useState();

    /////////////////// SHARED STATE  ///////////////////////////
    const { setShowContainer, uuid } = useShowContainer();

    const featureFlagTabsDocuments = FeatureFlags.isFeatureFlagActive(
        'NEW_DOCUMENTS_CARD_FOLLOWING'
    );
    const modalFeatureFlagModal = featureFLagManager.isFeatureFlagActive(
        'NEW_ADD_POSTULATIONS_FOLLOWING_MODULE'
    );
    const desingFeatureFlagPostulations =
        featureFLagManager.isFeatureFlagActive(
            'NEW_ADD_NEW_DESING_POSTULATIONS'
        );

    /////////////////// HOOKS ///////////////////////////
    const feedbackApp = useFeedback();
    const { TabPane } = Tabs;

    /////////////////// FUNCTIONS ///////////////////////////
    const handleExportPDF = (postulationId) => {
        Connection.getPostulationsExportable('assignment-voucher', 'pdf', {
            id_postulacion: postulationId,
        })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `ficha_preinscripcion.pdf`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                feedbackApp.showFeedback({
                    title: '¡Ficha Descargada con Éxito! 🎉  Recuerda llevarla contigo a las instalaciones de la institución.',
                    severity: 'success',
                });
            })
            .catch((err) => {
                feedbackApp.showFeedback({
                    title: '¡Ups! Algo salió mal',
                    severity: 'error',
                });
            });
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            setLoading(true);
            setSubmitting(true);
            setPrescriptions([]);

            let result = [];

            let finalValues = uuidRegex.test(values.identifier)
                ? `id_preinscripcion=${values.identifier}`
                : `cellphone=${values.identifier}`;

            const preResponse = await Connection.getPendingPrescriptionsById(
                finalValues
            ).then((res) => res.data.data);

            for (const prescription of preResponse) {
                const postulationsResponse =
                    await Connection.getPendingPostulationsById(
                        prescription.preinscription_id,
                        finalValues
                    ).then((res) => res.data.data);

                const documentsResponse =
                    await Connection.getDocumentsByPreinscriptionId(
                        prescription.preinscription_id,
                        prescription.parent_phone
                    ).then((res) => res.data.data);

                const postulationsWithSchools = [];
                for (const postulation of postulationsResponse) {
                    const schoolData =
                        await Connection.getSchoolsByPostulations(
                            postulation.postulation_id,
                            finalValues
                        ).then((res) => res.data.data);

                    postulationsWithSchools.push({
                        ...postulation,
                        schools: schoolData,
                    });
                }

                result.push({
                    ...prescription,
                    postulations: postulationsWithSchools,
                    documents: documentsResponse,
                });
            }
            setPrescriptions(result);
        } catch (error) {
            feedbackApp.showFeedback({
                title: 'No se encontraron preinscripciones con la información ingresada.',
                severity: 'error',
            });
        } finally {
            setLoading(false);
            setSubmitting(false);
        }
    };

    const getStatusInfo = (status) => {
        switch (status) {
            case 1:
                return {
                    percentage: 25,
                    text: 'Pendiente',
                    color: '#FFBF43',
                    icon: YellowSchoolIcon,
                };
            case 2:
                return {
                    percentage: 100,
                    text: 'Aceptada',
                    color: '#00C483',
                    icon: GreenSchoolIcon,
                };
            case 0:
                return {
                    percentage: 100,
                    text: 'Rechazada',
                    color: '#ff6060',
                    icon: RedSchoolIcon,
                };
            default:
                return {
                    percentage: 0,
                    text: 'Desconocido',
                    color: 'gray',
                    icon: BlueSchoolIcon,
                };
        }
    };

    const getChipText = (priority) => {
        switch (priority) {
            case 1:
                return 'Primera Opción 🥇';
            case 2:
                return 'Segunda Opción 🥈';
            case 3:
                return 'Tercera Opción 🥉';
            case 4:
                return 'Cuarta Opción 🏅';
            default:
                return '';
        }
    };

    const postulationsModal = (preinscription) => {
        if (prescriptions.length > 0) {
            setcellphoneContact(preinscription.parent_phone);

            let postulationsRejected = prescriptions.flatMap((data) =>
                (data.postulations || []).filter(
                    (item) =>
                        item.status == 0 &&
                        item.preinscription_id ==
                            preinscription.preinscription_id
                )
            );

            let postulationsPending = prescriptions.flatMap((data) =>
                (data.postulations || []).filter(
                    (item) =>
                        item.status == 1 &&
                        item.preinscription_id ==
                            preinscription.preinscription_id
                )
            );

            setPostulationsRejected(postulationsRejected);
            setPostulationsPending(postulationsPending);

            const preinscriptionLevel = preinscription.preinscription_level;
            setLevelPostulationSearch(preinscriptionLevel);

            setOpenModalNewPostulation(true);
        }
    };

    const preinscriptionsModal = (preinscription) => {
        if (prescriptions.length > 0) {
            setcellphoneContact(preinscription.parent_phone);
            const preinscriptionsFiltered = prescriptions.filter(
                (data) =>
                    data.preinscription_id === preinscription.preinscription_id
            );
            setPreinscriptionsFiltered(preinscriptionsFiltered);
            setOpenModalNewPostulation(true);
        }
    };

    const shouldShowButton = (postulations) => {
        // const countStatus0 = postulations.filter((p) => p.status === 0).length;
        const countStatus1 = postulations.filter((p) => p.status === 1).length;
        const countStatus2 = postulations.filter((p) => p.status === 2).length;

        if (countStatus2 > 0) {
            return false;
        }
        if (countStatus1 === 3) {
            return false;
        }

        return true;
    };

    return (
        <Box
            sx={{
                maxWidth: 1200,
                margin: '0 auto',
                padding: 2,
                alignItems: 'center',
            }}
        >
            <Typography
                variant="h5"
                align="center"
                gutterBottom
                style={{ color: '#919399' }}
            >
                ¡Bienvenido/a al seguimiento de tu preinscripción! 🎉
            </Typography>
            <Divider />
            <Typography
                variant="subtitle1"
                gutterBottom
                className="py-2"
                style={{ color: '#757575', fontSize: '1.1rem' }}
            >
                - Debes de contar con tu{' '}
                <span style={{ color: '#000', fontWeight: 'bold' }}>
                    Folio de preinscripción
                </span>{' '}
                el cual obtuviste al momento de terminar el proceso de
                preinscripción
                <br />- Esta sección es para consultar el estado de tu
                preinscripción.
            </Typography>
            <Divider />

            <Box className="mt-4">
                <FormSection
                    isMobile={isMobile}
                    handleSubmit={handleSubmit}
                    setShowContainer={setShowContainer}
                    uuid={uuid}
                />
                <Divider />

                <Skeleton loading={loading} avatar active />
                {desingFeatureFlagPostulations ? (
                    <Space className="w-full" direction="vertical">
                        {prescriptions?.length > 0 ? (
                            <PostulationSummary
                                prescriptions={prescriptions}
                                handleExportPDF={handleExportPDF}
                                getStatusInfo={getStatusInfo}
                                getChipText={getChipText}
                                cellphoneContact={cellPhoneContact}
                                PostulationsRejected={postulationsRejected}
                                postulationsPending={postulationsPending}
                                preinscriptionsFiltered={
                                    preinscriptionsFiltered
                                }
                            />
                        ) : (
                            <Typography align="center">
                                No hay datos disponibles.
                            </Typography>
                        )}
                    </Space>
                ) : (
                    <Space className="w-full" direction="vertical">
                        {prescriptions?.map((item, index) => (
                            <div key={index}>
                                <Box
                                    display="flex"
                                    flexDirection={isMobile ? 'column' : 'row'}
                                    alignItems="stretch"
                                    gap={4}
                                    justifyContent="center"
                                >
                                    <Skeleton loading={loading} avatar active>
                                        <StudentInfo
                                            prescription={item}
                                            loading={loading}
                                            isMobile={isMobile}
                                        />
                                    </Skeleton>

                                    <Skeleton loading={loading} avatar active>
                                        <ContactInfo
                                            prescription={item}
                                            loading={loading}
                                            isMobile={isMobile}
                                        />
                                    </Skeleton>
                                </Box>
                                <Divider
                                    sx={{
                                        width: '100%',
                                        margin: isMobile ? '16px 0' : '24px 0',
                                    }}
                                />
                                <Divider
                                    sx={{
                                        width: '100%',
                                        margin: isMobile ? '16px 0' : '24px 0',
                                    }}
                                />
                                {featureFlagTabsDocuments ? (
                                    <TabsDocumentsAndSchool
                                        prescriptions={prescriptions}
                                        isMobile={isMobile}
                                        isMediumScreen={isMediumScreen}
                                        handleExportPDF={handleExportPDF}
                                        getStatusInfo={getStatusInfo}
                                        getChipText={getChipText}
                                        currentPreinscriptionId={
                                            item.preinscription_id
                                        }
                                    />
                                ) : (
                                    <Space
                                        direction={
                                            isMobile ? 'vertical' : 'horizontal'
                                        }
                                        size="middle"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            width: '100%',
                                            alignItems: 'stretch',
                                            flexWrap: 'nowrap',
                                        }}
                                    >
                                        {prescriptions?.postulations.map(
                                            (postulation, postIndex) => (
                                                <Card
                                                    key={postIndex}
                                                    hoverable
                                                    style={{
                                                        marginBottom: 16,
                                                        minWidth: isMobile
                                                            ? '100%'
                                                            : isMediumScreen
                                                            ? 350
                                                            : 600,
                                                        maxWidth: isMobile
                                                            ? '100%'
                                                            : isMediumScreen
                                                            ? 350
                                                            : 600,
                                                        width: '100%',
                                                    }}
                                                    loading={loading}
                                                    className="rounded-xl overflow-hidden shadow-lg w-full"
                                                    cover={
                                                        loading ? (
                                                            <Skeleton.Image
                                                                className="w-full"
                                                                style={{
                                                                    height: 300,
                                                                }}
                                                            />
                                                        ) : (
                                                            <>
                                                                {postulation
                                                                    .schools
                                                                    .latitude &&
                                                                    postulation
                                                                        .schools
                                                                        .longitude && (
                                                                        <Box className="absolute top-2 bg-white m-1 rounded-xl shadow-lg z-10 left-1/2 transform -translate-x-1/2 w-10/12 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-5/12">
                                                                            <Typography
                                                                                variant="body2"
                                                                                className="font-bold"
                                                                                align="center"
                                                                                mt={
                                                                                    0.5
                                                                                }
                                                                            >
                                                                                Dirección
                                                                                de
                                                                                la
                                                                                escuela
                                                                            </Typography>
                                                                            <Typography
                                                                                variant="body2"
                                                                                align="center"
                                                                            >
                                                                                {
                                                                                    postulation
                                                                                        .schools
                                                                                        .street
                                                                                }

                                                                                ,
                                                                                #
                                                                                {
                                                                                    postulation
                                                                                        .schools
                                                                                        .inside_number
                                                                                }

                                                                                ,{' '}
                                                                                {
                                                                                    postulation
                                                                                        .schools
                                                                                        .zipcode
                                                                                }

                                                                                ,{' '}
                                                                                {
                                                                                    postulation
                                                                                        .schools
                                                                                        .location
                                                                                }
                                                                            </Typography>
                                                                            <Box
                                                                                display="flex"
                                                                                justifyContent="center"
                                                                                mt={
                                                                                    0.1
                                                                                }
                                                                            >
                                                                                <Button
                                                                                    variant="text"
                                                                                    color="primary"
                                                                                    onClick={() => {
                                                                                        const url = `https://www.google.com/maps/dir/?api=1&destination=${postulation.schools.latitude},${postulation.schools.longitude}`;
                                                                                        window.open(
                                                                                            url,
                                                                                            '_blank'
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Ver
                                                                                    indicaciones
                                                                                    de
                                                                                    llegada
                                                                                </Button>
                                                                            </Box>
                                                                        </Box>
                                                                    )}
                                                                {postulation
                                                                    .schools
                                                                    .latitude &&
                                                                    postulation
                                                                        .schools
                                                                        .longitude && (
                                                                        <Map
                                                                            height={
                                                                                isMobile
                                                                                    ? 150
                                                                                    : 250
                                                                            }
                                                                            style={{
                                                                                paddingTop:
                                                                                    '50px',
                                                                            }}
                                                                            defaultCenter={[
                                                                                (postulation
                                                                                    .schools
                                                                                    .latitude ||
                                                                                    24.0226) +
                                                                                    0.0025,
                                                                                (postulation
                                                                                    .schools
                                                                                    .longitude ||
                                                                                    -104.6532) -
                                                                                    0.0005,
                                                                            ]}
                                                                            mouseEvents={
                                                                                true
                                                                            }
                                                                            touchEvents={
                                                                                true
                                                                            }
                                                                            zoomSnap={
                                                                                true
                                                                            }
                                                                            zoomDelta={
                                                                                0
                                                                            }
                                                                            defaultZoom={
                                                                                15
                                                                            }
                                                                        >
                                                                            {postulation
                                                                                .schools
                                                                                .longitude &&
                                                                                postulation
                                                                                    .schools
                                                                                    .latitude && (
                                                                                    <Marker
                                                                                        width={
                                                                                            50
                                                                                        }
                                                                                        anchor={[
                                                                                            postulation
                                                                                                .schools
                                                                                                .latitude,
                                                                                            postulation
                                                                                                .schools
                                                                                                .longitude,
                                                                                        ]}
                                                                                    >
                                                                                        <img
                                                                                            src={
                                                                                                getStatusInfo(
                                                                                                    postulation.status
                                                                                                )
                                                                                                    .icon
                                                                                            }
                                                                                            width={
                                                                                                40
                                                                                            }
                                                                                            height={
                                                                                                40
                                                                                            }
                                                                                            alt="marker"
                                                                                        />
                                                                                    </Marker>
                                                                                )}
                                                                        </Map>
                                                                    )}
                                                            </>
                                                        )
                                                    }
                                                    actions={[
                                                        <Box
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            width="100%"
                                                            key="actions"
                                                            sx={{
                                                                flexDirection:
                                                                    isMobile
                                                                        ? 'column'
                                                                        : 'row',
                                                            }}
                                                        >
                                                            {postulation.status ===
                                                                2 && (
                                                                <Tooltip
                                                                    title="Descargar Ficha de Preinscripción en PDF"
                                                                    arrow
                                                                >
                                                                    <IconButton
                                                                        onClick={() =>
                                                                            handleExportPDF(
                                                                                postulation.postulation_id
                                                                            )
                                                                        }
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                            flexDirection:
                                                                                'column',
                                                                            alignItems:
                                                                                'center',
                                                                            justifyContent:
                                                                                'center',
                                                                            margin: -1,
                                                                            width: 'auto',
                                                                            height: 'auto',
                                                                            padding: 0,
                                                                            borderRadius: 2,
                                                                            transition:
                                                                                'all 0.3s ease',
                                                                            '&:hover':
                                                                                {
                                                                                    backgroundColor:
                                                                                        'rgba(0, 0, 0, 0.1)',
                                                                                    borderRadius: 0,
                                                                                    width: 'auto',
                                                                                    height: 'auto',
                                                                                },
                                                                        }}
                                                                    >
                                                                        <FileDownloadOutlinedIcon
                                                                            style={{
                                                                                width: 40,
                                                                                height: 40,
                                                                            }}
                                                                        />
                                                                        <Typography
                                                                            variant="body2"
                                                                            className="font-bold"
                                                                            sx={{
                                                                                marginTop: 1,
                                                                            }}
                                                                        >
                                                                            Descarga
                                                                            tu
                                                                            Ficha
                                                                            de
                                                                            Preinscripción
                                                                        </Typography>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                        </Box>,
                                                    ]}
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        gap={3}
                                                        sx={{
                                                            margin: -2,
                                                            marginLeft: 1,
                                                        }}
                                                    >
                                                        <Meta
                                                            avatar={
                                                                <Avatar
                                                                    sx={(
                                                                        theme
                                                                    ) => ({
                                                                        width: '40px',
                                                                        height: '40px',
                                                                        display:
                                                                            'flex',
                                                                        justifyContent:
                                                                            'center',
                                                                        alignItems:
                                                                            'center',
                                                                        overflow:
                                                                            'visible',
                                                                        [theme.breakpoints.down(
                                                                            'sm'
                                                                        )]: {
                                                                            width: '35px',
                                                                            height: '35px',
                                                                        },
                                                                    })}
                                                                >
                                                                    <Box
                                                                        component="img"
                                                                        src={
                                                                            getStatusInfo(
                                                                                postulation.status
                                                                            )
                                                                                .icon
                                                                        }
                                                                        sx={(
                                                                            theme
                                                                        ) => ({
                                                                            width: '40px',
                                                                            height: '40px',
                                                                            marginLeft:
                                                                                '-19.5px',
                                                                            marginTop:
                                                                                '-4px',
                                                                            position:
                                                                                'absolute',
                                                                            [theme.breakpoints.down(
                                                                                'sm'
                                                                            )]:
                                                                                {
                                                                                    width: '35px',
                                                                                    height: '35px',
                                                                                    marginLeft:
                                                                                        '-17px',
                                                                                },
                                                                        })}
                                                                    />
                                                                </Avatar>
                                                            }
                                                            title={
                                                                <Box
                                                                    display="flex"
                                                                    flexDirection={{
                                                                        xs: 'column',
                                                                        sm: 'column',
                                                                        md: 'column',
                                                                        lg: 'column',
                                                                    }}
                                                                    gap={1}
                                                                    width="100%"
                                                                >
                                                                    <Typography
                                                                        variant="h6"
                                                                        className="font-bold"
                                                                        sx={(
                                                                            theme
                                                                        ) => ({
                                                                            fontSize:
                                                                                '1rem',
                                                                            [theme.breakpoints.down(
                                                                                'sm'
                                                                            )]:
                                                                                {
                                                                                    fontSize:
                                                                                        '0.875rem',
                                                                                },
                                                                        })}
                                                                    >
                                                                        {
                                                                            postulation
                                                                                .schools
                                                                                .name
                                                                        }
                                                                    </Typography>
                                                                    <Box
                                                                        display="flex"
                                                                        flexWrap="wrap"
                                                                        gap={1}
                                                                    >
                                                                        {prescriptions?.postulations
                                                                            .filter(
                                                                                (
                                                                                    currentPostulation
                                                                                ) =>
                                                                                    currentPostulation.school_id ===
                                                                                    postulation.school_id
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    filteredPostulation
                                                                                ) => (
                                                                                    <Chip
                                                                                        key={
                                                                                            filteredPostulation.postulation_id
                                                                                        }
                                                                                        label={getChipText(
                                                                                            filteredPostulation.priority
                                                                                        )}
                                                                                        sx={(
                                                                                            theme
                                                                                        ) => ({
                                                                                            backgroundColor:
                                                                                                'white',
                                                                                            borderColor:
                                                                                                '#005A99',
                                                                                            color: '#003F66',
                                                                                            borderWidth:
                                                                                                '1px',
                                                                                            borderStyle:
                                                                                                'solid',
                                                                                            fontSize:
                                                                                                '1rem',
                                                                                            padding:
                                                                                                '4px 8px',
                                                                                            [theme.breakpoints.down(
                                                                                                'sm'
                                                                                            )]:
                                                                                                {
                                                                                                    fontSize:
                                                                                                        '0.75rem',
                                                                                                    padding:
                                                                                                        '2px 6px',
                                                                                                },
                                                                                            [theme.breakpoints.up(
                                                                                                'md'
                                                                                            )]:
                                                                                                {
                                                                                                    fontSize:
                                                                                                        '0.875rem',
                                                                                                    padding:
                                                                                                        '3px 7px',
                                                                                                },
                                                                                        })}
                                                                                    />
                                                                                )
                                                                            )}
                                                                    </Box>
                                                                </Box>
                                                            }
                                                            description={
                                                                <Box
                                                                    marginTop={
                                                                        1.5
                                                                    }
                                                                    marginBottom={
                                                                        1
                                                                    }
                                                                    width="100%"
                                                                    sx={(
                                                                        theme
                                                                    ) => ({
                                                                        [theme.breakpoints.down()]:
                                                                            {
                                                                                marginTop: 1,
                                                                                marginBottom: 0.5,
                                                                            },
                                                                    })}
                                                                >
                                                                    <Box
                                                                        display="flex"
                                                                        flexWrap="wrap"
                                                                        gap={1}
                                                                        marginBottom={
                                                                            1
                                                                        }
                                                                    >
                                                                        <Box
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            gap={
                                                                                1
                                                                            }
                                                                        >
                                                                            <Typography
                                                                                variant="body2"
                                                                                className="font-bold"
                                                                            >
                                                                                {' '}
                                                                                CCT:{' '}
                                                                            </Typography>
                                                                            <Typography
                                                                                variant="body2"
                                                                                className="font-normal"
                                                                            >
                                                                                {' '}
                                                                                {
                                                                                    postulation
                                                                                        .schools
                                                                                        .clave
                                                                                }
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            gap={
                                                                                1
                                                                            }
                                                                        >
                                                                            <Typography
                                                                                variant="body2"
                                                                                className="font-bold"
                                                                            >
                                                                                {' '}
                                                                                Teléfono:
                                                                            </Typography>
                                                                            <Typography
                                                                                variant="body2"
                                                                                className="font-normal"
                                                                            >
                                                                                {' '}
                                                                                {
                                                                                    postulation
                                                                                        .schools
                                                                                        .cellphone
                                                                                }
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box
                                                                        display="flex"
                                                                        flexWrap="wrap"
                                                                        gap={1}
                                                                        marginBottom={
                                                                            1
                                                                        }
                                                                    >
                                                                        <Box
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            gap={
                                                                                1
                                                                            }
                                                                        >
                                                                            <Typography
                                                                                variant="body2"
                                                                                className="font-bold"
                                                                            >
                                                                                {' '}
                                                                                Nivel:
                                                                            </Typography>
                                                                            <Typography
                                                                                variant="body2"
                                                                                className="font-normal"
                                                                            >
                                                                                {' '}
                                                                                {getLevelName(
                                                                                    postulation
                                                                                        .schools
                                                                                        .level
                                                                                )}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            gap={
                                                                                1
                                                                            }
                                                                        >
                                                                            <Typography
                                                                                variant="body2"
                                                                                className="font-bold"
                                                                            >
                                                                                {' '}
                                                                                Turno:{' '}
                                                                            </Typography>
                                                                            <Typography
                                                                                variant="body2"
                                                                                className="font-normal"
                                                                            >
                                                                                {' '}
                                                                                {getTurnLevel(
                                                                                    postulation
                                                                                        .schools
                                                                                        .turn
                                                                                )}{' '}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            }
                                                        />
                                                    </Box>
                                                    <Divider />
                                                    <Box
                                                        style={{
                                                            marginTop: -20,
                                                            marginLeft: 1,
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h7"
                                                            className="font-medium"
                                                        >
                                                            {' '}
                                                            Proceso de
                                                            aceptación
                                                        </Typography>
                                                        <Box
                                                            key={
                                                                postulation.postulation_id
                                                            }
                                                            display="flex"
                                                            flexDirection="column"
                                                            alignItems="flex-start"
                                                            style={{
                                                                padding: 0,
                                                                margin: 0,
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="body2"
                                                                style={{
                                                                    color: getStatusInfo(
                                                                        postulation.status
                                                                    ).color,
                                                                }}
                                                            >
                                                                {' '}
                                                                {
                                                                    getStatusInfo(
                                                                        postulation.status
                                                                    ).text
                                                                }
                                                            </Typography>
                                                            <Progress
                                                                percent={
                                                                    getStatusInfo(
                                                                        postulation.status
                                                                    ).percentage
                                                                }
                                                                showInfo={false}
                                                                strokeColor={
                                                                    getStatusInfo(
                                                                        postulation.status
                                                                    ).color
                                                                }
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Card>
                                            )
                                        )}
                                    </Space>
                                )}

                                <Box marginTop={5} marginBottom={5}>
                                    <Divider sx={{ width: '100%' }} />
                                </Box>

                                {modalFeatureFlagModal && (
                                    <Box align="center">
                                        {shouldShowButton(
                                            item.postulations
                                        ) && (
                                            <Button
                                                variant="outlined"
                                                size="large"
                                                onClick={() => {
                                                    postulationsModal(item);
                                                    preinscriptionsModal(item);
                                                }}
                                                sx={{ mt: 2 }}
                                            >
                                                Agregar Postulación
                                            </Button>
                                        )}
                                    </Box>
                                )}

                                <Box marginTop={5} marginBottom={5}>
                                    <Divider sx={{ width: '100%' }} />
                                </Box>
                            </div>
                        ))}
                    </Space>
                )}
                <ModalComponent
                    OpenModal={openModalNewPostulation}
                    setOpenModal={setOpenModalNewPostulation}
                    title={'Postulaciones'}
                    subtitle={'Agrega postulaciones'}
                >
                    <DocumentNewPrescription
                        PostulationsRejected={postulationsRejected}
                        prescriptions={prescriptions}
                        setOpenModal={setOpenModalNewPostulation}
                        cellphoneContact={cellPhoneContact}
                        preinscriptionsFiltered={preinscriptionsFiltered}
                        preinscriptionLevel={levelPostulationSearch}
                        PostulationsPending={postulationsPending}
                    />
                </ModalComponent>
            </Box>
        </Box>
    );
};
