import { Chip, Stack } from '@mui/material';
import React from 'react';

export const StatusChips = ({
    estados,
    doc,
    documentStatuses,
    handleChangeStatus,
}) => (
    <Stack
        direction="column"
        spacing={1}
        px={2}
        sx={{
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        }}>
        {estados.map((estado) => (
            <Chip
                key={estado.value}
                icon={estado.icon}
                color={estado.color}
                label={estado.label}
                variant={
                    documentStatuses[doc.document_preinscription_id] ===
                        estado.value ||
                    (doc.status === estado.value &&
                        documentStatuses[doc.document_preinscription_id] ===
                            undefined)
                        ? 'contained'
                        : 'outlined'
                }
                onClick={() =>
                    handleChangeStatus(
                        doc.document_preinscription_id,
                        estado.value
                    )
                }
                sx={{
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    },
                }}
            />
        ))}
    </Stack>
);
