import { combineReducers } from '@reduxjs/toolkit';
import settingsReducer from './settings';
import partialReducer from './partials';
import schoolsReducer from './schools';
import usersReducer from './users';
import GroupsSlice from './groups';
import StudentsSlice from './students';
import SubjectcatalogsSlice from './subjectcatalogs';
import SubjectsSlice from './subjects';
import GroupsStudentsSlice from './groups_students';
import ReportsCatalogsSlice from './reportscatalogs';
import ReportsSlice from './reports';
import FilesSlice from './files';
import noticesSlice from './notices';
import relationshipsSlice from './relationships';
import califications from './califications';
import assists from './assists';
import ImportsSlice from './imports';
import notesSlice from './notes';
import modulesSlice from './modules';
import cat_concepts from './payments/cat_concepts';
import cat_surcharges from './payments/cat_surcharges';
import cat_discounts from './payments/cat_discounts';
import concepts from './payments/concepts';
import discounts from './payments/discounts';
import income from './payments/income';
import surcharges from './payments/surcharges';
import agreements from './payments/agreements';
import notificationsSlice from './notifications';
import partialities from './payments/partialities';
import Cycles from './cycles';
import statesSlice from './states';
import municipalitiesSlice from './municipalities';
import citiesSlice from './cities';
import tracesCatalogsSlice from './tracesCatalogs';
import postulationsSlice from './postulations';
import preinscriptionsSlice from './preinscriptions';
import documentsPreinscriptionsSlice from './documents_preinscriptions';

export const entities = combineReducers({
    settings: settingsReducer,
    cycle: Cycles,
    partials: partialReducer,
    schools: schoolsReducer,
    users: usersReducer,
    groups: GroupsSlice,
    subjectcatalogs: SubjectcatalogsSlice,
    students: StudentsSlice,
    subjects: SubjectsSlice,
    groupsStudents: GroupsStudentsSlice,
    reportscatalogs: ReportsCatalogsSlice,
    reports: ReportsSlice,
    files: FilesSlice,
    notices: noticesSlice,
    relationships: relationshipsSlice,
    imports: ImportsSlice,
    notes: notesSlice,
    postulations: postulationsSlice,
    preinscriptions: preinscriptionsSlice,
    documentsPreinscriptions: documentsPreinscriptionsSlice,
    califications,
    assists,
    modules: modulesSlice,
    income,
    agreements,
    concepts,
    partialities,
    cat_concepts,
    discounts,
    cat_discounts,
    surcharges,
    notifications: notificationsSlice,
    cat_surcharges,
    states: statesSlice,
    municipalities: municipalitiesSlice,
    cities: citiesSlice,
    tracesCatalogs: tracesCatalogsSlice,
});
