import React from 'react';
import { TextField, MenuItem, Grid, Box } from '@mui/material';

const RelationshipSelector = ({
    value,
    onChange,
    onBlur,
    error,
    helperText,
    isUnderAge,
    onRelationshipChange,
}) => {
    const handleChange = (event) => {
        const selectedValue = event.target.value;

        if (selectedValue !== '4') {
            onRelationshipChange(selectedValue);
        }

        onChange(event);
    };

    return (
        <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 3 }}>
            <Grid item xs={12} sm={12}>
                <TextField
                    select
                    fullWidth
                    label="Relación con el alumno*"
                    name="relationship"
                    value={value || ''}
                    onChange={handleChange}
                    onBlur={onBlur}
                    error={error}
                    helperText={helperText}
                >
                    <MenuItem value="" disabled>
                        Seleccione una opción
                    </MenuItem>
                    <MenuItem value="1">Padre</MenuItem>
                    <MenuItem value="2">Madre</MenuItem>
                    <MenuItem value="3">Otro</MenuItem>
                    <MenuItem value="4" disabled={isUnderAge}>
                        Soy el alumno{' '}
                        {isUnderAge && '(Debe ser mayor de 18 años)'}
                    </MenuItem>
                </TextField>
            </Grid>
        </Box>
    );
};

export default RelationshipSelector;
