import React from 'react';
import { Tabs, Divider } from 'antd';
import { Space } from 'antd';
import SchoolCard from '../components/SchoolCard';
import { VisualCardDocuments } from '../components/VisualCardDocuments';
const { TabPane } = Tabs;

const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
};

export const TabsDocumentsAndSchool = ({
    prescriptions,
    isMobile,
    isMediumScreen,
    handleExportPDF,
    getStatusInfo,
    getChipText,
    currentPreinscriptionId,
}) => {
    const filteredPostulations =
        prescriptions.find(
            (prescription) =>
                prescription.preinscription_id === currentPreinscriptionId
        )?.postulations || [];

    const groupedPostulations = filteredPostulations.reduce((acc, postulation) => {
        const status = postulation.status;
        if (!acc[status]) {
            acc[status] = [];
        }
        acc[status].push(postulation);
        return acc;
    }, {});

    const statusOrder = [2, 1, 0];

    return (
        <Tabs defaultActiveKey="1" style={{ width: '100%' }}>
            <TabPane tab="Escuelas 🏫 " key="1">
                <Space
                    direction="vertical"
                    size="middle"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        alignItems: 'stretch',
                        flexWrap: 'nowrap',
                    }}
                >
                    {statusOrder.map((status) => (
                        groupedPostulations[status] && (
                            <React.Fragment key={status}>
                                <Divider
                                    orientation="left"
                                    style={{
                                        color: getStatusInfo(status).color,
                                        borderColor: getStatusInfo(status).color,
                                        borderWidth: '2px',
                                    }}
                                >
                                    {getStatusInfo(status).text}
                                </Divider>
                                {chunkArray(groupedPostulations[status], 3).map((group, groupIndex) => (
                                    <Space
                                        key={groupIndex}
                                        direction={isMobile ? 'vertical' : 'horizontal'}
                                        size="middle"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            width: '100%',
                                            alignItems: 'stretch',
                                            flexWrap: 'nowrap',
                                        }}
                                    >
                                        {group.map((postulation, postIndex) => (
                                            <SchoolCard
                                                key={postIndex}
                                                postulation={postulation}
                                                isMobile={isMobile}
                                                isMediumScreen={isMediumScreen}
                                                handleExportPDF={handleExportPDF}
                                                getStatusInfo={getStatusInfo}
                                                getChipText={getChipText}
                                            />
                                        ))}
                                    </Space>
                                ))}
                            </React.Fragment>
                        )
                    ))}
                </Space>
            </TabPane>
            <TabPane tab="Documentos 📋" key="2">
                {prescriptions
                    .filter(
                        (prescription) =>
                            prescription.preinscription_id ===
                            currentPreinscriptionId
                    )
                    .map((prescription, index) =>
                        
                            <VisualCardDocuments
                                key={index}
                                documents={prescription.documents}
                                preinscription_id={prescription.preinscription_id}
                            />
                      
                    )}
            </TabPane>
        </Tabs>
    );
};
export default TabsDocumentsAndSchool;