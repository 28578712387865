import { useState } from 'react';
import {
    Card,
    CardContent,
    CardActions,
    Typography,
    IconButton,
    Stack,
    Tooltip,
    Box,
    Divider,
} from '@mui/material';
import {
    AccessTime,
    CheckCircleOutline,
    ErrorOutline,
    FileUpload,
    HighlightOff,
    Upload,
    Visibility,
} from '@mui/icons-material';

import { useFeedback } from '../../../../../../hooks';
import ViewFile from './ViewFile';
import Connection from '../../../../../../service/Connection';
import ModalComponent from '../../../../../../components/ReusableComponents/modal';

export const VisualCardDocuments = ({ preinscription_id, documents }) => {

    /////////////////////////// LOCAL STATE ///////////////////////////
    const [localDocuments, setLocalDocuments] = useState(documents);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);

    const documentTypes = [
        { key: 'curp', label: 'CURP' },
        { key: 'acta_nacimiento', label: 'Acta de nacimiento' },
        { key: 'comprobante_domicilio', label: 'Comprobante de domicilio' },
        {
            key: 'certificado_nivel_anterior',
            label: 'Certificado (nivel anterior)',
        },
        { key: 'boleta_grado_anterior', label: 'Boleta (grado anterior)' },
    ];

    /////////////////////////// SHARED STATE ///////////////////////////
    const feedbackApp = useFeedback();

    /////////////////////////// FUNCTIONS ///////////////////////////
    const handleUpdateFile = async ({ documentId, name, id, newFile }) => {
        const newFileName = `${id}_${name}.pdf`;
        const renamedFile = new File([newFile], newFileName, {
            type: newFile.type,
        });
        const formData = new FormData();
        formData.append('file', renamedFile);

        await Connection.updateDocumentPreinscription(documentId, id, formData)
            .then((res) => {
                const document = res.data.data;

                setLocalDocuments((prevDocuments) =>
                    prevDocuments.map((doc) =>
                        doc.document_preinscription_id ===
                        document.document_preinscription_id
                            ? document
                            : doc
                    )
                );

                feedbackApp.showFeedback({
                    title: 'Archivo subido exitosamente',
                    severity: 'success',
                });
            })
            .catch((error) => {
                feedbackApp.showFeedback({
                    title: 'Error al subir el archivo',
                    severity: 'error',
                });
            });
    };

    const handleUploadFile = async ({ name, id, newFile }) => {
        const newFileName = `${id}_${name}.pdf`;
        const renamedFile = new File([newFile], newFileName, {
            type: newFile.type,
        });

        const formData = new FormData();
        formData.append('file', renamedFile);

        Connection.uploadRemainDocuments(id, formData)
            .then((res) => {
                const document = res.data;

                const updatedDocument = {
                    ...document,
                    key: name,
                    label:
                        documentTypes.find((d) => d.key === name)?.label ||
                        name,
                };

                setLocalDocuments((prevDocuments) => [
                    ...prevDocuments,
                    updatedDocument,
                ]);

                feedbackApp.showFeedback({
                    title: 'Archivo subido correctamente.',
                    severity: 'success',
                });
            })
            .catch((error) => {
                feedbackApp.showFeedback({
                    title: 'Error al subir el archivo',
                    severity: 'error',
                });
            });
    };

    const getStatusInfo = (status) => {
        switch (status) {
            case 1:
                return {
                    text: 'Por determinar',
                    color: '#ffbf43',
                    icon: AccessTime,
                };
            case 2:
                return {
                    text: 'Aceptada',
                    color: '#00C483',
                    icon: CheckCircleOutline,
                };
            case 0:
                return {
                    text: 'Rechazada',
                    color: '#ff6060',
                    icon: HighlightOff,
                };
            default:
                return {
                    text: 'Desconocido',
                    color: 'gray',
                    icon: FileUpload,
                };
        }
    };

    const handleViewFile = (url) => {
        if (url) {
            setSelectedDocument(url);
            setIsModalVisible(true);
        } else {
            feedbackApp.showFeedback({
                title: 'No hay un archivo cargado.',
                severity: 'error',
            });
        }
    };

    const handleUploadedFileChange = (event, docKey, preinscriptionId) => {
        const file = event.target.files[0];
        if (!file) return;

        if (file.type !== 'application/pdf') {
            feedbackApp.showFeedback({
                title: 'Solo se permiten archivos PDF.',
                severity: 'error',
            });
            return;
        }

        if (file.size > 3 * 1024 * 1024) {
            feedbackApp.showFeedback({
                title: 'El archivo no debe exceder los 3 MB.',
                severity: 'error',
            });
            return;
        }

        handleUploadFile({
            name: docKey,
            id: preinscriptionId,
            newFile: file,
        });
    };

    const handleUpdatedFileChange = (
        event,
        docKey,
        preinscriptionId,
        documentId
    ) => {
        const file = event.target.files[0];
        if (!file) return;

        if (file.type !== 'application/pdf') {
            feedbackApp.showFeedback({
                title: 'Solo se permiten archivos PDF.',
                severity: 'error',
            });
            return;
        }

        if (file.size > 3 * 1024 * 1024) {
            feedbackApp.showFeedback({
                title: 'El archivo no debe exceder los 3 MB.',
                severity: 'error',
            });
            return;
        }

        handleUpdateFile({
            documentId: documentId,
            name: docKey,
            id: preinscriptionId,
            newFile: file,
        });
    };

    /////////////////////////// Filters ///////////////////////////

    const loadedDocuments = localDocuments.map((doc) => {
        const fileName = doc.url.split('/').pop();
        const cleanName = fileName.replace(/^[a-f0-9-]+_/, '').split('.')[0];

        const documentType = documentTypes.find((d) => d.key === cleanName);

        return {
            ...doc,
            key: cleanName,
            label: documentType ? documentType.label : cleanName,
        };
    });

    const loadedKeys = loadedDocuments.map((doc) => doc.key);

    const missingDocuments = documentTypes
        .filter((doc) => !loadedKeys.includes(doc.key))
        .map((doc) => ({
            ...doc,
            preinscription_id: preinscription_id,
        }));

    /////////////////////////// Render ///////////////////////////

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{ width: '100%', padding: 2 }}>
            {loadedDocuments.length > 0 && (
                <>
                    <Divider
                        textAlign="left"
                        sx={{
                            width: '80%',
                            my: 2,
                            borderColor: 'primary.main',
                        }}>
                        <Typography
                            variant="h6"
                            color="textSecondary"
                            style={{
                                fontFamily: 'Roboto, sans-serif',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                textAlign: 'left',
                                width: '100%',
                            }}>
                            Documentos Subidos
                        </Typography>
                    </Divider>
                    <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        flexWrap="wrap">
                        {loadedDocuments.map((doc) => {
                            const statusInfo = getStatusInfo(doc.status);

                            return (
                                <Card
                                    className="mt-3 mb-3"
                                    key={doc.key}
                                    sx={{
                                        minWidth: 300,
                                        minHeight: 300,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        borderRadius: 2,
                                    }}>
                                    <CardContent>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            alignItems="center">
                                            <Typography variant="h4">
                                                📑
                                            </Typography>
                                            <Typography variant="h6">
                                                {doc.label}
                                            </Typography>
                                        </Stack>
                                        <Divider sx={{ my: 1 }} />
                                        <Box
                                            sx={{
                                                backgroundColor:
                                                    statusInfo.color,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderRadius: 2,
                                                padding: 1,
                                            }}>
                                            <statusInfo.icon
                                                fontSize="small"
                                                sx={{ color: '#fff' }}
                                            />
                                            <Typography
                                                variant="body2"
                                                fontWeight="bold"
                                                sx={{ color: 'white', mt: 1 }}>
                                                {statusInfo.text}
                                            </Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                            mt={2}
                                            p={1}
                                            border={1}
                                            borderRadius={2}
                                            borderColor="grey.300"
                                            bgcolor="grey.100"
                                            width="100%">
                                            <Typography
                                                variant="body2"
                                                fontWeight="bold">
                                                Observaciones:
                                            </Typography>
                                            <Typography variant="body2">
                                                {doc.observations
                                                    ? doc.observations
                                                    : 'No tienes observaciones'}
                                            </Typography>
                                        </Box>
                                    </CardContent>

                                    <CardActions
                                        sx={{ justifyContent: 'center' }}>
                                        <Tooltip title="Visualizar documento">
                                            <IconButton
                                                onClick={() =>
                                                    handleViewFile(doc.url)
                                                }
                                                disabled={!doc.url}>
                                                <Visibility />
                                                <Typography
                                                    variant="body2"
                                                    sx={{ ml: 1 }}>
                                                    Visualizar
                                                </Typography>
                                            </IconButton>
                                        </Tooltip>

                                        <IconButton component="label">
                                            <Upload />
                                            <Typography
                                                variant="body2"
                                                sx={{ ml: 1 }}>
                                                Subir Archivo
                                            </Typography>
                                            <input
                                                type="file"
                                                hidden
                                                accept=".pdf"
                                                onChange={(e) =>
                                                    handleUpdatedFileChange(
                                                        e,
                                                        doc.key,
                                                        doc.preinscription_id,
                                                        doc.document_preinscription_id
                                                    )
                                                }
                                            />
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            );
                        })}
                    </Stack>
                </>
            )}

            {missingDocuments.length > 0 && (
                <>
                    <Divider
                        textAlign="left"
                        sx={{
                            width: '80%',
                            my: 2,
                            borderColor: 'primary.main',
                        }}>
                        <Typography
                            variant="h6"
                            color="textSecondary"
                            style={{
                                fontFamily: 'Roboto, sans-serif',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                textAlign: 'left',
                                width: '100%',
                            }}>
                            Documentos Faltantes
                        </Typography>
                    </Divider>

                    <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        flexWrap="wrap">
                        {missingDocuments.map((doc) => (
                            <Card
                                className="mt-3 mb-3"
                                key={doc.key}
                                sx={{
                                    minWidth: 300,
                                    minHeight: 200,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    borderRadius: 2,
                                }}>
                                <CardContent>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        alignItems="center">
                                        <Typography variant="h4">📑</Typography>
                                        <Typography variant="h6">
                                            {doc.label}
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ my: 1 }} />

                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        justifyContent="center"
                                        borderRadius={2}
                                        p={2}
                                        bgcolor="#b0b0b0"
                                        width="100%">
                                        <ErrorOutline
                                            sx={{
                                                color: 'white',
                                                fontSize: 40,
                                            }}
                                        />
                                        <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                            sx={{ color: 'white', mt: 1 }}>
                                            Documento Faltante
                                        </Typography>
                                    </Box>
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'center' }}>
                                    <Tooltip title="Visualizar documento">
                                        <IconButton disabled>
                                            <Visibility />
                                            <Typography
                                                variant="body2"
                                                sx={{ ml: 1 }}>
                                                Visualizar
                                            </Typography>
                                        </IconButton>
                                    </Tooltip>

                                    <IconButton component="label">
                                        <Upload />
                                        <Typography
                                            variant="body2"
                                            sx={{ ml: 1 }}>
                                            Subir Archivo
                                        </Typography>
                                        <input
                                            type="file"
                                            hidden
                                            accept=".pdf"
                                            onChange={(e) =>
                                                handleUploadedFileChange(
                                                    e,
                                                    doc.key,
                                                    doc.preinscription_id
                                                )
                                            }
                                        />
                                    </IconButton>
                                </CardActions>
                            </Card>
                        ))}
                    </Stack>
                </>
            )}
            <ModalComponent
                OpenModal={isModalVisible}
                setOpenModal={setIsModalVisible}
                title={'Documentos'}
                subtitle={'Documentos proporcionados por el alumno'}>
                <ViewFile
                    file={selectedDocument}
                    onClose={() => setIsModalVisible(false)}
                />
            </ModalComponent>
        </Stack>
    );
};

export default VisualCardDocuments;