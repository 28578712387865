import React, { useState } from 'react';
import {
    Box,
    Checkbox,
    Menu,
    MenuItem,
    TextField,
    Typography,
    IconButton,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DnsIcon from '@mui/icons-material/Dns';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import PersonIcon from '@mui/icons-material/Person';
import { useAuth } from '../../../../../hooks';

const CalificationMenu = React.memo(
    ({
        value,
        studentId,
        description,
        add_description,
        in_recovery,
        index,
        column,
        updated_by,
        onBlur,
        onChange,
        disabled,
        shouldShowCheckbox,
        isCheckboxEnabled,
    }) => {
        ////////// LOCAL STATE //////////
        const [anchorEl, setAnchorEl] = useState(null);

        const Auth = useAuth();
        const userId = Auth.getUser().user_id;

        const isRegularizationCell = /r-[0-9]+/.test(column);

        /*
         * Manejo del click para el anchor del menu
         */
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        /*
         * Manejo del cerrado del menu
         */
        const handleClose = () => {
            setAnchorEl(null);
        };

        /*
         * Manejo del cambio de estado
         * de las calificación
         */
        const handleCalificationChange = (e) => {
            if (e.key === 'r' || e.key === 'c') {
                e.preventDefault();
                return;
            }
            onChange({
                studentId: studentId,
                column,
                index,
                event: {
                    score: e.target.value,
                    type: 2,
                },
            });
        };

        /*
         * Manejo del cambio de estado
         * del comentario de calificación
         */
        const handleCommentChange = (e) => {
            if (e.key === 'r' || e.key === 'c') {
                e.preventDefault();
                return;
            }
            const comment = e.target.value.slice(0, 250);
            onChange({
                studentId: studentId,
                column,
                index,
                event: {
                    comment: comment,
                    type: 1,
                },
            });
        };

        /*
         * Manejo del cambio de estado
         * del checkbox de recuperación
         */
        const handleCheckboxChange = (e) => {
            if (e.key === 'r' || e.key === 'c') {
                e.preventDefault();
                return;
            }
            onChange({
                studentId: studentId,
                column,
                index,
                event: {
                    checked: e.target.checked,
                    type: 3,
                },
            });
        };

        /*
         * Función que evita que al presionar
         * las teclas "r" y "c" se pierda focus
         * de los inputs
         */
        const handleKeyDown = (e) => {
            e.stopPropagation();
        };

        /*
         * Función que hace el salto de linea
         * en el comentario mostrado en el menu
         */
        const splitTextIntoLines = (text, wordsPerLine) => {
            const words = text.split(' ');
            const lines = [];

            for (let i = 0; i < words.length; i += wordsPerLine) {
                lines.push(words.slice(i, i + wordsPerLine).join(' '));
            }

            return lines;
        };

        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%',
                        gap: '8px',
                    }}
                >
                    <TextField
                        value={value}
                        onChange={handleCalificationChange}
                        onBlur={(e) => {
                            onBlur({
                                column: column,
                                value: value,
                                index,
                                in_recovery,
                            });
                        }}
                        variant="outlined"
                        size="small"
                        disabled={disabled}
                        placeholder="-"
                        sx={{
                            flex: '1 1 auto',
                            height: '40px',
                        }}
                        InputProps={{
                            style: { height: '40px' },
                        }}
                    />
                    {updated_by === 2 ? (
                        <DnsIcon
                            sx={{
                                color: '#0a90d3',
                            }}
                        />
                    ) : updated_by !== userId ? (
                        <LaptopChromebookIcon
                            sx={{
                                color: '#ffbf43',
                            }}
                        />
                    ) : (
                        <PersonIcon
                            sx={{
                                color: 'grey',
                            }}
                        />
                    )}
                    <IconButton
                        onClick={handleClick}
                        sx={{
                            padding: '4px',
                            height: '40px',
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </div>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem disableRipple>
                        <Typography sx={{ pl: 1 }} variant="p">
                            {splitTextIntoLines(description, 3).map(
                                (line, index) => (
                                    <div key={index}>{line}</div>
                                )
                            )}
                        </Typography>
                    </MenuItem>
                    <MenuItem disableRipple>
                        <Box display="flex" flexDirection="column">
                            <TextField
                                id="comment-input"
                                label="Comentario"
                                variant="outlined"
                                value={add_description}
                                multiline
                                onChange={handleCommentChange}
                                disabled={disabled}
                                onKeyDown={handleKeyDown}
                                inputProps={{
                                    maxLength: description
                                        ? 250 - description.length
                                        : 250,
                                }}
                            />
                            <Typography variant="caption" color="textSecondary">
                                {description
                                    ? `${add_description.length}/${
                                          250 - description.length
                                      }`
                                    : `${add_description.length}/250`}
                            </Typography>
                        </Box>
                    </MenuItem>
                    {!isRegularizationCell && shouldShowCheckbox && (
                        <MenuItem disableRipple>
                            <Checkbox
                                id="recovery-checkbox"
                                checked={in_recovery}
                                onChange={handleCheckboxChange}
                                disabled={isCheckboxEnabled}
                            />
                            <Typography>Recuperación</Typography>
                        </MenuItem>
                    )}
                </Menu>
            </>
        );
    }
);

export default CalificationMenu;
