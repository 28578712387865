import Authentication from './Login';
import enviroment from './Service';

/**
 * Clase encargada de mapear la API de eficaz
 */
class EficazAPI {
    httpClient = null;

    /**
     *
     * @param {*} httpClient Cliente que sera usado para realizar las peticiones
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Retorna el cliente HTTP actual
     *
     * @returns HttpClient
     */
    getClient() {
        return this.httpClient;
    }

    /**
     * Recuperar recursos del servidor paginados
     *
     * @param {string} method Metodo que sera usado por a API
     * @param {array} params Parametros que seran enviados a method
     * @param {object} queryParameters Parametros de query que ira en la solicitud
     *
     * @returns
     */
    async getResourcesPaginatedFromServer(
        method,
        params,
        queryParameters = {},
        lastPage = null
    ) {
        let currentPage = 0;

        let items = [];
        let metas = [];

        let parameter = {
            per_page: 50,
            page: currentPage,
        };

        if (queryParameters) {
            parameter = { ...parameter, ...queryParameters };
        }

        do {
            currentPage++;

            parameter.page = currentPage;

            let itemsResponse = await this[method](...params, parameter).then(
                (res) => res.data
            );

            let itemsPaginated = itemsResponse.data;
            let meta = itemsResponse.meta;

            if (lastPage == null) {
                lastPage = meta.last_page;
            }

            items = items.concat(itemsPaginated);
            metas = metas.concat(meta);
        } while (currentPage < lastPage);

        return {
            metas,
            data: items,
        };
    }

    ///////////////////////////////////// CONEXIONES DE LA API ////////////////////////////////////////////////////////

    /**
     * Se puede cambiar contraseña, password e informacion
     *
     * @param {*} userID
     * @param {*} newCredential
     * @returns
     */
    updateUser(userID, data) {
        return this.updateGenericUser('/users', userID, data);
    }

    /**
     * Se puede cambiar contraseña, password e informacion
     *
     * @param {*} userID
     * @param {*} newCredential
     * @returns
     */
    updateGenericUser(endpoint, userID, data) {
        return this.httpClient.put(`${endpoint}/${userID}`, data);
    }

    /**
     * Actualizar imagen de usuario
     *
     * @param {*} userID
     * @param {*} newCredential
     * @returns
     */
    uploadUserImage(endpoint, userID, data) {
        return this.httpClient.post(`${endpoint}/${userID}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    /**
     * Actualziar imagen de escuela
     *
     * @param {*} userID
     * @param {*} newCredential
     * @returns
     */
    uploadSchoolImage(settingId, data) {
        return this.httpClient.post(`settings/${settingId}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    /**
     * Actualziar imagen de Alumno
     *
     * @param {*} idStudent
     * @param {*} newCredential
     * @returns
     */
    uploadStudentImage(idStudent, data) {
        return this.httpClient.post(`/students/${idStudent}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    /**
     * Recuperar todos los parciales activos de una escuela
     *
     * @param numeric idSchool
     * @param {*} data
     * @returns Promise
     */
    getPartials(idSchool) {
        return this.httpClient.get(
            `/schools/${idSchool}/partials?filters={"status":1}&&fields={"except":["link","created_at","updated_at"]}`
        );
    }

    /**
     * Crear un parcial
     *
     * @param numeric idSchool
     * @param {*} data
     * @returns Promise
     */
    setPartials(idSchool, data) {
        return this.httpClient.post(`/schools/${idSchool}/partials`, data);
    }

    /**
     * Actualizar un parcial
     *
     * @param numeric idPartial
     * @param {*} data
     * @returns Promise
     */
    updatePartial(idPartial, data) {
        return this.httpClient.patch(`/partials/${idPartial}`, data);
    }

    /**
     * Eliminar un parcial
     *
     * @param numeric idPartial
     * @returns Promise
     */
    deletePartial(idPartial) {
        return this.httpClient.delete(`/partials/${idPartial}`);
    }

    loginMe(token) {
        return this.httpClient.get(
            `${enviroment.RUTA_API}${enviroment.VERSION}/me/information`,
            {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            }
        );
    }

    /**
     * Recuperar todos los grupos de una escuela
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} queryParameters Opciones de filtrado
     *
     * @returns
     */
    groupsBySchool(schoolId, queryParameters = null) {
        let endpoint = `/schools/${schoolId}/groups`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Recuperar todos los profesores de una escuela
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} queryParameters nombre del corte
     *
     * @returns
     */
    professorsBySchool(idSchool, queryParameters = null) {
        let Auth = new Authentication();
        let endpoint = `/schools/${idSchool}/professors`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            'not-calculate-properties': true,
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`).then((r) => {
            let payload = r.data.data;

            r.data.data = payload.map((i) => ({
                ...i,
                user_id: Auth.getUserID(i),
            }));

            return r;
        });
    }

    /**
     * Recuperar todos los profesores de una escuela con datos calculados
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} queryParameters nombre del corte
     *
     * @returns
     */
    professorsBySchoolDirector(idSchool, queryParameters = null) {
        let Auth = new Authentication();
        let endpoint = `/schools/${idSchool}/professors`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`).then((r) => {
            let payload = r.data.data;

            r.data.data = payload.map((i) => ({
                ...i,
                user_id: Auth.getUserID(i),
            }));

            return r;
        });
    }

    /**
     * Recuperar profesor
     *
     * @param {*} idProfessor Identificador del profesor
     * @returns
     */
    getProfessorsById(idProfessor) {
        return this.httpClient.get(
            `/professors/${idProfessor}?fields={"except":["link","created_at","updated_at"]}`
        );
    }

    /**
     * Recuperar todos los estudiantes de una escuela
     *
     * @param {*} schoolId Identificador de la escuela
     * @param {*} queryParameters Contiene un Objeto que sera serialisado
     *
     * @returns
     */
    StudentsBySchool(schoolId, queryParameters = {}) {
        let endpoint = `/schools/${schoolId}/students`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Combierte un Objecto de JS en un QueryHttp
     * @param {*} object
     *
     * @returns
     */
    encodeQuery(object) {
        return Object.entries(object)
            .map(([key, val]) => {
                let value = this.isObject(val)
                    ? encodeURIComponent(JSON.stringify(val))
                    : val;

                return `${encodeURIComponent(key)}=${value}`;
            })
            .join('&');
    }

    isObject(obj) {
        return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
    }

    /**
     * Recuperamos un alumno usando si id
     *
     * @param {*} idStudent
     *
     * @returns
     */
    getStudentById(idStudent, queryParameters = {}) {
        let endpoint = `/students/${idStudent}`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    getStudentByIdV2(idStudent, queryParameters = {}) {
        let endpoint = `/preinscriptions/students/${idStudent}`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.getV2(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Recuperamos un alumno usando si id
     *
     * @param {*} idStudent
     *
     * @returns
     */
    searchStudentByCURP(curp) {
        return this.httpClient.get(
            `/students?search=${curp}&fields={"except":["link","created_at","updated_at"]}`
        );
    }

    searchStudentByCURPV2(curp) {
        let endpoint = `/preinscriptions/students?curp=${curp}`;
        return this.httpClient.getV2(endpoint);
    }

    /**
     * Muestra los grupos asesorados
     *
     * @param {*} userId
     *
     * @returns
     */
    getSchoolByAssessor(userId) {
        const endpoint = `/assessors/${userId}/groups?filters={"group_type":[1,2,3]}&fields={"except":["link","created_at","updated_at"]}&"not-calculate-properties"=true`;
        return this.httpClient.get(`${endpoint}`);
    }

    /**
     * Recuperar todos los asesores de una escuela
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} queryParameters nombre del corte
     *
     * @returns
     */
    getAssesorsBySchool(idSchool, queryParameters = {}) {
        let Auth = new Authentication();
        let endpoint = `/schools/${idSchool}/assessors`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`).then((r) => {
            let payload = r.data.data;

            r.data.data = payload.map((i) => ({
                ...i,
                user_id: Auth.getUserID(i),
            }));

            return r;
        });
    }

    /**
     * Recuperar assesores activos
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    getAssesorsActivedBySchool(idSchool) {
        let url = `/schools/${idSchool}/assessors?filters={"status":1}&fields={"except":["link","created_at","updated_at"]}`;

        return this.httpClient.get(url);
    }

    /**
     * Recuperar profesores activos
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {object} queryParameters
     *
     * @returns
     */
    getProfessorsActivedBySchool(idSchool, queryParameters = {}) {
        let endpoint = `/schools/${idSchool}/professors`;

        const queryEncoded = this.encodeQuery({
            filters: { status: 1 },
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Recuperar los alumnos de un grupo
     *
     * @param {integer} idGroup
     * @param {object} queryParameters
     *
     * @returns
     */
    StudentsByGroup(idGroup, queryParameters = {}) {
        let endpoint = `/groups/${idGroup}/students`;

        const queryEncoded = this.encodeQuery({
            filters: { status: 1 },
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Recuperar todos los alumnos de un grupo
     *
     * @param {interger} idSchool Identificador de la escuela
     * @param {object} queryParameters
     *
     * @returns
     */
    getStudentsByGroup(groupID, queryParameters = {}) {
        return this.StudentsByGroup(groupID, queryParameters);
    }

    /**
     * Recuperar todos los alumnos de un grupo que tengan mas de una relacion
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    getStudentsHasRelationshipsByGroup(groupID) {
        return this.getStudentsByGroup(groupID).then((response) => {
            let students = response.data.data;

            response.data.data = students.filter((s) => s.relationships > 0);

            return response;
        });
    }

    /**
     * Recuperar toas las calificaciones de un alumno
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    calificationsByStudent(idStudent, queryParameters = {}) {
        let endpoint = `/students/${idStudent}/califications`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    getCalificationsByGroupId(group_id, queryParameters = {}) {
        let endpoint = `/groups/${group_id}/califications`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Recuperar toas las calificaciones de un alumno
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    getCalificationaBySubject(subjectID, queryParameters = {}) {
        let endpoint = `/subjects/${subjectID}/califications`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    assistsByStudent(idStudent) {
        return this.httpClient.get(
            `${enviroment.RUTA_API}/students/${idStudent}/assists?fields={"except":["link","created_at","updated_at"]}`
        );
    }

    /**
     * Recuperar informacion de una escuela
     *
     * @param numeric idSchool
     * @returns Promise
     */
    schoolInfo(idSchool, queryParameters = {}) {
        let endpoint = `/schools/${idSchool}`;

        let parameters = {
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            'not-calculate-properties': true,
            ...queryParameters,
        };
        const parametersEncoded = this.encodeQuery(parameters);

        return this.httpClient.get(`${endpoint}?${parametersEncoded}`);
    }

    /**
     * Recuperar informacion de una escuela
     *
     * @param numeric idSchool
     * @returns Promise
     */
    getSchoolById(idSchool, queryParameters = {}) {
        let endpoint = `/schools/${idSchool}`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            'not-calculate-properties': true,
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Recuperar informacion de una escuela con datos calculados
     *
     * @param numeric idSchool
     * @returns Promise
     */
    getSchoolByIdDirector(idSchool, queryParameters = {}) {
        let endpoint = `/schools/${idSchool}`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }
    /**
     * Recuperar informacion de una escuela sin datos calculados
     *
     * @param numeric idSchool
     * @returns Promise
     */
    getSchoolByIdDirectorNoneCalculated(idSchool, queryParameters = {}) {
        let endpoint = `/schools/${idSchool}`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            'not-calculate-properties': true,
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Recuperar informacion de una escuela
     *
     * @param numeric idSchool
     * @returns Promise
     */
    getHomeSupervisor(queryParameters = {}) {
        let endpoint = `/supervisor-schools`;

        const queryEncoded = this.encodeQuery({
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}`);
    }

    /**
     * Recuperar informacion de usuarios supervisor
     *
     * @param numeric idSchool
     * @returns Promise
     */
    getUsersDetailsSupervisor(idSchool, queryParameters = {}) {
        let endpoint = `/supervisor-schools-details?school_id=${idSchool}`;

        const queryEncoded = this.encodeQuery({
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}`);
    }

    getCalifications(idSchool) {
        return this.httpClient.get(
            `/schools/${idSchool}?fields={"except":["link","created_at","updated_at"]}`
        );
    }

    /**
     * Actualiza la informacion de una escuela
     *
     * @param {*} idSchool
     * @param {*} data
     * @returns
     */
    UpdateSchoolInfo(idSchool, data) {
        return this.httpClient.patch(`/schools/${idSchool}`, data);
    }

    schooSettings(idSchool) {
        return this.httpClient.get(
            `/schools/${idSchool}/settings?fields={"except":["link","created_at","updated_at"]}`
        );
    }

    /**
     * Actualizar una configuracion de una escuela
     *
     * @param numeric idSetting
     * @param any valor
     * @returns Promise
     */
    setSchoolSettings(idSetting, valor) {
        let data = {
            value: valor,
        };

        return this.httpClient.patch(`/settings/${idSetting}`, data);
    }

    /**
     * Recuperamos todas las noticias de una escuela
     *
     * @param {*} idSchool
     * @returns
     */
    noticesBySchool(idSchool, queryParameters = {}) {
        let endpoint = `/schools/${idSchool}/notices`;

        const queryEncoded = this.encodeQuery({
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Recuperamos todas las noticias de una escuela
     *
     * @param {*} idSchool
     * @returns
     */
    getNoticesByType(idSchool, type, queryParameters = {}) {
        let endpoint = `/schools/${idSchool}/notices`;

        const queryEncoded = this.encodeQuery({
            filters: { type: type },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Recuperamos las notificaciones por avisa
     * @param {*}
     * @returns
     */

    getNotificationsByNotice(idNotice, queryParameters = {}) {
        let endpoint = `/notices/${idNotice}/notifications`;
        const queryEncoded = this.encodeQuery({
            ...queryParameters,
        });
        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    getSpokesmen(spokesman_id) {
        return this.httpClient.get(`/spokesmen/${spokesman_id}`);
    }

    /**
     * Recupera las configuraciones de una escuela
     * @param {*} idSchool
     *
     * @returns
     */
    getSettingsBySchool(idSchool) {
        return this.httpClient.get(`/schools/${idSchool}/settings`);
    }

    /**
     * Recupermos los reportes por escuela
     *
     * @param {*} idSchool Identificador de la escuela
     * @returns
     */
    getReportBySchool(idSchool, queryParameters = {}) {
        let endpoint = `/schools/${idSchool}/reports`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     *  Obtenemos el catalogo de reportes
     *
     * @param {*} idSchool
     * @returns
     */
    getReportCatalogBySchool(idSchool) {
        return this.httpClient.get(`/schools/${idSchool}/reportcatalogs`);
    }

    /**
     * Recupermos los reportes por alumno
     *
     * @param {*} idStudent Identificador de la escuela
     * @returns
     */
    getReportsByStudentId(idStudent) {
        return this.httpClient.get(`/students/${idStudent}/reports`);
    }

    /**
     * Crear un catalogo de reportes
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} data Datos a registrar
     *
     * @returns
     */
    setReportCatalogBySchool(idSchool, data) {
        return this.httpClient.post(
            `/schools/${idSchool}/reportcatalogs`,
            data
        );
    }

    /**
     * Actualizar catalogo
     *
     * @param {*} idCatalogo Identificador de catalogo
     * @param {*} data Datos a actualizar
     *
     * @returns
     */
    updateReportCatalog(idCatalogo, data) {
        return this.httpClient.patch(`/reportcatalogs/${idCatalogo}`, data);
    }

    /**
     * Recuperamos el expediante al que pertenece un reporte
     *
     * @param {*} idReport
     * @returns
     */
    getFilesByReport(idReport) {
        return this.httpClient.get(`/reports/${idReport}/files`);
    }

    getStudentByFile(idFile) {
        return this.httpClient.get(`/files/${idFile}/students`);
    }

    /**
     * Recuperar todo el catalogo de materias de una escuela
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} queryParameters nombre del corte
     *
     * @returns
     */
    getSubjectCatalogBySchool(idSchool, queryParameters = {}) {
        let endpoint = `/schools/${idSchool}/subjectcatalogs`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Recueprar el catalogo de materia
     *
     * @param {*} idCatalog
     * @returns
     */
    getSubjectCatalogById(idCatalog) {
        return this.httpClient.get(
            `/subjectcatalogs/${idCatalog}?fields={"except":["link","created_at","updated_at"]}`
        );
    }

    getSchoolBySubjectCatalog(idCatalog) {
        return this.httpClient.get(
            `/subjectcatalogs/${idCatalog}/schools?fields={"except":["link","created_at","updated_at"]}`
        );
    }

    /**
     * Almacena un catalogo de una materia
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} data datos de la neuva materia
     *
     * @returns
     */
    setSubjecyCatalogBySchool(idSchool, data) {
        return this.httpClient.post(
            `/schools/${idSchool}/subjectcatalogs`,
            data
        );
    }

    /**
     * Registrar materia a un profesor
     *
     * @param {*} idProfessor Identificador del profesor
     * @param {*} data Datos de la materia a asignar
     *
     * @returns
     */
    setSubjectByProfessor(idProfessor, data) {
        return this.httpClient.post(
            `/professors/${idProfessor}/subjects`,
            data
        );
    }

    /**
     * Creamos materias
     *
     * @param {*} data Identificador de la escuela
     *
     * @returns
     */
    setSubject(data) {
        return this.httpClient.post(`/subjects`, data);
    }

    /**
     * Eliminar materia
     *
     * @param {*} subjectId Identificador de la materia
     *
     * @returns
     */
    deleteSubject(subjectId) {
        return this.httpClient.delete(`/subjects/${subjectId}`);
    }

    /**
     * Eliminar multiples materias
     *
     * @param {*} subjectId Identificador de las materias
     *
     * @returns
     */
    deleteMultiSubjects(subjectIds) {
        const url = `/subjects`;
        return this.httpClient.delete(url, {
            data: { data: subjectIds },
        });
    }

    /**
     * Borrar multiples materias principales de un grupo
     */

    deleteManyPrincipalSubjects(groupId, data) {
        return this.httpClient.post(`/groups/${groupId}/subjects`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    /**
     * Recuperar todas las materias de una escuela
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} queryParameters nombre del corte
     *
     * @returns
     */
    getSubjectsBySchool(idSchool, queryParameters = {}) {
        let endpoint = `/schools/${idSchool}/subjects`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    getCalificationsBySchool(idSchool) {
        return this.httpClient.get(
            `/schools/${idSchool}/subjects?fields={"except":["link","created_at","updated_at"]}`
        );
    }

    getSubjectByCatalog(idSchool) {
        return this.httpClient.get(
            `/subjectcatalogs/${idSchool}/subjects?fields={"except":["link","created_at","updated_at"]}`
        );
    }

    /**
     * Actualziar un catalogo de materias
     *
     * @param {*} idCatalog Identificador del catalogo
     * @param {*} data Datos actualizar
     *
     * @returns
     */
    updateSubjectCatalog(idCatalog, data) {
        return this.httpClient.patch(`/subjectcatalogs/${idCatalog}`, data);
    }

    /**
     * Recuperar materias por grupo
     *
     * @param {integer} idGroup
     * @param {object} queryParameters
     *
     * @returns
     */
    getSubjectByGroup(idGroup, queryParameters = {}) {
        let endpoint = `/groups/${idGroup}/subjects`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Actualiza la informacion de un grupo
     *
     * @param {*} idGroup
     * @param {*} data
     * @returns
     */
    updateGroupById(idGroup, data) {
        return this.httpClient.patch(`/groups/${idGroup}`, data);
    }

    /**
     * Recupera el grupo pincipal del alumno
     *
     * @param {*} idStudent
     *
     * @returns
     */
    getGroupByStudent(idStudent) {
        return this.httpClient.get(
            `/students/${idStudent}/groups?fields={"except":["link","created_at","updated_at"]}`
        );
    }

    getGroupByStudentFilter(idStudent, queryParameters = {}) {
        let endpoint = `/students/${idStudent}/groups`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Recupera el los grupos especiales del alumno
     *
     * @param {*} idStudent
     *
     * @returns
     */
    getSpecialGroupsByStudent(idStudent) {
        return this.httpClient.get(
            `/students/${idStudent}/specialgroups?fields={"except":["link","created_at","updated_at"]}`
        );
    }

    recoverAccount(data) {
        return this.httpClient.post(`/pwd/email`, data);
    }

    resetAccount(data) {
        return this.httpClient.post(`/pwd/reset`, data);
    }

    getActivitiesBySchool(schoolId) {
        return this.httpClient.get(
            `/schools/${schoolId}/activities?filters={"status":1}`
        );
    }

    storeActivity(schoolId, data) {
        return this.httpClient.post(`/schools/${schoolId}/activities`, data);
    }

    updateActivity(activityId, data) {
        return this.httpClient.patch(`/activities/${activityId}`, data);
    }

    deleteActivity(activityId) {
        return this.httpClient.delete(`/activities/${activityId}`);
    }

    /**
     * Recuperar todos los ciclso de una escuela
     *
     * los snaphost no cuenta con la tabla de ciclos
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    getCyclesBySchool(schoolId) {
        return this.httpClient.get(`/schools/${schoolId}/cycles`);
    }

    /**
     * Obtenemos un archivo de exportacion del webservice
     *
     * @param reportName
     * @param typeFormat
     * @param queryReports
     * @returns Promise
     */
    getExportable(reportName, typeFormat, queryReports, cycle = null) {
        let url = `/exportable?report=${reportName}&format=${typeFormat}&data=${JSON.stringify(
            queryReports
        )}`;

        if (cycle) {
            url += `&cycle=${cycle}`;
        }

        return this.httpClient.get(url, { responseType: 'blob' });
    }

    /**
     * get exportable for postulations
     */
    getPostulationsExportable(report, format, data) {
        let url = `preinscriptions/exports?report=${report}&format=${format}&data=${JSON.stringify(
            data
        )}`;

        return this.httpClient.getV2(url, { responseType: 'blob' });
    }

    /**
     * get a example file
     */
    getExampleFile(key, formatFile) {
        let url = `/deposits/formats/${key}?format=${formatFile}`;

        return this.httpClient.get(url, { responseType: 'blob' });
    }

    /**
     * Obtenemos todos las materias de un alumno
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    getSubjectsByStudent(studentID, queryParameters = {}) {
        let endpoint = `/students/${studentID}/subjects`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Obtiene las asistencias de un alumno
     *
     * @param {*} idStudent
     *
     * @returns
     */
    getAssistensByStudent(idStudent) {
        return this.httpClient.get(
            `/students/${idStudent}/assists?fields={"except":["link","created_at","updated_at"]}`
        );
    }

    getAssistsByGroupId(group_id, queryParameters = {}) {
        let endpoint = `/groups/${group_id}/assists`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Obtenemos las calificaciones de un alumno
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    getCalificationbyStudent(idStudent, options = null) {
        let initalFileds = `?fields={"except":["link","created_at","updated_at"]}`;

        let url = `/students/${idStudent}/califications${initalFileds}`;

        if (options) {
            url += options.filters;
        }

        return this.httpClient.get(url);
    }

    getPartialsByLevel(idSchool, level) {
        return this.httpClient.get(
            `/schools/${idSchool}/partials?filters={"status":1, "level": ${level}}&&fields={"except":["link","created_at","updated_at"]}`,
            {
                body: {},
            }
        );
    }

    getSubjectCatalogBySubject(idSubject) {
        return this.httpClient.get(
            `/subjects/${idSubject}/subjectcatalogs?fields={"except":["link","created_at","updated_at"]}`
        );
    }

    /**
     * Recuperamos todos los padres de una escuela
     *
     * @param {integer} idSchool
     * @param {object} queryParameters
     * @returns
     */
    getParentsBySchool(idSchool, queryParameters = {}) {
        let endpoint = `/schools/${idSchool}/parents`;

        let parameters = {
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        };

        const parametersEncoded = this.encodeQuery(parameters);

        return this.httpClient.get(`${endpoint}?${parametersEncoded}`);
    }

    /**
     * Recuperamos datos de un grupo
     *
     * @param {*} idGroup Identificador del grupo
     *
     * @returns
     */
    getGroupById(idGroup) {
        return this.httpClient.get(
            `/groups/${idGroup}?fields={"except":["link","created_at","updated_at"]}`
        );
    }

    /**
     * Recuperamos datos de los grupos enviados por parametros
     *
     * @param schoolId Identificador de la escuela
     * @param {*} groupsIds Identificador del los grupos
     *
     * @returns
     */
    getGroupByIds(groupsIds, schoolId = null) {
        // TODO HACER OPCIONA EL ID DE LA ESCUELA
        return this.httpClient.get(
            `/schools/${schoolId}/groups?filters={"group_id":[${groupsIds}]}&fields={"except":["link","created_at","updated_at"]}`
        );
    }

    /**
     * Recuperar todos los directores de una escuela
     *
     * @param {*} idSchool
     * @returns
     */
    getDirectorsBySchool(idSchool) {
        return this.httpClient.get(
            `/directors?filters={"school_id":${idSchool}}&fields={"except":["link","created_at","updated_at"]}`
        );
    }

    /**
     * Recuperar directores por id
     *
     * @param {*} idSchool
     * @returns
     */
    getDirectorsById(userID) {
        return this.httpClient.get(
            `/directors?filters={"user_id":${userID}}&fields={"except":["created_at","updated_at"]}`
        );
    }

    /**
     * Crear directores
     *
     * @param {*} idSchool
     * @param {*} data
     * @returns
     */
    setUserDirectorBySchool(schoolId, data) {
        return this.httpClient.post(`/schools/${schoolId}/directors`, data);
    }

    /**
     * Crear padres sin escuela
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} data Datos del usuario
     * @returns
     */
    setUserParent(data) {
        return this.httpClient.post(`/parents`, data);
    }

    /**
     * Crear padres con una escuela establecida
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} data Datos del usuario
     * @returns
     */
    storeParentSchool(schoolId, data) {
        return this.httpClient.post(`/schools/${schoolId}/parents`, data);
    }

    updateUserDirectorById(idDirector, data) {
        return this.httpClient.patch(`/directors/${idDirector}`, data);
    }

    setUserDirectorrBySchool(idSchool, data) {
        return this.httpClient.get(`/schools/${idSchool}/directors`, data);
    }

    getOrientadoresBySchool(idSchool) {
        return this.httpClient.get(
            `/schools/${idSchool}/assessors?fields={"except":["link","created_at","updated_at"]}`
        );
    }

    updateUserOrientadorById(idOrientador, data) {
        return this.httpClient.patch(`/assessors/${idOrientador}`, data);
    }

    /**
     * Crear un orientador
     *
     * @param {*} idSchool
     * @param {*} data
     * @returns
     */
    setUserOrientadorBySchool(idSchool, data) {
        return this.httpClient.post(`/schools/${idSchool}/assessors`, data);
    }

    /**
     * Recupera los administradores de una escuela
     *
     * @param {*} idSchool
     * @returns
     */
    getAdministratorssBySchool(idSchool) {
        return this.httpClient.get(
            `/schools/${idSchool}/administrators?fields={"except":["link","created_at","updated_at"]}`
        );
    }

    updateUserAdministratorsById(idAdministrators, data) {
        return this.httpClient.patch(
            `/administrators/${idAdministrators}`,
            data
        );
    }

    /**
     * Crear un administrador
     *
     * @param {*} idSchool
     * @param {*} data
     * @returns
     */
    setUserAdministratorsBySchool(idSchool, data) {
        return this.httpClient.post(
            `/schools/${idSchool}/administrators`,
            data
        );
    }

    /**
     * Crear un profesor
     *
     * @param {*} idSchool
     * @param {*} data
     * @returns
     */
    setUserProfessorBySchool(idSchool, data) {
        return this.httpClient.post(`/schools/${idSchool}/professors`, data);
    }

    updateUserProfessorBySchool(idProfessor, data) {
        return this.httpClient.patch(`/professors/${idProfessor}`, data);
    }

    /**
     * Recuperar todos los voseros de una escuela
     *
     * @param {*} idSchool
     * @returns
     */
    getVocerosBySchool(idSchool) {
        return this.httpClient.get(
            `/schools/${idSchool}/spokesmen?fields={"except":["link","created_at","updated_at"]}`
        );
    }

    updateUserVoceroById(idAdministrators, data) {
        return this.httpClient.patch(`/spokesmen/${idAdministrators}`, data);
    }

    setUserVoceroBySchool(idSchool, data) {
        return this.httpClient.post(`/schools/${idSchool}/spokesmen`, data);
    }

    /**
     * Actualzia contrsseña, username, y el tipo de usuario de un usuario
     *
     * @param {*} idUser
     * @param {*} data
     * @returns
     */
    updateUserById(idUser, data) {
        return this.httpClient.patch(`/users/${idUser}`, data);
    }

    /**
     * Crear grupos
     *
     * @param {*} idSchool
     * @param {*} data
     * @returns
     */
    setGroupBySchool(idSchool, data) {
        return this.httpClient.post(`/schools/${idSchool}/groups`, data);
    }

    /**
     * Eliminar un modulo de un grupo
     *
     * @param {*} module_id Identificador de la materia
     *
     * @returns
     */
    deleteModule(module_id) {
        return this.httpClient.delete(`/modules/${module_id}`);
    }

    /**
     * Eliminar muchos modulo de un grupo
     *
     * @param {*} module_id Identificador de los modulos
     *
     * @returns
     */
    deleteMultiModule(module_id) {
        const url = `/modules`;
        return this.httpClient.delete(url, {
            data: { data: module_id },
        });
    }

    /**
     * Crear modulos
     *
     * @param {*} idGroup
     * @param {*} data
     * @returns
     */
    setModulesByGroup(idGroup, data) {
        return this.httpClient.post(`/groups/${idGroup}/modules`, data);
    }

    /**
     * Actualizar nombre de modulo
     */
    updateModule(moduleId, data) {
        return this.httpClient.put(`/modules/${moduleId}`, data);
    }

    /**
     * Agregar materias a modulo
     */

    addSubjectsToModule(moduleId, data) {
        return this.httpClient.post(`/modules/${moduleId}/subjects`, {
            subjects: data,
        });
    }

    /**
     * Eliminar materias de modulo
     */
    deleteSubjectsToModule(subjectId) {
        return this.httpClient.delete(`subjects/${subjectId}`);
    }

    /**
     * traer modulos por id de grupo
     *
     * @param {*} idGroup
     * @param {*} data
     * @returns
     */
    getModulesByGroup(idGroup, data) {
        return this.httpClient.get(`/groups/${idGroup}/modules`, data);
    }

    /**
     * Traer todos los modulos de las escuela
     */

    getAllSchoolModules(schoolId) {
        return this.httpClient.get(`/schools/${schoolId}/modules`);
    }

    /**
     * Traer todos los modulos de una escuela por nivel
     */
    getAllSchoolModulesByLevelGrade(schoolId, groupLevel, groupGrade) {
        return this.httpClient.get(
            `/schools/${schoolId}/modules?filters={"level": ${groupLevel}, "grade": ${groupGrade}}`
        );
    }

    /**
     * Traer materias de grupos
     *
     * @param {*} idGroup
     * @returns
     */
    getSubjectsByGroup(idGroup) {
        return this.httpClient.get(`/groups/${idGroup}/subjects`);
    }

    /**
     * Traer materias de grupos especificos
     */

    getSpecifiedGroupsSubjects(groupsIds) {
        return this.httpClient.get(
            `/subjects?filters={"group_id":[${groupsIds}]}`
        );
    }

    /**
     * Traer modulos con materias
     *
     * @param {*} idSubject
     * @returns
     */
    getModuleWithSubjects(idSubject) {
        return this.httpClient.get(`/modules/${idSubject}/subjects`);
    }

    /**
     * Traer modulos con materias
     *
     * @param {*} idModule
     * @returns
     */
    getSubjectsByModule(idModule) {
        return this.httpClient.get(`/modules/${idModule}/subjects`);
    }

    /**
     * Recuperar grupos con alumnos
     *
     * @param {*} schoolId
     *
     * @returns
     */
    getGroupsWithStudents(schoolId) {
        return this.groupsBySchool(schoolId).then((i) => {
            let groups = i.data.data;

            i.data.data = groups.filter((g) => g.students != 0);

            return i;
        });
    }

    /**
     * Actualizar materia
     *
     * @param {*} idSubject
     * @param {*} data
     *
     * @returns
     */
    updateSubjectById(idSubject, data) {
        return this.httpClient.patch(`/subjects/${idSubject}`, data);
    }

    /**
     * Obtenemos los padres de u alumno
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    getParentsByStudent(studentID, queryParameters = {}) {
        let endpoint = `/students/${studentID}/parents`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Obtenemos los grupos de un profesor
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    getGroupsByProfessor(professorID, snaphost = null) {
        let url = `/professors/${professorID}/groups`;
        let params = `?fields={"except":["link","created_at","updated_at"]}`;

        if (snaphost) {
            url += params + `&snaphot=${snaphost}`;
        }

        return this.httpClient.get(url);
    }

    /**
     * Obtenemos las materias de un profesor
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    getSubjectsByProfessor(professorID, snaphost = null) {
        let url = `/professors/${professorID}/subjects`;
        let params = `?fields={"except":["link","created_at","updated_at"]}`;

        if (snaphost) {
            url += params + `&snaphot=${snaphost}`;
        }

        return this.httpClient.get(url);
    }

    /**
     * Obtenemos una escuela
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    getSchoolByUser(
        userEndpoint,
        userId,
        snaphost = null,
        queryParameters = {}
    ) {
        let url = `${userEndpoint}/${userId}/schools`;
        let params = `?fields={"except":["link","created_at","updated_at"]}`;

        let parameters = {
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            'not-calculate-properties': true,
            ...queryParameters,
        };
        if (snaphost) {
            url += parameters + `&snaphot=${snaphost}`;
        }

        const parametersEncoded = this.encodeQuery(parameters);

        return this.httpClient.get(`${url}?${parametersEncoded}`);
    }

    /**
     * Obtenemos una escuela
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    getStudentAssists(userId, subjectId, snaphost = null) {
        let params = `?filters={"subject_id":${subjectId}}&fields={"except":["link","created_at","updated_at"]}`;

        let url = `students/${userId}/assists${params}`;

        if (snaphost) {
            url += `&snaphot=${snaphost}`;
        }

        return this.httpClient.get(url);
    }

    /**
     * Almancear la lsita de asistencia
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    storeMultiAssistsList(postData) {
        let url = `/assists`;

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        return this.httpClient.post(url, postData, headers);
    }

    /**
     * Obtenemos la calificaciones de un alumno usado filtros
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    getCalificationbyStudentUsingSubjetID(student_id, id_subject) {
        return this.getCalificationbyStudent(student_id, {
            filters: `&filters={"subject_id":${id_subject}}`,
        });
    }

    /**
     * Actualziar calificacion
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    updateCalification(calificationID, dataRequest) {
        let url = `/califications/${calificationID}`;

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };

        return this.httpClient.put(url, dataRequest);
    }

    /**
     * Crear calificacion
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    createCalification(studentId, subjectId, dataRequest) {
        let url = `/students/${studentId}/subjects/${subjectId}/califications`;

        return this.httpClient.post(url, dataRequest);
    }

    /**
     * Recuperar todas las materias de un alumno
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    getSubjectsByStudent(studentId) {
        let url = `/students/${studentId}/subjects`;

        return this.httpClient.get(url);
    }

    /**
     * Obtenemos una materia
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    getSubjectById(subjectId) {
        let url = `/subjects/${subjectId}?fields={"except":["link","created_at","updated_at"]}`;

        return this.httpClient.get(url);
    }

    /**
     * Crear reportes
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    storeReports(professorId, studentId, requestData) {
        let url = `/students/${studentId}/professors/${professorId}/reports`;

        return this.httpClient.post(url, requestData);
    }

    /**
     * Crear expediantes
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    storeFile(studentId, assessorId) {
        let url = `/students/${studentId}/assessors/${assessorId}/files`;

        return this.httpClient.post(url);
    }

    /**
     * Crear expediantes
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    getInactiveFilesByStudent(studentId) {
        let url = `students/${studentId}/files?filters={"status":0}`;

        return this.httpClient.get(url);
    }

    /**
     * Obtener expedientes por id
     * del alumno
     * @param {*} studentId Identificador del alumno
     */

    getFilesByStudent(studentId) {
        let url = `students/${studentId}/files`;

        return this.httpClient.get(url);
    }

    /**
     * Obtenes un expediante
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    getFileById(fileId) {
        let url = `/files/${fileId}?fields={"except":["link","created_at","updated_at"]}`;

        return this.httpClient.get(url);
    }

    /**
     * Obtener el emisor de una notificacion
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    getEmmiterByNoticeId(noticeId) {
        let url = `/notices/${noticeId}/emitters?fields={"except":["link","created_at","updated_at"]}`;

        return this.httpClient.get(url);
    }

    /**
     * Guardar una noticias
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    storeNotice(spokesmanID, requestData) {
        let url = `/spokesmen/${spokesmanID}/notices`;

        return this.httpClient.post(url, requestData);
    }

    /**
     * Obtenemos informacion general usuarios
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    getUsers(usersIds) {
        let url = `/users?filters={"user_id":[${usersIds}]}&fields={"except":["link", "push_token", "push_id"]}`;

        return this.httpClient.get(url);
    }

    /**
     * Recuperar todas los usuarios de una escuela
     *
     * @param {*} idSchool Identificador de la escuela
     *
     * @returns
     */
    getUsersFromSchoolByIds(schoolId, usersIds) {
        let url = `schools/${schoolId}/users?filters={"user_id":[${usersIds}]}&fields={"except":["link", "push_token", "push_id"]}`;

        return this.httpClient.get(url);
    }

    /**
     * Recuperar todas los usuarios de una escuela
     *
     * @param {*} idSchool Identificador de la escuela
     *
     * @returns
     */
    getUsersBySchool(schoolId) {
        let url = `schools/${schoolId}/users`;

        return this.httpClient.get(url);
    }

    /**
     * Actualizar cualquier usuario
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    updateUserData(endpoint, userId, data) {
        return this.httpClient.patch(`${endpoint}/${userId}`, data);
    }

    /**
     * Asignar un asesor a un grupo
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    assignAssessorToGroup(groupId, id_assesor) {
        let data = { assessor_id: id_assesor };

        return this.httpClient.post(`/groups/${groupId}/assessors`, data);
    }

    /**
     * Da de baja un alumno
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    deleteStudent(studentId, data) {
        return this.httpClient.delete(`/students/${studentId}`, {
            data,
        });
    }

    /**
     * Quitar grupo a un alumno
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    deleteStudentFromGroup(studentId, groupId) {
        return this.httpClient.delete(
            `/students/${studentId}/groups/${groupId}`
        );
    }

    /**
     * Quitar de un grupo a muchos alumnos
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */

    deleteMultiStudentFromGroup(groupId, payload) {
        const url = `/groups/${groupId}/students`;

        const data = { data: payload };

        return this.httpClient.delete(url, data);
    }

    // DELETE students/1/groups/12

    /**
     * Recuperar alumnos activos sin grupo
     *
     * @param {*} idSchool Identificador de la escuela
     *
     * @returns
     */
    getStudentsActivedWithoutGroup(idSchool, queryParameters) {
        let endpoint = `/schools/${idSchool}/students`;

        const queryEncoded = this.encodeQuery({
            filters: { status: 1, group_id: null },
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Recuperar alumnos activos
     *
     * @param {*} idSchool Identificador de la escuela
     *
     * @returns
     */
    getStudentsActived(idSchool) {
        let url = `/schools/${idSchool}/students?filters={"status":1}&fields={"except":["link","created_at","updated_at"]}`;

        return this.httpClient.get(url);
    }

    /**
     * Recuperar alumnos activos con padres
     *
     * @param {*} idSchool Identificador de la escuela
     *
     * @returns
     */
    getStudentsHasRelationships(idSchool) {
        return this.getStudentsActived(idSchool).then((response) => {
            let students = response.data.data;

            response.data.data = students.filter((s) => s.relationships > 0);

            return response;
        });
    }

    /**
     * Agregamos un grupo a un alumno
     *
     * @param {*} studentId Identificador del alumno
     * @param {*} groupId Identificador del alumno
     *
     * @returns
     */
    setStudentsToGroup(studentId, groupId) {
        let url = `/students/${studentId}/groups`;

        return this.httpClient.post(url, {
            group_id: groupId,
        });
    }

    /**
     * Cambiar un grupo a un alumno
     *
     * @param {*} studentId Identificador del alumno
     * @param {*} currentGroupId Identificador del grupo actual
     * @param {*} newGroupId Identificador del nuevo grupo
     *
     * @returns
     */
    changeStudentGroup(studentId, currentGroupId, newGroupId, data = null) {
        let url = `/students/${studentId}/groups/${currentGroupId}`;

        return this.httpClient.patch(url, {
            group_id: newGroupId,
            ...data,
        });
    }

    /**
     * Crear alumno y asignarlo a un grupo principal
     *
     * @param {*} idSchool Identificador de la escuela
     *
     * @returns
     */
    createAndSetStudentToGroup(schoolId, groupId, data) {
        let url = `/schools/${schoolId}/groups/${groupId}/students`;

        return this.httpClient.post(url, data);
    }

    /**
     * Recuperar todas las relaciones de una escuela
     *
     * @param {*} idSchool Identificador de la escuela
     *
     * @returns
     */
    getRelationshipsBySchool(schoolId, queryParameters = {}) {
        let endpoint = `/schools/${schoolId}/relationships`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Recuperar los parentescos de un alumno
     *
     * @param {*} idSchool Identificador de la escuela
     *
     * @returns
     */
    getRelationshipsByStudents(studentId) {
        let url = `/students/${studentId}/relationships`;

        return this.httpClient.get(url);
    }

    /**
     * Recuperar las relaciones de un usuario padre
     *
     * @param {*} userId Identificador del usuario
     *
     * @returns
     */
    getStudentsByParent(userId, schoolId) {
        let url = `parents/${userId}/students?filters={"school_id": ${schoolId}}`;

        return this.httpClient.get(url);
    }

    /**
     * Actualizar alumno
     *
     * @param {*} idUser
     * @param {*} data
     * @returns
     */
    updateStudent(studentId, data) {
        return this.httpClient.patch(`/students/${studentId}`, data);
    }

    /**
     * Crear alumnos
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} data Datos del usuario
     * @returns
     */
    storeStudentInSchool(schoolId, data) {
        return this.httpClient.post(`/schools/${schoolId}/students`, data);
    }

    /**
     * Recuperamos todos los padres de una escuela
     *
     * @param {*} idSchool
     * @returns
     */
    findParents(queryParameters = {}) {
        let endpoint = `/parents`;

        let parameters = {
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            'not-calculate-properties': true,
            ...queryParameters,
        };

        const parametersEncoded = this.encodeQuery(parameters);

        return this.httpClient.get(`${endpoint}?${parametersEncoded}`);
    }

    /**
     * Recuperamos todos los padres de una escuela
     *
     * @param {*} idSchool
     * @returns
     */
    findUsers(queryParameters = {}) {
        let endpoint = `/users`;

        let parameters = {
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            'not-calculate-properties': true,
            ...queryParameters,
        };

        const parametersEncoded = this.encodeQuery(parameters);

        return this.httpClient.get(`${endpoint}?${parametersEncoded}`);
    }

    /**
     * Recuperamos todos los usuarios
     *
     * @returns
     */
    findUsersCura(queryParameters = {}) {
        let endpoint = `/users`;
        let parameters = {
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            'not-calculate-properties': true,
            ...queryParameters,
        };
        const parametersEncoded = this.encodeQuery(parameters);
        return this.httpClient.get(`${endpoint}?${parametersEncoded}`);
    }

    /**
     * Recuperamos un padre por su identificador
     *
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     *
     * @returns
     */
    getParentById(parentID, queryParameters = {}) {
        let endpoint = `/parents/${parentID}`;

        let parameters = {
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            'not-calculate-properties': true,
            ...queryParameters,
        };

        const parametersEncoded = this.encodeQuery(parameters);

        return this.httpClient.get(`${endpoint}?${parametersEncoded}`);
    }

    /**
     * Recuperar un usuario por su identificador
     *
     * @param {*} idSchool Identificador de la escuela
     *
     * @returns
     */
    getUserById(userID, queryParameters = {}) {
        let endpoint = `/users/${userID}`;

        let parameters = {
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            'not-calculate-properties': true,
            ...queryParameters,
        };

        const parametersEncoded = this.encodeQuery(parameters);

        return this.httpClient.get(`${endpoint}?${parametersEncoded}`);
    }

    /**
     * Asociar padre con un hujo
     *
     * @param {*} idSchool
     * @returns
     */
    storeRelationship(stundetId, parentId, dataRequest) {
        let endpoint = `/students/${stundetId}/parents/${parentId}/relationships`;

        return this.httpClient.post(endpoint, dataRequest);
    }

    /**
     * Actualzia contrsseña, username, y el tipo de usuario de un usuario
     *
     * @param {*} idUser
     * @param {*} data
     * @returns
     */
    updateRelationshipById(idUser, data) {
        return this.httpClient.patch(`/relationships/${idUser}`, data);
    }

    /**
     * Actualzia contrsseña, username, y el tipo de usuario de un usuario
     *
     * @param {*} idUser
     * @param {*} data
     * @returns
     */
    updateParentById(idUser, data) {
        return this.httpClient.patch(`/parents/${idUser}`, data);
    }

    /**
     * Permite almacenar/actualiziar un conjunto de
     * calificaciones en un solo request
     *
     * @param postData
     * @returns
     */
    saveMutiCalifications(postData) {
        let endpoint = `/califications`;

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };

        return this.httpClient.post(endpoint, postData, { headers });
    }

    /**
     * Solicitar permiso al servidor
     *
     * @param postData
     * @returns
     */
    grantPermission(postData) {
        let endpoint = `/grant-permission`;

        return this.httpClient.post(endpoint, postData);
    }

    /**
     * Crear ciclo escolar
     *
     * @param postData
     * @returns
     */
    createSnaphot(schoolId, postData) {
        let endpoint = `/schools/${schoolId}/cycles`;

        return this.httpClient.post(endpoint, postData);
    }

    /**
     * Actualiza la justificación de faltas
     *
     * @param {*} idAbsence
     * @param {*} data
     * @returns
     */
    updateAbsence(data) {
        return this.httpClient.patch(`/assists`, data);
    }

    getCities(queryParameters = {}) {
        let url = `/cities`;

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ['created_at', 'updated_at'],
            },
            ...queryParameters,
        });

        return this.httpClient.get(`${url}?${queryEncoded}`);
    }

    getCitiesByState(stadeId) {
        let url = `/states/${stadeId}/cities`;

        return this.httpClient.get(url);
    }

    getStates() {
        let url = `/states?sort=title`;

        return this.httpClient.get(url);
    }

    getMunicipalities() {
        let url = `/municipalities?sort=title`;

        return this.httpClient.get(url);
    }

    getMunicipalitysByStateId(id) {
        let url = `/states/${id}/municipalities?sort=title`;

        return this.httpClient.get(url);
    }

    getCityByMunicipalityId(id) {
        let url = `municipalities/${id}/cities?sort=title`;

        return this.httpClient.get(url);
    }

    getMunicipalityByCityId(id) {
        let url = `/cities/${id}/municipalities?sort=title`;

        return this.httpClient.get(url);
    }

    getStateByMunicipalityID(id) {
        let url = `/municipalities/${id}/states?sort=title`;

        return this.httpClient.get(url);
    }

    getCityById(id) {
        let url = `/cities/${id}?sort=title`;

        return this.httpClient.get(url);
    }

    /**
     * Eliminar una relacion
     *
     * @param numeric idRelationship
     * @returns Promise
     */
    removeRelationship(idRelationship) {
        return this.httpClient.delete(`relationships/${idRelationship}`);
    }

    getImportsBySchoolId(schoolId) {
        return this.httpClient.get(`/schools/${schoolId}/imports`);
    }

    getGroupsScoresImportsBySchoolId(schoolId) {
        return this.httpClient.get(
            `/schools/${schoolId}/imports?filters={"import_type": "group-scores"}`
        );
    }

    saveImportFile(userID, data) {
        return this.httpClient.post(`/users/${userID}/imports`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    updateImportFile(importId, data) {
        return this.httpClient.post(`/imports/${importId}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    getNotesByFileId(fileId) {
        return this.httpClient.get(`/files/${fileId}/notes`);
    }

    getFilesByGroup(groupId) {
        return this.httpClient.get(`groups/${groupId}/files`);
    }

    getStudentsBySchool(schoolId, ids) {
        return this.httpClient.get(
            `/schools/${schoolId}/students?filters={"student_id":[${ids}]}&sort=-created_at`
        );
    }

    /////////////////////////////// NEW DIRECTORS /////////////////////////////////////////////

    /**
     * Recuperar informacion para el director UI/V1/Director
     * @param numeric idSchool
     * @param {*} data
     * @returns Promise
     */
    getDirectorInformation({ school_id, cycle_id, data }) {
        const filters = {
            turno: data.turno ?? '',
            limite: 5,
        };

        const queryParameters = this.encodeQuery({
            page: data.page,
            component: data.type,
            id: data.idKey,
            school_id: school_id,
            ...(cycle_id !== null &&
                cycle_id !== '' &&
                cycle_id !== -1 && { cycle_id: cycle_id }),
            ...(data.type === 'top' && { filters: JSON.stringify(filters) }),
        });

        const url = `/directors?${queryParameters}`;

        return this.httpClient.getUI(url);
    }

    /////////////////////////////// NEW PAYMENTS MODULE /////////////////////////////////////////////

    /**
     * Recuperar las kpis de  UI/V1/PAYMENTS
     * @param numeric idSchool
     * @param {*} data
     * @returns Promise
     */
    getPaymentsInformation({ school_id, data }) {
        const queryParameters = this.encodeQuery({
            page: data.page,
            component: data.type,
            id: data.idKey,
            school_id: school_id,
        });
        const url = `/payments?${queryParameters}`;

        return this.httpClient.getUI(url);
    }

    /**
     * Recuperar las kpis de  UI/V1/PAYMENTS
     * @param numeric idSchool
     * @param {*} data
     * @returns Promise
     */
    getTotalGains({ school_id, data }) {
        const queryParameters = this.encodeQuery({
            page: data.page,
            component: data.type,
            id: data.idKey,
            school_id: school_id,
        });
        const url = `/payments?${queryParameters}`;

        return this.httpClient.getUI(url);
    }

    /**
     * obtener las parcialidades de un estudiante
     *
     * @param {*} studentdId
     * @return {*}
     * @memberof EficazAPI
     */
    getPartialitiesByStudent(studentdId) {
        return this.httpClient.getV2(`/students/${studentdId}/partialities`);
    }

    /**
     * obtener las parcialidades de la escuela
     *
     * @param {*} schoolId
     * @return {*}
     * @memberof EficazAPI
     */
    getPartialitiesBySchool(schoolId) {
        return this.httpClient.getV2(`schools/${schoolId}/partialities`);
    }

    /**
     * obtener las parcialidades de un concepto
     *
     * @param {*} studentdId
     * @return {*}
     * @memberof EficazAPI
     */
    getPartialitiesByConcept(conceptId) {
        return this.httpClient.getV2(`/concepts/${conceptId}/partialities`);
    }

    /**
     **Obtener los estudiantes con conceptos
     *
     * @param {*} studentdId
     * @return {*}
     * @memberof EficazAPI
     */
    getStudentsPaymentsConcepts(studentdId) {
        return this.httpClient.getV2(`/schools/${studentdId}/concepts`);
    }

    // pagos
    /**
     *Obtener todos los pagos
     *
     * @param {*}
     * @return {*}
     * @memberof EficazAPI
     */
    getAllPayments() {
        return this.httpClient.getV2(`/payments`);
    }

    setPreRegistration(data) {
        //http://cura-ws.me/api/v2/preincriptions

        return this.httpClient.postV2(`/preinscriptions`, data);
    }

    getExportablePreinscription(
        reportName,
        typeFormat,
        queryReports,
        cycle = null
    ) {
        let url = `/preinscriptions/exports?report=${reportName}&format=${typeFormat}&data=${JSON.stringify(
            queryReports
        )}`;

        if (cycle) {
            url += `&cycle=${cycle}`;
        }

        return this.httpClient.getV2(encodeURI(url), { responseType: 'blob' });
    }

    setPostulations(data, cellPhoneContact) {
        return this.httpClient.postV2(
            `/preinscriptions/postulations?cellphone=${cellPhoneContact}`,
            data
        );
    }

    /**
     *Obtener todos los convenios de una escuela
     *
     * @param {*} schoolId
     * @return {*}
     * @memberof EficazAPI
     */
    getPaymentsBySchool(schoolId) {
        return this.httpClient.getV2(`/schools/${schoolId}/payments`);
    }

    /**
     *Obtener un pago
     *
     * @param {*} payment_id
     * @return {*}
     * @memberof EficazAPI
     */
    getOnePayment(payment_id) {
        return this.httpClient.getV2(`/payments/${payment_id}`);
    }

    /**
     *Obtener el concepto que tiene ese pago
     *
     * @param {*} payment_id
     * @return {*}
     * @memberof EficazAPI
     */
    getConceptByPayment(payment_id) {
        return this.httpClient.getV2(`/payments/${payment_id}/concepts`);
    }

    /**
     *Obtener el usuario que tiene un pago
     *
     * @param {*} payment_id
     * @return {*}
     * @memberof EficazAPI
     */
    getUserByPayment(payment_id) {
        return this.httpClient.getV2(`/payments/${payment_id}/users`);
    }
    /**
     *Obtener pagos que tiene un usuario
     *
     * @param {*} userId
     * @return {*}
     * @memberof EficazAPI
     */
    getUserPayments(userId) {
        return this.httpClient.getV2(`/users/${userId}/payments`);
    }
    /**
     *Obtener el pago que corresponda ese concepto
     *
     * @param {*} concept_id
     * @return {*}
     * @memberof EficazAPI
     */
    getPaymentByConcept(concept_id) {
        return this.httpClient.getV2(`/concepts/${concept_id}/payments`);
    }

    // CONVENIOS

    /**
     *Obtener todos los convenios
     *
     * @param {*}
     * @return {*}
     * @memberof EficazAPI
     */
    getAllAgreements() {
        return this.httpClient.getV2(`/agreements`);
    }

    /**
     *Obtener todos los convenios de una escuela
     *
     * @param {*} schoolId
     * @return {*}
     * @memberof EficazAPI
     */
    getAgreementBySchool(schoolId) {
        return this.httpClient.getV2(`/schools/${schoolId}/agreements`);
    }
    /**
     *Obtener un convenio
     *
     * @param {*} agreement_id
     * @return {*}
     * @memberof EficazAPI
     */
    getOneAgreement(agreement_id) {
        return this.httpClient.getV2(`/agreements/${agreement_id}`);
    }

    /**
     *Obtener el concepto con el ID del convenio
     *
     * @param {*} agreement_id
     * @return {*}
     * @memberof EficazAPI
     */
    getConceptByAgreementId(agreement_id) {
        return this.httpClient.getV2(`/agreements/${agreement_id}/concepts`);
    }

    /**
     *Obtener el usuario según el ID del convenio
     *
     * @param {*} agreement_id
     * @return {*}
     * @memberof EficazAPI
     */
    getUserByAgreementId(agreement_id) {
        return this.httpClient.getV2(`/agreements/${agreement_id}/users`);
    }

    /**
     *Obtener convenios que tiene un usuario
     *
     * @param {*} userId
     * @return {*}
     * @memberof EficazAPI
     */
    getUserAgreements(userId) {
        return this.httpClient.getV2(`/users/${userId}/agreements`);
    }
    /**
     *Obtener el convenio que corresponda ese concepto
     *
     * @param {*} concept_id
     * @return {*}
     * @memberof EficazAPI
     */
    getAgreementByConcept(concept_id) {
        return this.httpClient.getV2(`/concepts/${concept_id}/agreements`);
    }

    // CONCEPTOS

    /**
     *Obtener todos los convenios de una escuela
     *
     * @param {*} schoolId
     * @return {*}
     * @memberof EficazAPI
     */
    getConceptsBySchool(schoolId) {
        return this.httpClient.getV2(`/schools/${schoolId}/concepts`);
    }

    /**
     *Obtener todos los conceptos
     *
     * @param {*}
     * @return {*}
     * @memberof EficazAPI
     */
    getAllConcepts() {
        return this.httpClient.getV2(`/concepts`);
    }
    /**
     *Obtener un concepto
     *
     * @param {*} concept_id
     * @return {*}
     * @memberof EficazAPI
     */
    getOneConcepts(concept_id) {
        return this.httpClient.getV2(`/concepts/${concept_id}`);
    }

    /**-----
     *Obtener catalogo de conceptos que corresponda ese concepto
     *
     * @param {*} concept_id
     * @return {*}
     * @memberof EficazAPI
     */
    getConceptsCatalogByConcept(concept_id) {
        return this.httpClient.getV2(`/concepts/${concept_id}/catalogconcepts`);
    }

    /**
     *Obtener escuelas que tengan ese catalogo de conceptos
     *
     * @param {*} schoolId
     * @return {*}
     * @memberof EficazAPI
     */
    getSchoolsByConceptsCatalog(schoolId) {
        return this.httpClient.getV2(`/schools/${schoolId}/catalogconcepts`);
    }

    /**
     *Obtener todos los conceptos del catalogo
     *
     * @param {*}
     * @return {*}
     * @memberof EficazAPI
     */
    getAllCatalogConcepts() {
        return this.httpClient.getV2(`/catalogconcepts`);
    }

    /**
     *Obtener un concepto del catalogo
     *
     * @param {*} catalogconcepts_id
     * @return {*}
     * @memberof EficazAPI
     */
    getOneCatalogConcept(catalogconcepts_id) {
        return this.httpClient.getV2(`/catalogconcepts/${catalogconcepts_id}`);
    }

    /**-----
     *Obtener conceptos que contenga el id_catalogconcept
     *
     * @param {*} catalogconcepts_id
     * @return {*}
     * @memberof EficazAPI
     */
    getConceptByIdCatalogConcept(catalogconcepts_id) {
        return this.httpClient.getV2(
            `/catalogconcepts/${catalogconcepts_id}/concepts`
        );
    }
    /**-----
     *Obtener escuelas que contenga el id_catalogconcept
     *
     * @param {*} catalogconcepts_id
     * @return {*}
     * @memberof EficazAPI
     */
    getSchoolsByIdCatalogConcept(catalogconcepts_id) {
        return this.httpClient.getV2(
            `/catalogconcepts/${catalogconcepts_id}/schools`
        );
    }

    /**
     *Obtener el alumno que tenga un concepto
     *
     * @param {*} concept_id
     * @return {*}
     * @memberof EficazAPI
     */
    getStudentByConcept(concept_id) {
        return this.httpClient.getV2(`/concepts/${concept_id}/students`);
    }

    /**
     *Obtener los conceptos asignados de un alumno
     *
     * @param {*} students_id
     * @return {*}
     * @memberof EficazAPI
     */
    getAssignedConceptsToStudent(students_id) {
        return this.httpClient.getV2(`/students/${students_id}/concepts`);
    }

    //descuentos y recargos

    // CATALOGO DESCUENTOS
    /**
     *Obtener escuelas que tengan ese catalogo de descuentos
     *
     * @param {*} schoolId
     * @return {*}
     * @memberof EficazAPI
     */
    getSchoolsByDiscountsCatalog(schoolId) {
        return this.httpClient.getV2(`/schools/${schoolId}/catalogdiscounts`);
    }

    /**
     *Obtener todos los descuentos del catalogo
     *
     * @param {*}
     * @return {*}
     * @memberof EficazAPI
     */
    getAllCatalogDiscounts() {
        return this.httpClient.getV2(`/catalogdiscounts`);
    }
    /**
     *Obtener un descuento del catalogo
     *
     * @param {*} catalogdiscounts_id
     * @return {*}
     * @memberof EficazAPI
     */
    getOneCatalogDiscounts(catalogdiscounts_id) {
        return this.httpClient.getV2(
            `/catalogdiscounts/${catalogdiscounts_id}`
        );
    }

    /**-----
     *Obtener los descuentos del catalogo de descuentos segun el ID
     *
     * @param {*} catalogdiscounts_id
     * @return {*}
     * @memberof EficazAPI
     */
    getCatalogDiscountsById(catalogdiscounts_id) {
        return this.httpClient.getV2(
            `/catalogdiscounts/${catalogdiscounts_id}/discounts`
        );
    }

    // DESCUENTOS

    /**
     *Obtener todos los descuentos
     *
     * @param {*}
     * @return {*}
     * @memberof EficazAPI
     */
    getAllDiscounts() {
        return this.httpClient.getV2(`/discounts`);
    }

    /**
     *Obtener todos los convenios de una escuela
     *
     * @param {*} schoolId
     * @return {*}
     * @memberof EficazAPI
     */
    getDiscountsBySchool(schoolId) {
        return this.httpClient.getV2(`/schools/${schoolId}/discounts`);
    }

    /**
     *Obtener un descuento
     *
     * @param {*} discount_id
     * @return {*}
     * @memberof EficazAPI
     */
    getOneDiscount(discount_id) {
        return this.httpClient.getV2(`/discounts/${discount_id}`);
    }

    /**
     *Obtener descuento que corresponda ese concepto
     *
     * @param {*} concept_id
     * @return {*}
     * @memberof EficazAPI
     */
    getDiscountByConcept(concept_id) {
        return this.httpClient.getV2(`/concepts/${concept_id}/discounts`);
    }

    /**-----
     *Obtener el catalogo de descuentos que tiene un descuento
     *
     * @param {*} discount_id
     * @return {*}
     * @memberof EficazAPI
     */
    getDiscountsCatalogByDiscount(discount_id) {
        return this.httpClient.getV2(
            `/discounts/${discount_id}/catalogdiscount`
        );
    }

    /**
     *Obtener el concepto que tiene ese descuento
     *
     * @param {*} discount_id
     * @return {*}
     * @memberof EficazAPI
     */
    getConceptByDiscount(discount_id) {
        return this.httpClient.getV2(`/discounts/${discount_id}/concepts`);
    }

    // CATALOGO RECARGOS

    /**
     *Obtener todos los recargos del catalogo
     *
     * @param {*}
     * @return {*}
     * @memberof EficazAPI
     */
    getAllSurchargesCatalog() {
        return this.httpClient.getV2(`/catalogsurcharges`);
    }

    /**
     *Obtener un recargo del catalogo
     *
     * @param {*} catalogsurcharges_id
     * @return {*}
     * @memberof EficazAPI
     */
    getOneCatalogSurcharges(catalogsurcharges_id) {
        return this.httpClient.getV2(
            `/catalogsurcharges/${catalogsurcharges_id}`
        );
    }
    /**
     *Obtener escuelas que tengan ese catalogo de recargos
     *
     * @param {*} schoolId
     * @return {*}
     * @memberof EficazAPI
     */
    getSchoolsBySurchargesCatalog(schoolId) {
        return this.httpClient.getV2(`/schools/${schoolId}/catalogsurcharges`);
    }

    /**-----
     *Obtener recargos del catalogo de recargos segun el ID
     *
     * @param {*}catalogsurcharges_id
     * @return {*}
     * @memberof EficazAPI
     */
    getSurchargesFromSurchargesCatalogById(catalogsurcharges_id) {
        return this.httpClient.getV2(
            `/catalogsurcharges/${catalogsurcharges_id}/surcharges`
        );
    }
    /**-----
     *Obtener la escuela correspondiente al catalogo de recargos segun el ID
     *
     * @param {*} catalogsurcharges_id
     * @return {*}
     * @memberof EficazAPI
     */
    getSchoolByCatalogSurchargesId(catalogsurcharges_id) {
        return this.httpClient.getV2(
            `/catalogsurcharges/${catalogsurcharges_id}/schools`
        );
    }

    // RECARGOS
    /**
     *Obtener todos los recargos
     *
     * @param {*}
     * @return {*}
     * @memberof EficazAPI
     */
    getAllSurcharges() {
        return this.httpClient.getV2(`/surcharges`);
    }

    /**
     *Obtener todos los convenios de una escuela
     *
     * @param {*} schoolId
     * @return {*}
     * @memberof EficazAPI
     */
    getSurchargesBySchool(schoolId) {
        return this.httpClient.getV2(`/schools/${schoolId}/surcharges`);
    }

    /**
     *Obtener un recargo
     *
     * @param {*} surcharge_id
     * @return {*}
     * @memberof EficazAPI
     */
    getOneSurcharge(surcharge_id) {
        return this.httpClient.getV2(`/surcharges/${surcharge_id}`);
    }

    /**
     *Obtener el recargo que corresponda ese concepto
     *
     * @param {*} concept_id
     * @return {*}
     * @memberof EficazAPI
     */
    getSurchargeByConcept(concept_id) {
        return this.httpClient.getV2(`/concepts/${concept_id}/surcharges`);
    }

    /**-----
     *Obtener el catalogo de recargos que tiene un recargo
     *
     * @param {*} concept_id
     * @return {*}
     * @memberof EficazAPI
     */
    getSurchargeCatalogBySurcharge(concept_id) {
        return this.httpClient.getV2(
            `/surcharges/${concept_id}/catalogsurcharges`
        );
    }

    /**
     *Obtener el concepto que tiene ese recargo
     *
     * @param {*} concept_id
     * @return {*}
     * @memberof EficazAPI
     */
    getConceptSurcharge(concept_id) {
        return this.httpClient.getV2(`/surcharges/${concept_id}/concepts`);
    }

    // DELETE

    /**
     * Eliminar del catalogo de conceptos
     *
     * @param numeric catalogconcepts_id
     * @returns Promise
     */
    deleteConceptCatalog(catalogconcepts_id) {
        return this.httpClient.deleteV2(
            `/catalogconcepts/${catalogconcepts_id}`
        );
    }

    /**
     * Eliminar un concepto
     *
     * @param {*} data
     * @returns Promise
     */
    deleteConcept(data) {
        return this.httpClient.deleteV2(`/concepts`, data);
    }

    /**
     * Eliminar del catalogo de descuentos
     *
     * @param numeric discount_catalog_id
     * @returns Promise
     */
    deleteDiscountCatalog(discount_catalog_id) {
        return this.httpClient.deleteV2(
            `/catalogdiscounts/${discount_catalog_id}`
        );
    }
    /**
     * Eliminar un descuento
     *
     * @param numeric discount_id
     * @returns Promise
     */
    deleteDiscount(data) {
        return this.httpClient.deleteV2(`/discounts`, data);
    }

    /**
     * Eliminar del catalogo de recargos
     *
     * @param numeric catalogsurcharges_id
     * @returns Promise
     */
    deleteSurchargeCatalog(catalogsurcharges_id) {
        return this.httpClient.deleteV2(
            `/catalogsurcharges/${catalogsurcharges_id}`
        );
    }
    /**
     * Eliminar un recargo
     *
     * @param numeric surcharge_id
     * @returns Promise
     */
    deleteSurcharge(data) {
        return this.httpClient.deleteV2(`/surcharges`, data);
    }
    /**
     * Eliminar un convenio
     *
     * @param numeric surcharge_id
     * @returns Promises
     */
    deleteAgreement(agreement_id) {
        return this.httpClient.deleteV2(`agreements/${agreement_id}`);
    }

    /**
     * Eliminar un pago
     *
     * @param numeric payment_id
     * @returns Promise
     */
    deletePayment(payment_id) {
        return this.httpClient.deleteV2(`/payments/${payment_id}`);
    }

    // POST
    /**
     * Agrega un nuevo concepto
     *
     * @param numeric school_id
     * @param {*} data
     * @returns Promise
     */
    addConceptCatalog(school_id, data) {
        return this.httpClient.postV2(
            `/schools/${school_id}/catalogconcepts`,
            data
        );
    }

    /**
     * Agrega un nuevo concepto
     *
     * @param numeric student_id, catalogconcepts_id
     * @param {*} data
     * @returns Promise
     */
    addConcept(data) {
        return this.httpClient.postV2(`/concepts`, data);
    }

    /**
     * Agrega un nuevo catalogo de descuentos
     *
     * @param numeric school_id
     * @param {*} data
     * @returns Promise
     */
    addDiscountCatalog(school_id, data) {
        return this.httpClient.postV2(
            `/schools/${school_id}/catalogdiscounts`,
            data
        );
    }

    /**
     * Agrega un nuevo descuento
     *
     * @param numeric concept_id
     * @param {*} data
     * @returns Promise
     */
    addDiscount(data) {
        return this.httpClient.postV2(`/discounts`, data);
    }

    /**
     * Agrega un nuevo catalogo de recargos
     *
     * @param numeric school_id
     * @param {*} data
     * @returns Promise
     */
    addSurchargesCatalog(school_id, data) {
        return this.httpClient.postV2(
            `/schools/${school_id}/catalogsurcharges`,
            data
        );
    }

    /**
     * Agrega un nuevo recargo
     *
     * @param numeric concept_id
     * @param {*} data
     * @returns Promise
     */
    addSurcharges(data) {
        return this.httpClient.postV2(`/surcharges`, data);
    }

    /**
     * Agrega un nuevo convenio
     *
     * @param numeric concept_id, user_id
     * @param {*} data
     * @returns Promise
     */
    addAgreement(concept_id, user_id, data) {
        return this.httpClient.postV2(
            `/concepts/${concept_id}/users/${user_id}/agreements`,
            data
        );
    }

    /**
     * Agrega un Pago
     *
     * @param numeric concept_id, user_id
     * @param {*} data
     * @returns Promise
     */
    addPayment(concept_id, user_id, data) {
        return this.httpClient.postV2(
            `/concepts/${concept_id}/users/${user_id}/payments`,
            data
        );
    }

    // PATCH
    /**
     * Actualizar un concepto del catalogo
     *
     * @param numeric catalogconcepts_id
     * @param {*} data
     * @returns Promise
     */
    updateConceptsCatalog(catalogconcepts_id, data) {
        return this.httpClient.putV2(
            `/catalogconcepts/${catalogconcepts_id}`,
            data
        );
    }

    /**
     * Actualizar un concepto
     *
     * @param numeric concept_id
     * @param {*} data
     * @returns Promise
     */
    updateConcept(data) {
        return this.httpClient.putV2(`/concepts`, data);
    }

    /**
     * Actualizar un descuento del catalogo
     *
     * @param numeric catalogdiscounts_id
     * @param {*} data
     * @returns Promise
     */
    updateDiscountCatalog(catalogdiscounts_id, data) {
        return this.httpClient.putV2(
            `/catalogdiscounts/${catalogdiscounts_id}`,
            data
        );
    }
    /**
     * Actualizar un descuento
     *
     * @param numeric discount_id
     * @param {*} data
     * @returns Promise
     */
    updateDiscount(data) {
        return this.httpClient.putV2(`/discounts`, data);
    }

    /**
     *  Actualizar un recargo del catalogo
     *
     * @param numeric catalogsurcharges_id
     * @param {*} data
     * @returns Promise
     */
    updateCatalogSurcharges(catalogsurcharges_id, data) {
        return this.httpClient.putV2(
            `/catalogsurcharges/${catalogsurcharges_id}`,
            data
        );
    }

    /**
     *  Actualizar un recargo
     *
     * @param numeric surcharge_id
     * @param {*} data
     * @returns Promise
     */
    updateSurcharge(data) {
        return this.httpClient.putV2(`/surcharges`, data);
    }

    /**
     *  Actualizar un convenio
     *
     * @param numeric agreement_id
     * @param {*} data
     * @returns Promise
     */
    updateAgreement(agreement_id, data) {
        return this.httpClient.putV2(`/agreements/${agreement_id}`, data);
    }
    /**
     *  Actualizar un pago
     *
     * @param numeric payment_id
     * @param {*} data
     * @returns Promise
     */
    updatePayment(payment_id, data) {
        return this.httpClient.putV2(`/payments/${payment_id}`, data);
    }

    getHistoricalRegularizations(values, queryParameters = {}) {
        let endpoint = `${values.cycle}/schools/regularizations`;

        const queryEncoded = this.encodeQuery({
            ...queryParameters,
            filters: {
                turn: values.turn,
                level: values.level,
                grade: values.grade,
                group: values.group,
            },
        });
        return this.httpClient.getHistorical(`${endpoint}?${queryEncoded}`);
    }

    postHistoricalRegularizations(cycle, data) {
        return this.httpClient.postHistorical(`${cycle}/regularizations`, data);
    }

    getTracesCatalogs() {
        let url = `/catalogtraces`;

        return this.httpClient.get(url);
    }

    getOutsideBootStart() {
        let url = `/bootstrap`;

        return this.httpClient.get(url);
    }

    //////////////////////////////////////////////////
    /////////      PREINSCRIPCIONES           ////////
    //////////////////////////////////////////////////

    /**
     * Upload files in prescriptions
     * @param {*} data
     * @returns Promise
     */
    UploadPrescriptionFile(data, options) {
        return this.httpClient.postV2(`/uploads`, data, options);
    }

    /**
     * Delete files in prescriptions
     * @param {*} data
     * @returns Promise
     */
    DeletePrescriptionFile(data) {
        return this.httpClient.deleteV2(`preinscriptions/uploads/delete`, {
            data: data,
        });
    }

    /**
     * Get pending preinscriptions
     * @param {*} data
     * @returns Promise
     */
    getPendingPrescriptionsById(payload, options) {
        return this.httpClient.getV2(`/followup?${payload}`, options);
    }

    getSearchCuraStudent(idStudent, identifier) {
        return this.httpClient.getV2(
            `/preinscriptions/students?student_id=${idStudent}&query=${identifier}`
        );
    }

    /**
     * Get pending postulations
     * @param {*} data
     * @returns Promise
     */
    getPendingPostulationsById(id, payload, options) {
        return this.httpClient.getV2(
            `/preinscriptions/${id}/postulations?${payload}`,
            options
        );
    }

    findPostulationSchools(queryParameters = {}) {
        let endpoint = `/preinscriptions/schools`;
        let parameters = {
            fields: {
                except: ['link', 'created_at', 'updated_at'],
            },
            'not-calculate-properties': true,
            ...queryParameters,
        };
        const parametersEncoded = this.encodeQuery(parameters);
        return this.httpClient.getV2(`${endpoint}?${parametersEncoded}`);
    }

    getSchoolsByPostulations(id, payload, options) {
        return this.httpClient.getV2(
            `/postulations/${id}/schools?${payload}`,
            options
        );
    }

    getPostulationsBySchool(schoolId) {
        return this.httpClient.getV2(`/schools/${schoolId}/postulations`);
    }

    getPostulationIncludes(postulationId) {
        return this.httpClient.getV2(
            `/postulations/${postulationId}?include=preinscription,documents`
        );
    }

    updateDocumentPreinscription(documentId, uuid, data) {
        return this.httpClient.postV2(
            `/uploads/${documentId}?id_preinscripcion=${uuid}`,
            data
        );
    }

    uploadRemainDocuments(preinscriptionId, data) {
        return this.httpClient.postV2(
            `/preinscriptions/${preinscriptionId}/uploads`,
            data
        );
    }

    getDocumentsByPreinscriptionId(preinscriptionId, cellphone) {
        return this.httpClient.getV2(
            `/preinscriptions/${preinscriptionId}/uploads?cellphone=${cellphone}`
        );
    }

    updatePostulation(postulationId, data) {
        return this.httpClient.putV2(`/postulations/${postulationId}`, data);
    }

    updateRejectedPostulation(data) {
        return this.httpClient.putV2(`/preinscriptions/postulations/`, data);
    }
}

export default EficazAPI;
