import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Connection from '../../service/Connection';
import Feedback from '../../service/Feedback';
import { getExpireIn } from '../../libs/utils';
import { upsertManyPostulations } from '../../store/slices/entities/postulations';
import { upsertManyPreinscriptions } from '../../store/slices/entities/preinscriptions';
import { upsertManyDocumentsPreinscriptions } from '../../store/slices/entities/documents_preinscriptions';
import { addItemsPostulations } from './itemsSlice';

const emptyState = {
    expireIn: null,
    ferchingAt: null,
    status: 'idle',
    didInvalidate: true,
    feedback: {
        title: null,
        message: null,
        payload: null,
    },
};

export const fetchSlice = createSlice({
    name: 'fetch',
    initialState: emptyState,
    reducers: {
        indvalidate: (state) => {
            state.didInvalidate = true;
        },
    },

    extraReducers: (builder) => {
        builder.addCase('app/clear', () => {
            return emptyState;
        });

        builder.addCase(loadUI.fulfilled, (state, action) => {
            state.expireIn = getExpireIn('PROFESSORS_HOME_EXPIRE_TIME');
            state.ferchingAt = Date.now();
            state.didInvalidate = false;
            state.status = 'fulfilled';
        });

        builder.addCase(loadUI.pending, (state, action) => {
            state.status = 'pending';
        });

        builder.addCase(loadUI.rejected, (state, action) => {
            state.status = 'rejected';
        });
    },
});

export const { indvalidate } = fetchSlice.actions;

export default fetchSlice.reducer;

export const selectPreinscriptionsFetch = (state) =>
    state.preinscriptionsUI.fetch;

export const selectFetchingStatus = (state) =>
    selectPreinscriptionsFetch(state).status;
/**Thunk carga de todas postulaciones de la escuela */

export const loadUI = createAsyncThunk(
    'preinscriptions/postulations/fetch',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const allPostulations = await Connection.getPostulationsBySchool(
                schoolId
            ).then((res) => res.data.data);

            let postulationsFullData = [];
            let postulationPreinscriptions = [];
            let postulationDocuments = [];
            for (let postulation of allPostulations) {
                let postulationData = await Connection.getPostulationIncludes(
                    postulation.postulation_id
                ).then((res) => res.data.data);
                postulationPreinscriptions = postulationPreinscriptions.concat(
                    postulationData.preinscription
                );
                postulationDocuments = postulationDocuments.concat(
                    postulationData.documents
                );
                postulationsFullData =
                    postulationsFullData.concat(postulationData);
            }

            thunkAPI.dispatch(addItemsPostulations(postulationsFullData));
            thunkAPI.dispatch(upsertManyPostulations(allPostulations));
            thunkAPI.dispatch(
                upsertManyPreinscriptions(postulationPreinscriptions)
            );
            thunkAPI.dispatch(
                upsertManyDocumentsPreinscriptions(postulationDocuments)
            );
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectPreinscriptionsFetch(
                getState()
            );

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
