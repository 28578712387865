import { Stack, Typography } from '@mui/material';
import React from 'react';

export const DocumentViewer = ({ url }) => {
    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                height: '100%',
                width: '100%',
            }}>
            <Typography
                variant="h6"
                component="div"
                sx={{
                    fontWeight: 'bold',
                    color: '#7F8185',
                }}>
                {url
                    .split('/')
                    .pop()
                    .split('_')
                    .slice(1)
                    .join(' ')
                    .replace('.pdf', '')
                    .split(' ')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}
            </Typography>
            <iframe
                style={{
                    width: '100%',
                    height: 'calc(100vh - 200px)',
                    border: 'none',
                }}
                src={url}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </Stack>
    );
};
