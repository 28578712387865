import React, { useState, useMemo, useEffect } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Typography,
    Autocomplete,
    TextField,
    InputAdornment,
    MenuItem,
    Select,
    Grid,
    CardHeader,
} from '@mui/material';
import { Send } from '@material-ui/icons';
import Connection from '../../../../../../service/Connection';
import { useFeedback } from '../../../../../../hooks';
import Feedback from '../../../../../../service/Feedback';
import Controls from '../../../../../../components/ReusableComponents/controls';
import { debounce } from 'lodash';
import { getTurnLevel } from '../../../../../../libs/utils';
import { Map, Marker } from 'pigeon-maps';
import BlueSchoolIcon from '../../../../../assets/iconos/Escuela_Azul.svg';
import GreenSchoolIcon from '../../../../../assets/iconos/Escuela_Verde.svg';

const DocumentNewPrescription = ({
    PostulationsRejected,
    PostulationsPending,
    PendingPostulations,
    setOpenModal,
    cellphoneContact,
    prescriptionsLevel,
    preinscriptionLevel,
    cellphonePostulations,
    prescriptions,
}) => {
    const feedbackApp = useFeedback();
    let FeedbackService = new Feedback();

    const [schoolCards, setSchoolCards] = useState(
        PostulationsRejected.filter(
            (postulation) => postulation.status === 0
        ).map((card) => ({
            ...card,
            preinscription_id: card.preinscription_id,
            options: [],
            loading: false,
            inputValue: '',
        }))
    );

    const [rejectedAndPendingSchools, setRejectedAndPendingSchools] = useState(
        []
    );

    useEffect(() => {
        const rejectedSchools = PostulationsRejected.filter(
            (postulation) => postulation.status === 0
        ).map((postulation) => postulation.school_id);

        const pendingSchools = PostulationsPending.filter(
            (postulation) => postulation.status === 1
        ).map((postulation) => postulation.school_id);

        setRejectedAndPendingSchools([...rejectedSchools, ...pendingSchools]);
    }, [PostulationsRejected, prescriptions]);

    const isValidPostulations =
        schoolCards.length >= 1 && schoolCards.length <= 3;

    const isSaveDisabled =
        schoolCards.some(
            (card) => !card.school || Object.keys(card.school).length === 0
        ) || !isValidPostulations;

    const selectedSchools = schoolCards
        .filter((card) => card.school && card.school.school_id)
        .map((card) => card.school.school_id);

    const fetchThrottledAndDebounced = useMemo(
        () =>
            debounce((search, index) => {
                if (search.length >= 3) {
                    const levelToUse =
                        prescriptionsLevel || preinscriptionLevel;

                    Connection.findPostulationSchools({
                        name: search,
                        level: levelToUse,
                    })
                        .then((res) => {
                            setSchoolCards((prevCards) => {
                                const updatedCards = [...prevCards];
                                updatedCards[index] = {
                                    ...updatedCards[index],
                                    options: res.data.data || [],
                                    loading: false,
                                };
                                return updatedCards;
                            });
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                } else {
                    setSchoolCards((prevCards) => {
                        const updatedCards = [...prevCards];
                        updatedCards[index] = {
                            ...updatedCards[index],
                            options: [],
                            loading: false,
                        };
                        return updatedCards;
                    });
                }
            }, 1000),
        [prescriptionsLevel, preinscriptionLevel]
    );

    const handleInputChange = (index, event, newValue) => {
        if (event?.type !== 'change') return;

        setSchoolCards((prevCards) => {
            const updatedCards = [...prevCards];
            updatedCards[index] = {
                ...updatedCards[index],
                inputValue: newValue,
                loading: true,
            };
            return updatedCards;
        });

        fetchThrottledAndDebounced(newValue, index);
    };

    const handleSelectionChange = (index, newValue) => {
        setSchoolCards((prevCards) => {
            const updatedCards = [...prevCards];
            updatedCards[index] = {
                ...updatedCards[index],
                school: newValue || {},
                inputValue: newValue ? newValue.name : '',
                loading: false,
            };
            return updatedCards;
        });
    };

    const handleSiblingsChange = (index, value) => {
        const updatedCards = [...schoolCards];
        updatedCards[index].siblings_number = value;
        setSchoolCards(updatedCards);
    };

    let savePostulations = async () => {
        try {
            const postulationsToUpdate = schoolCards.map((card) => {
                if (!card.school || !card.school.school_id) {
                    throw new Error(
                        'Falta el school_id en una de las postulaciones'
                    );
                }
                if (!card.preinscription_id) {
                    throw new Error(
                        'Falta el preinscription_id en una de las postulaciones'
                    );
                }
                return {
                    school_id: card.school.school_id,
                    preinscription_id: card.preinscription_id,
                    siblings_number: 2, // OPCIONAL
                    priority: 4, // OPCIONAL
                };
            });

            const data = {
                postulations: postulationsToUpdate,
            };

            const CellToUse = cellphonePostulations || cellphoneContact;

            const response = await Connection.setPostulations(data, CellToUse);

            console.log('Respuesta del servidor:', response);

            feedbackApp.showFeedback({
                title: 'Postulaciones actualizadas correctamente',
            });
        } catch (error) {
            console.error('Error al guardar postulaciones:', error);
            let feedbackError = FeedbackService.getMessage(error);
            feedbackApp.showFeedback({
                title: feedbackError.title,
            });
        }

        setOpenModal(false);
    };

    return (
        <Box>
            <Typography
                variant="h5"
                align="center"
                gutterBottom
                style={{ color: '#919399' }}
            >
                📑 Actualización de Postulaciones
            </Typography>
            <Typography
                variant="subtitle1"
                gutterBottom
                style={{ color: '#919399' }}
            >
                📌 Actualiza las postulaciones rechazadas Por favor, revisa y
                actualiza las postulaciones que fueron rechazadas. 👉 Puedes
                seleccionar escuelas para volver a enviar la postulación. ✍️
                Asegúrate de que toda la información esté correcta antes de
                continuar.
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {schoolCards.map((card, index) => (
                        <Card
                            sx={{
                                mt: 1,
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
                                backgroundColor: '#ffffff',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                marginBottom: '16px',
                                '&:hover': {
                                    boxShadow:
                                        '0px 6px 16px rgba(0, 0, 0, 0.25)',
                                },
                            }}
                            key={index}
                        >
                            <CardHeader
                                title={`Postulación ${index + 1}`}
                                subheader="Actualiza la escuela para esta postulación"
                            />
                            <CardContent>
                                <Autocomplete
                                    disablePortal
                                    options={[...card.options]
                                        .filter(
                                            (option) =>
                                                !selectedSchools.includes(
                                                    option.school_id
                                                ) &&
                                                !rejectedAndPendingSchools.includes(
                                                    option.school_id
                                                )
                                        )
                                        .sort((a, b) =>
                                            a.state.localeCompare(b.state)
                                        )}
                                    loading={card.loading}
                                    loadingText={
                                        card.inputValue.length < 3
                                            ? 'Escribe para iniciar búsqueda'
                                            : 'Buscando...'
                                    }
                                    noOptionsText={
                                        card.inputValue.length < 3
                                            ? 'Escribe para iniciar búsqueda'
                                            : 'No se encontraron resultados'
                                    }
                                    groupBy={(option) =>
                                        option.state.trim().toUpperCase()
                                    }
                                    getOptionLabel={(option) =>
                                        `${option.name}, Turno:  ${getTurnLevel(
                                            option.turn
                                        )} CCT: ${option.cct} - ${
                                            option.street
                                        } ${option.colony} #${option.no_ext} ${
                                            option.zip_code
                                        }`
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.school_id === value.school_id
                                    }
                                    inputValue={card.inputValue}
                                    onInputChange={(event, newInputValue) =>
                                        handleInputChange(
                                            index,
                                            event,
                                            newInputValue
                                        )
                                    }
                                    onChange={(event, newValue) =>
                                        handleSelectionChange(index, newValue)
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            label="Escuela"
                                            type="text"
                                            variant="outlined"
                                            placeholder="Escribe el nombre de la escuela"
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <>
                                                        <InputAdornment position="start">
                                                            <img
                                                                src={
                                                                    GreenSchoolIcon
                                                                }
                                                                width={35}
                                                                height={35}
                                                                alt="icon"
                                                            />
                                                        </InputAdornment>
                                                        {
                                                            params.InputProps
                                                                .startAdornment
                                                        }
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                                {card.school && card.school.name && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            mt: 2,
                                        }}
                                    >
                                        <Box sx={{ flex: 1, maxWidth: '60%' }}>
                                            <Typography variant="h6">
                                                {card.school.name}
                                            </Typography>
                                            <Typography variant="body2">
                                                {`Turno: ${getTurnLevel(
                                                    card.school.turn
                                                )}`}
                                            </Typography>
                                            <Typography variant="body2">
                                                {`Dirección: ${card.school.street} #${card.school.no_ext} ${card.school.colony}`}
                                            </Typography>
                                            <Typography variant="body2">
                                                {`Código Postal: ${card.school.zip_code}`}
                                            </Typography>
                                            <Typography variant="body2">
                                                {`Estado: ${card.school.state}`}
                                            </Typography>
                                            <Typography variant="body2">
                                                {`Teléfono: ${card.school.phone}`}
                                            </Typography>
                                            <Box sx={{ mt: 2 }}>
                                                <Typography
                                                    variant="body2"
                                                    sx={{ mb: 1 }}
                                                >
                                                    Número de hermanos en la
                                                    escuela
                                                </Typography>
                                                <Select
                                                    value={
                                                        card.siblings_number ||
                                                        0
                                                    }
                                                    size="small"
                                                    onChange={(e) =>
                                                        handleSiblingsChange(
                                                            index,
                                                            e.target.value
                                                        )
                                                    }
                                                    variant="outlined"
                                                >
                                                    {[...Array(11)].map(
                                                        (_, i) => (
                                                            <MenuItem
                                                                key={i}
                                                                value={i}
                                                            >
                                                                {i}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                flex: 1,
                                                maxWidth: '70%',
                                                height: '200px',
                                                backgroundColor: '#f0f0f0',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: '1px dashed #ccc',
                                                mt: 1,
                                            }}
                                        >
                                            {card.school.latitude &&
                                            card.school.longitude ? (
                                                <Map
                                                    height={230}
                                                    defaultCenter={[
                                                        card.school.latitude,
                                                        card.school.longitude,
                                                    ]}
                                                    defaultZoom={15}
                                                    attribution={false}
                                                >
                                                    <Marker
                                                        width={50}
                                                        anchor={[
                                                            card.school
                                                                .latitude,
                                                            card.school
                                                                .longitude,
                                                        ]}
                                                    >
                                                        <img
                                                            src={BlueSchoolIcon}
                                                            width={50}
                                                            height={50}
                                                            alt="marker"
                                                        />
                                                    </Marker>
                                                </Map>
                                            ) : (
                                                <Typography textAlign="center">
                                                    Ubicación no disponible
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                )}
                            </CardContent>
                        </Card>
                    ))}
                </Grid>
            </Grid>
            <Typography
                variant="subtitle1"
                gutterBottom
                style={{ color: '#919399' }}
            >
                ⚠️ Asegúrate de que los datos sean precisos para garantizar que
                el registro sea adecuado.
            </Typography>
            <center>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 2,
                        maxWidth: 800,
                    }}
                >
                    <Controls.Button
                        text="Cerrar"
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => setOpenModal(false)}
                    />
                    <Controls.Button
                        text="Guardar"
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<Send />}
                        onClick={savePostulations}
                        disabled={isSaveDisabled}
                    />
                </Box>
            </center>
        </Box>
    );
};

export default DocumentNewPrescription;
