import React from 'react';
import { IconButton, Tooltip, Button, Chip, Box, Badge } from '@mui/material';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import AddIcon from '@mui/icons-material/Add';
import {
    GridPreferencePanelsValue,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarQuickFilter,
    useGridApiContext,
} from '@mui/x-data-grid';
import { ReactComponent as FilterIcon } from '../../assets/images/filter-svgrepo-com.svg';
import { LoadingButton } from '@mui/lab';
import { Affix } from 'antd';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { DateTime } from 'luxon';

export const GridToolBar = ({
    onAddButtonClick,
    ButtonText,
    AnotherButtons = [],
    massActions = [],
    tabs = [],
}) => {
    //////////////////////////// HOOKS ////////////////////////////
    const location = useLocation();
    const apiRef = useGridApiContext();

    //////////////////////////// HANDLERS ////////////////////////////
    const handleFilterClick = () => {
        apiRef.current.showFilterPanel();
    };

    const handleColumnsClick = () => {
        apiRef.current?.showPreferences(GridPreferencePanelsValue.columns);
    };

    //////////////////////////// ACTIONS ////////////////////////////

    const getCurrentDate = () => {
        const now = DateTime.now();
        const datePart = now.toFormat('yyyy-MM-dd');
        const timePart = now.toFormat('HH:mm:ss');
        return `${datePart} ${timePart}`;
    };

    const fileName = `${location.pathname}_${getCurrentDate()}`;
    const filterCount = apiRef.current.state.filter.filterModel.items.length;

    //////////////////////////// RENDER ////////////////////////////

    return (
        <GridToolbarContainer
            sx={{
                padding: 0,
                width: '100%',
            }}>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                }}>
                <Affix
                    className="Affix"
                    offsetTop={46}
                    style={{
                        width: '100%',
                        display: 'flex',
                        padding: 0,
                    }}>
                    <div>
                        <div
                            style={{
                                backgroundColor: '#ffffff',
                                height: '50px',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginLeft: '10px',
                                }}>
                                <div>
                                    {AnotherButtons.map((button, index) =>
                                        button.loadingPosition ? (
                                            <LoadingButton
                                                key={index}
                                                variant="contained"
                                                size="small"
                                                startIcon={button.icon}
                                                onClick={button.onClick}
                                                disabled={button.disabled}
                                                loading={button.loading}
                                                loadingPosition={
                                                    button.loadingPosition
                                                }
                                                sx={{
                                                    marginRight: 1,
                                                    borderRadius: '10px',
                                                }}>
                                                {button.text}
                                            </LoadingButton>
                                        ) : (
                                            <Button
                                                key={index}
                                                variant="contained"
                                                size="small"
                                                startIcon={button.icon}
                                                onClick={button.onClick}
                                                disabled={button.disabled}
                                                sx={{
                                                    marginRight: 1,
                                                    borderRadius: '10px',
                                                }}>
                                                {button.text}
                                            </Button>
                                        )
                                    )}
                                    {onAddButtonClick && (
                                        <Button
                                            variant="contained"
                                            size="small"
                                            startIcon={<AddIcon />}
                                            onClick={onAddButtonClick}
                                            sx={{
                                                borderRadius: '10px',
                                            }}>
                                            {ButtonText}
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <div
                                style={{
                                    marginRight: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                <GridToolbarQuickFilter />

                                <Tooltip title="Filtrar" placement="top" arrow>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: '#dedede44',
                                            paddingLeft: '40px',
                                        }}>
                                        <Badge
                                            badgeContent={filterCount}
                                            color="primary"
                                            height="20px"
                                            width="20px"
                                        />
                                    </Box>
                                    <IconButton onClick={handleFilterClick}>
                                        <FilterIcon width={20} height={20} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Columnas" placement="top" arrow>
                                    <IconButton onClick={handleColumnsClick}>
                                        <ViewColumnIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Exportar" placement="top" arrow>
                                    <GridToolbarExport
                                        csvOptions={{
                                            utf8WithBom: true,
                                            delimiter: ',',
                                            fileName: fileName,
                                        }}
                                        size="large"
                                        sx={{
                                            color: '#0000008a',
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        <div
                            style={{
                                backgroundColor: '#ffffff',
                                width: '100%',
                                display: 'flex',
                                paddingTop: '10px',
                                justifyContent: 'space-between',
                            }}>
                            {massActions && (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        width: '100%',
                                    }}>
                                    {massActions.map((action, index) => {
                                        return (
                                            <Button
                                                key={index}
                                                size="small"
                                                variant="text"
                                                disableElevation
                                                disabled={action.disabled}
                                                onClick={action.onClick}
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: action.color,
                                                    backgroundColor:
                                                        '#dedede44',
                                                    borderRadius:
                                                        '10px 10px 0px 0px',
                                                    borderTop:
                                                        '2px solid rgba(224, 224, 224, 1)',
                                                    borderLeft:
                                                        '2px solid rgba(224, 224, 224, 1)',
                                                    borderRight:
                                                        '2px solid rgba(224, 224, 224, 1)',
                                                }}
                                                startIcon={action.icon}>
                                                {action.tooltip}
                                            </Button>
                                        );
                                    })}
                                </div>
                            )}
                            {tabs.length > 0 && (
                                <Box sx={{ display: 'flex', gap: '10px' }}>
                                    {tabs.map((tab, index) => (
                                        <Chip
                                            key={index}
                                            label={tab.label}
                                            icon={tab.icon || null}
                                            sx={{
                                                color:
                                                    tab.chips === tab.value
                                                        ? '#040404'
                                                        : '#fff',
                                                backgroundColor:
                                                    tab.chips === tab.value
                                                        ? '#dedede44'
                                                        : tab.color,
                                                borderTop:
                                                    '2px solid rgba(224, 224, 224, 1)',
                                                borderLeft:
                                                    '2px solid rgba(224, 224, 224, 1)',
                                                borderRight:
                                                    '2px solid rgba(224, 224, 224, 1)',
                                                borderRadius:
                                                    '10px 10px 0px 0px',

                                                minWidth: tab.icon
                                                    ? {
                                                          xs: 'auto',
                                                          sm: '100px',
                                                      }
                                                    : '100px',
                                                '& .MuiChip-label': {
                                                    display: tab.icon
                                                        ? {
                                                              xs: 'none',
                                                              sm: 'block',
                                                          }
                                                        : 'block',
                                                },
                                                '& .MuiChip-icon': {
                                                    margin: tab.icon
                                                        ? { xs: '0' }
                                                        : '-6px 8px 0 -4px',
                                                    color:
                                                        tab.chips === tab.value
                                                            ? '#040404  '
                                                            : '#fff',
                                                },
                                            }}
                                            onClick={tab.onClick}
                                        />
                                    ))}
                                </Box>
                            )}
                        </div>
                    </div>
                </Affix>
            </div>
        </GridToolbarContainer>
    );
};
