import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const documentsPreinscriptionsAdapter = createEntityAdapter({
    selectId: (documentPreinscription) =>
        documentPreinscription.document_preinscription_id,
    sortComparer: (a, b) =>
        a.document_preinscription_id - b.document_preinscription_id,
});

export const documentsPreinscriptionsSlice = createSlice({
    name: 'documentsPreinscriptions',
    initialState: documentsPreinscriptionsAdapter.getInitialState,
    reducers: {
        addOneDocumentPreinscription: documentsPreinscriptionsAdapter.addOne,
        addManyDocumentsPreinscriptions:
            documentsPreinscriptionsAdapter.addMany,
        upsertOneDocumentPreinscription:
            documentsPreinscriptionsAdapter.upsertOne,
        upsertManyDocumentsPreinscriptions:
            documentsPreinscriptionsAdapter.upsertMany,
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return documentsPreinscriptionsAdapter.getInitialState();
        });
    },
});

export const {
    addOneDocumentPreinscription,
    addManyDocumentsPreinscriptions,
    upsertOneDocumentPreinscription,
    upsertManyDocumentsPreinscriptions,
} = documentsPreinscriptionsSlice.actions;

const globalizedSelectors = documentsPreinscriptionsAdapter.getSelectors(
    (state) => state.entities.documentsPreinscriptions
);

export const selectDocumentsPreinscriptions = (state) =>
    globalizedSelectors.selectEntities(state);

export const selectAllDocumentsPreinscriptions = (state) =>
    globalizedSelectors.selectAll(state);

export default documentsPreinscriptionsSlice.reducer;
