import ActionButton from './Buttons/ActionButton';
import Button from './Buttons/Button';
import LoadingButton from './Buttons/LoadingButton';

const Controls = {
    Button,
    ActionButton,
    LoadingButton,
};

export default Controls;
