import * as Yup from 'yup';

export const uuidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
export const phoneRegex = /^[0-9]{10}$/;

export const validationSchema = Yup.object({
    identifier: Yup.string()
        .required('Campo requerido')
        .test(
            'is_valid',
            'Debe ser un Folio válido o número de teléfono válido',
            (value) => {
                return uuidRegex.test(value) || phoneRegex.test(value);
            }
        ),
});
