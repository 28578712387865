import React, { useEffect } from 'react';
import { Box, Skeleton, Stack, Tooltip } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { Sync } from '@material-ui/icons';
import { PrescriptionsTable } from './components/PrescriptionsTable';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
    indvalidate,
    loadUI,
    selectFetchingStatus,
} from '../../store/fetchSlice';
import { useAuth } from '../../../hooks';
import ErrorPage from '../../../PaymentModule/components/Error/ErrorPage';

const PreInscriptions = () => {
    ////////////////////////// LOCAL STATE //////////////////////////

    //////////////////// SHARE STATE ////////////////////
    const dispatch = useDispatch();
    const Auth = useAuth();
    const history = useHistory();
    const statusServer = useSelector(selectFetchingStatus);

    //////////////////// USEEFECT ////////////////////

    useEffect(() => {
        dispatch(loadUI(Auth.getUser().school_id));
    }, [history]);

    //////////////////// FUNCTIONS ////////////////////

    const reload = () => {
        dispatch(loadUI(Auth.getUser().school_id));
    };

    const invalidateUI = () => {
        dispatch(indvalidate());
        reload();
    };

    return (
        <>
            <Box
                sx={{
                    paddingTop: {
                        xs: 1,
                        sm: 2,
                        md: 2,
                    },
                    paddingLeft: {
                        xs: 1,
                        sm: 2,
                        md: 5,
                    },
                    paddingRight: {
                        xs: 1,
                        sm: 2,
                        md: 5,
                    },

                    flexGrow: 1,
                }}>
                <Stack
                    spacing={2}
                    direction={{ xs: 'column', sm: 'row' }}
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                    <Tooltip title="Sincronizar información">
                        <LoadingButton
                            onClick={invalidateUI}
                            loadingPosition="start"
                            variant="contained"
                            size="small"
                            startIcon={<Sync />}
                            loading={statusServer === 'pending'}>
                            Sincronizar
                        </LoadingButton>
                    </Tooltip>
                </Stack>

                {statusServer == 'rejected' ? (
                    <ErrorPage
                        onRetry={reload}
                        maxWidth={'40rem'}
                        mt={2}
                        maxHeight={'20rem'}
                        alignContent={'center'}
                        message={
                            'Ocurrió un error mientras intentábamos acceder a este recurso ¿Podrías intentarlo una vez más? ¡Por favor! 🤞'
                        }
                    />
                ) : statusServer === 'pending' ? (
                    <Skeleton
                        variant="rectangular"
                        height={600}
                        sx={{ mt: 2 }}
                        animation="wave"
                    />
                ) : (
                    <PrescriptionsTable />
                )}
            </Box>
        </>
    );
};

export default PreInscriptions;
