import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useFormik } from 'formik';
import { validationSchema } from './components/validationSchema';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import PersonalInfoForm from './components/PersonalInfoForm';
import AddressForm from './components/AddressForm';
import GpsLocationField from './components/GpsLocationField';
import RelationshipSelector from './components/RelationshipSelector';
import AddressReuse from './components/AddressReuse';
import { isEqual } from 'lodash';

export const StudentParent = ({ data, setData, handleBack, handleNext }) => {
    const initialParent = useMemo(
        () =>
            data?.parent || {
                parent_name: '',
                parent_last_name: '',
                parent_second_last_name: '',
                parent_email: '',
                parent_phone: '',
                parent_birth_date: '',
                parent_gender: '',
                parent_birth_city_id: '',
                relationship: '',
                parent_home_street: '',
                parent_home_external_number: '',
                parent_home_internal_number: '',
                parent_home_neighborhood: '',
                parent_home_zipcode: '',
                parent_birth_state_id: '',
                parent_birth_minicipality_id: '',
                addressCity: '',
                parent_birth_state_name: '',
                parent_birth_minicipality_name: '',
                parent_birth_city_name: '',
                work_street: '',
                work_neighborhood: '',
                work_zipcode: '',
                work_external_number: '',
                work_internal_number: '',
                address_Information: '',
            },
        [data?.parent]
    );

    const calculateIsUnderAge = useCallback((birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);
        let age = today.getFullYear() - birth.getFullYear();
        const monthDifference = today.getMonth() - birth.getMonth();

        if (
            monthDifference < 0 ||
            (monthDifference === 0 && today.getDate() < birth.getDate())
        ) {
            age--;
        }

        return age < 18;
    }, []);

    const isUnderAge = useMemo(
        () => calculateIsUnderAge(data?.student?.birth_date),
        [data?.student?.birth_date, calculateIsUnderAge]
    );

    const initialValues = useMemo(
        () => ({
            ...initialParent,
            longitude: data?.longitude || '',
            latitude: data?.latitude || '',
        }),
        [initialParent, data?.longitude, data?.latitude]
    );

    const formik = useFormik({
        initialValues,
        validateOnMount: true,
        validationSchema,
        onSubmit: (values) => {
            const updatedParent = { ...values };
            setData({
                ...data,
                parent: updatedParent,
                longitude: values.longitude || '',
                latitude: values.latitude || '',
            });
            handleNext();
        },
    });

    useEffect(() => {
        const hasChanges = !isEqual(formik.values, data.parent);

        if (hasChanges) {
            setData((prevData) => ({
                ...prevData,
                parent: formik.values,
                longitude: formik.values.longitude,
                latitude: formik.values.latitude,
            }));
        }
    }, [formik.values, data.parent, setData]);

    useEffect(() => {
        const studentData = data.student;
        const shouldCopyAddress = formik.values.address_Information === '1';
        const isRelationship = formik.values.relationship === '4';

        const newValues = {};

        if (shouldCopyAddress) {
            Object.assign(newValues, {
                parent_home_street: studentData?.home_street || '',
                parent_home_external_number:
                    studentData?.home_external_number || '',
                parent_home_internal_number:
                    studentData?.home_internal_number || '',
                parent_home_neighborhood: studentData?.home_neighborhood || '',
                parent_home_zipcode: studentData?.home_zipcode || '',
                //STATE, MUNICIPALITY, CITY - ADDRESS
                parent_birth_state_id: studentData?.state_id || '',
                parent_birth_minicipality_id:
                    studentData?.municipality_id || '',
                parent_birth_city_id: studentData?.city_id || '',

                parent_birth_state_name: studentData?.address_state_name || '',
                parent_birth_minicipality_name:
                    studentData?.address_municipality_name || '',
                parent_birth_city_name: studentData?.address_city_name || '',
            });
        }

        if (isRelationship) {
            Object.assign(newValues, {
                parent_name: studentData?.name || data.parent.parent_name,
                parent_last_name:
                    studentData?.last_name || data.parent.parent_last_name,
                parent_second_last_name:
                    studentData?.second_last_name ||
                    data.parent.parent_second_last_name,
                parent_email: studentData?.email || data.parent.parent_email,
                parent_phone: studentData?.phone || data.parent.parent_phone,
                parent_birth_date:
                    studentData?.birth_date || data.parent.parent_birth_date,
                parent_gender: studentData?.gender || data.parent.parent_gender,
            });
        }

        if (Object.keys(newValues).length > 0) {
            formik.setValues((prevValues) => ({
                ...prevValues,
                ...newValues,
            }));
        }
    }, [formik.values.relationship, formik.values.address_Information]);

    const isStudent = formik.values.relationship === '4';

    const handleRelationshipChange = useCallback(
        (selectedValue) => {
            if (selectedValue !== '4') {
                formik.setValues((prevValues) => ({
                    ...prevValues,
                    parent_name: '',
                    parent_last_name: '',
                    parent_second_last_name: '',
                    parent_email: '',
                    parent_phone: '',
                    parent_birth_date: '',
                    parent_gender: '',
                }));
            }
        },
        [formik]
    );

    const onClearAddress = useCallback(() => {
        formik.setValues((prevValues) => ({
            ...prevValues,
            parent_home_street: '',
            parent_home_external_number: '',
            parent_home_internal_number: '',
            parent_home_neighborhood: '',
            parent_home_zipcode: '',
            parent_birth_state_id: '',
            parent_birth_minicipality_id: '',
            parent_birth_city_id: '',
            parent_birth_state_name: '',
            parent_birth_minicipality_name: '',
            parent_birth_city_name: '',
        }));
    }, [formik]);

    return (
        <>
            <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 2 }}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid item xs={12} sm={12}>
                        <Divider>
                            <Typography
                                variant="h5"
                                align="center"
                                gutterBottom
                                style={{ color: '#919399' }}
                            >
                                📋 Información Personal
                            </Typography>
                        </Divider>
                        <Typography
                            variant="subtitle1"
                            gutterBottom
                            style={{ color: '#919399' }}
                        >
                            En este formulario, proporciona la información
                            personal básica del tutor, como su nombre completo,
                            fecha de nacimiento, género y datos de contacto. 📝
                            Estos datos son esenciales para identificar al tutor
                            y establecer una comunicación adecuada. ✅
                        </Typography>
                    </Grid>
                    <RelationshipSelector
                        value={formik.values.relationship}
                        isUnderAge={isUnderAge}
                        onRelationshipChange={handleRelationshipChange}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.relationship &&
                            Boolean(formik.errors.relationship)
                        }
                        helperText={
                            formik.touched.relationship &&
                            formik.errors.relationship
                        }
                    />
                    <AddressReuse
                        value={formik.values.address_Information}
                        onChange={formik.handleChange}
                        onClearAddress={onClearAddress}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.relationship &&
                            Boolean(formik.errors.relationship)
                        }
                        helperText={
                            formik.touched.relationship &&
                            formik.errors.relationship
                        }
                    />
                    <PersonalInfoForm
                        formik={formik}
                        data={data}
                        initialParent={initialParent}
                        initialState={data?.parent?.parent_birth_state_id}
                        initialMunicipality={
                            data?.parent?.parent_birth_minicipality_id
                        }
                        initialCity={data?.parent?.parent_birth_city_id}
                        isStudent={isStudent}
                    />
                    <Grid item xs={12} sm={12}>
                        <Divider>
                            <Typography
                                variant="h5"
                                align="center"
                                gutterBottom
                                style={{ color: '#919399' }}
                            >
                                🏢 Datos de Trabajo
                            </Typography>
                        </Divider>
                        <Typography
                            variant="subtitle1"
                            gutterBottom
                            style={{ color: '#919399' }}
                        >
                            Esta sección de carácter opcional, tiene la
                            intención de conocer la ubicación de su lugar de
                            trabajo con el objetivo de que ésta, pueda ser
                            considerada al momento de la aceptación del alumno a
                            preinscribir. 📍
                        </Typography>
                    </Grid>
                    <AddressForm
                        formik={formik}
                        data={data}
                        initialParent={initialParent}
                    />
                    <GpsLocationField
                        longitude={formik.values.longitude}
                        latitude={formik.values.latitude}
                        setFieldValue={formik.setFieldValue}
                    />
                </form>
            </Box>
            <Box
                sx={{
                    maxWidth: 800,
                    margin: '0 auto',
                    padding: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Button variant="outlined" onClick={handleBack}>
                    Regresar
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    onClick={() => {
                        formik.validateForm().then((errors) => {
                            if (Object.keys(errors).length === 0) {
                                formik.handleSubmit();
                            } else {
                                console.log('Errores de validación:', errors);
                            }
                        });
                    }}
                    disabled={
                        !formik.isValid ||
                        (isUnderAge && formik.values.relationship === '4')
                    }
                >
                    Siguiente
                </Button>
            </Box>
        </>
    );
};
