import { Comment } from '@mui/icons-material';
import { Avatar, Box, Card, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

export const ExistingObservations = ({ doc }) => {
    const [currentDoc, setCurrentDoc] = useState(doc);

    useEffect(() => {
        setCurrentDoc(doc);
    }, [doc]);

    return (
        <Card
            sx={{
                height: '35vh',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                    borderRadius: '10px',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#f1f1f1',
                    borderRadius: '10px',
                    '&:hover': {
                        background: '#f1f1f1',
                    },
                },
            }}>
            <Typography
                variant="h6"
                component="div"
                style={{
                    padding: '16px',
                    fontWeight: 'bold',
                    color: '#7F8185',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'sticky',
                    top: 0,
                    backgroundColor: 'white',
                    zIndex: 1,
                    borderBottom: '1px solid #f1f1f1',
                }}>
                Observaciones
            </Typography>
            <Box
                sx={{
                    padding: '0 16px 16px 16px',
                    overflow: 'auto',
                    height: '100%',
                    display: 'flex',
                    justifyContent: doc.observations ? 'flex-start' : 'center',
                    alignItems: doc.observations ? 'flex-start' : 'center',
                }}>
                {doc.observations ? (
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Avatar
                            sx={{ bgcolor: '#00C483', width: 32, height: 32 }}>
                            <Comment sx={{ fontSize: 16 }} />
                        </Avatar>
                        <Typography
                            variant="body1"
                            component="div"
                            sx={{
                                color: '#7F8185',
                                padding: '4px 12px',
                                backgroundColor: '#f5f5f5',
                                borderRadius: '12px',
                                maxWidth: '90%',
                            }}>
                            {currentDoc.observations}
                        </Typography>
                    </Box>
                ) : (
                    <Typography
                        variant="body1"
                        component="div"
                        sx={{
                            color: '#9E9E9E',
                            fontStyle: 'italic',
                        }}>
                        No hay observaciones para este documento
                    </Typography>
                )}
            </Box>
        </Card>
    );
};
