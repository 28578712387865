import { Box, Divider, Grid } from '@mui/material';
import React, { useState } from 'react';
import { Cancel, CheckCircleOutline, Schedule } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { PREISCRIPTIONS_DOCUMENTS } from '../../../../../service/const';
import { StatusChips } from './Components/StatusChips';
import { ObservationsForm } from './Components/ObservationsForm';
import { ExistingObservations } from './Components/ExistingObservations';
import { PaginationDoc } from './Components/Pagination';
import { DocumentViewer } from './Components/DocumentViewer';
import { updateDocument } from '../../../../store/operationsSlice';
import { useFeedback } from '../../../../../hooks';

export const Documents = ({ documents }) => {
    ////////////////////////// LOCAL STATE //////////////////////////
    const [currentPage, setCurrentPage] = useState(0);
    const [documentValues, setDocumentValues] = useState({});
    const [documentStatuses, setDocumentStatuses] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentDocuments, setCurrentDocuments] = useState(documents);

    const estados = [
        {
            label: 'Aceptar',
            value: PREISCRIPTIONS_DOCUMENTS.ACCEPTED,
            icon: <CheckCircleOutline />,
            color: 'success',
        },
        {
            label: 'Pendiente',
            value: PREISCRIPTIONS_DOCUMENTS.UPLOADED,
            icon: <Schedule />,
            color: 'warning',
        },
        {
            label: 'Rechazar',
            value: PREISCRIPTIONS_DOCUMENTS.REJECTED,
            icon: <Cancel />,
            color: 'error',
        },
    ];

    //////////////////////////// SHARED STATE //////////////////////////
    const dispath = useDispatch();
    const feedbackApp = useFeedback();

    //////////////////////////// ACTIONS ////////////////////////////
    const documentsPerPage = 1;
    const totalPages = Math.ceil(documents.length / documentsPerPage);

    const documentsToShow = currentDocuments.slice(
        currentPage * documentsPerPage,
        (currentPage + 1) * documentsPerPage
    );

    const handleNextPage = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleChangeStatus = (docId, newStatus) => {
        setDocumentStatuses((prev) => ({
            ...prev,
            [docId]: newStatus,
        }));
    };

    const handleChangeObservation = (docId, value) => {
        setDocumentValues((prev) => ({
            ...prev,
            [docId]: value,
        }));
    };

    const isButtonEnabled = (doc) => {
        const currentStatus = documentStatuses[doc.document_preinscription_id];
        const currentValue =
            documentValues[doc.document_preinscription_id] || '';
        const originalValue = doc.observations || '';
        const hasStatusChanged =
            currentStatus !== undefined && currentStatus !== doc.status;

        if (currentStatus === PREISCRIPTIONS_DOCUMENTS.REJECTED) {
            return currentValue.trim().length > 0;
        }

        return (
            hasStatusChanged ||
            (currentValue !== originalValue && currentValue.trim().length > 0)
        );
    };
    const handleSubmit = async (doc) => {
        const docId = doc.document_preinscription_id;
        const newStatus =
            documentStatuses[docId] !== undefined
                ? documentStatuses[docId]
                : doc.status;
        const observations = documentValues[docId] || '';
        setLoading(true);

        await dispath(
            updateDocument({
                documentId: docId,
                uuid: doc.preinscription_id,
                data: {
                    status: newStatus,
                    observations: observations,
                },
            })
        )
            .unwrap()
            .then(() => {
                setCurrentDocuments((prevDocs) =>
                    prevDocs.map((d) =>
                        d.document_preinscription_id === docId
                            ? {
                                  ...d,
                                  status: newStatus,
                                  observations: observations,
                              }
                            : d
                    )
                );
                setDocumentValues((prev) => ({
                    ...prev,
                    [docId]: '',
                }));
                setDocumentStatuses((prev) => ({
                    ...prev,
                    [docId]: undefined,
                }));

                feedbackApp.showFeedback({
                    title: '📄 Documento actualizados con exito ✨',
                    severity: 'success',
                });
            })
            .catch((error) => {
                feedbackApp.showFeedback({
                    title: error.feedback.title,
                    severity: 'error',
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: { md: '77vh' },
                    position: 'relative',
                    width: { md: '130vh' },
                    overflow: 'hidden',
                    top: 2,
                }}>
                <Box sx={{ flex: 1 }}>
                    {documentsToShow.map((doc) => (
                        <Grid
                            container
                            spacing={2}
                            key={doc.document_preinscription_id}>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={8}
                                sx={{
                                    flexDirection: 'column',
                                    height: '66vh',
                                    width: '100%',
                                }}>
                                <DocumentViewer url={doc.url} />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={4}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '70vh',
                                    order: { xs: 1, md: 2 },
                                }}>
                                <StatusChips
                                    estados={estados}
                                    doc={doc}
                                    documentStatuses={documentStatuses}
                                    handleChangeStatus={handleChangeStatus}
                                />
                                <ObservationsForm
                                    doc={doc}
                                    documentValues={documentValues}
                                    documentStatuses={documentStatuses}
                                    handleChangeObservation={
                                        handleChangeObservation
                                    }
                                    handleSubmit={handleSubmit}
                                    isButtonEnabled={isButtonEnabled}
                                    loading={loading}
                                />
                                <Divider
                                    sx={{
                                        borderColor: '#00C483',
                                        borderWidth: 2,
                                    }}
                                />
                                <ExistingObservations doc={doc} />
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            </Box>
            <PaginationDoc
                currentPage={currentPage}
                totalPages={totalPages}
                handlePrevPage={handlePrevPage}
                handleNextPage={handleNextPage}
            />
        </>
    );
};
