import {} from '@mui/material';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ConfirmationModal } from '../Components/ConfirmationModal';
import { useFeedback } from '../../hooks';
import Connection from '../../service/Connection';

const ShowContainerContext = createContext();

export const ShowContainerProvider = ({ children }) => {
    /////////////////////// LOCAL STATE ///////////////////////////

    const [showContainer, setShowContainer] = useState('inicio');
    const [uuid, setuuid] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [pendingNavigation, setPendingNavigation] = useState(null);
    const [PreInscription, setPreinscription] = useState([]);

    /////////////////////// HOOKS ///////////////////////////

    const history = useHistory();
    const feedbackApp = useFeedback();

    /////////////////////// ACTIONS ///////////////////////////

    const handleContainerChange = (newContainer, targetPath) => {
        const isNavigatingToHome = newContainer === 'inicio';
        const isInPreInscription = showContainer === 'pre-inscribirte';

        if (isInPreInscription && isNavigatingToHome) {
            setShowConfirmModal(true);
            setPendingNavigation({ container: 'inicio', path: targetPath });
            return;
        }

        setShowContainer(newContainer);
        if (targetPath) {
            history.push(targetPath);
        }
    };

    const handleConfirm = async () => {
        const hasArchives =
            PreInscription.archives &&
            Object.keys(PreInscription.archives).length > 0;

        const cleanupAndNavigate = () => {
            setPreinscription({
                preinscriptionLevel: null,
                student: {},
                parent: {},
                archives: {},
            });

            setShowContainer(pendingNavigation.container);
            if (pendingNavigation.path) {
                history.push(pendingNavigation.path);
            }
            setShowConfirmModal(false);
            setPendingNavigation(null);
        };

        if (!hasArchives) {
            cleanupAndNavigate();
            return;
        }

        const deletePromises = Object.values(PreInscription.archives).map(
            (archive) =>
                Connection.DeletePrescriptionFile({
                    filename: archive.file.name,
                })
        );

        Promise.all(deletePromises)
            .then(() => {
                feedbackApp.showFeedback({
                    title: 'Archivos eliminados exitosamente',
                    severity: 'success',
                });
                cleanupAndNavigate();
            })
            .catch((error) => {
                console.error('Error al eliminar los archivos:', error);
                feedbackApp.showFeedback({
                    title: 'Error al eliminar los archivos',
                    severity: 'error',
                });
            });
    };

    const handleCancel = () => {
        setShowConfirmModal(false);
        setPendingNavigation(null);
    };
    /////////////////////// EFFECTS ///////////////////////////

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (showContainer === 'pre-inscribirte') {
                event.preventDefault();
                event.returnValue = '';
                return '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () =>
            window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [showContainer]);

    /////////////////////// RENDER ///////////////////////////

    return (
        <>
            <ShowContainerContext.Provider
                value={{
                    showContainer,
                    setShowContainer: handleContainerChange,
                    uuid,
                    setuuid,
                    setPreinscription,
                    PreInscription,
                }}>
                {children}
            </ShowContainerContext.Provider>

            <ConfirmationModal
                open={showConfirmModal}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
        </>
    );
};

export const useShowContainer = () => useContext(ShowContainerContext);
