import { IconButton, Stack, Tooltip, Chip, Typography } from '@mui/material';
import {
    PlagiarismOutlined,
    CheckCircle,
    Cancel,
    Schedule,
} from '@mui/icons-material';
import { DateTime } from 'luxon';

export const PrescriptionsColumns = (actions) => [
    {
        field: 'postulation_id',
        headerName: 'Folio de postulación',
        flex: 0.5,
    },
    {
        field: 'name',
        headerName: 'Nombre del alumno',
        flex: 2,
        valueGetter: (params) => {
            const preinscription = params.row.preinscription;
            return `${preinscription.name} ${preinscription.last_name} ${preinscription.second_last_name}`;
        },
    },
    {
        field: 'grade',
        headerName: 'Grado',
        flex: 0.5,
        valueGetter: (params) => `${params.row.preinscription.grade}°`,
    },
    {
        field: 'status',
        headerName: 'Estatus',
        flex: 1.5,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
        type: 'singleSelect',
        valueOptions: ['Pendiente', 'Aceptada', 'Rechazada'],
        valueGetter: (params) => {
            switch (params.value) {
                case 0:
                    return 'Rechazada';
                case 1:
                    return 'Pendiente';
                case 2:
                    return 'Aceptada';
                default:
                    return 'Desconocido';
            }
        },
        renderCell: ({ row }) => {
            const getStatusConfig = (status) => {
                switch (status) {
                    case 0:
                        return {
                            label: 'Rechazada',
                            icon: <Cancel />,
                            color: 'error',
                        };
                    case 1:
                        return {
                            label: 'Pendiente',
                            icon: <Schedule />,
                            color: 'warning',
                        };
                    case 2:
                        return {
                            label: 'Aceptada',
                            icon: <CheckCircle />,
                            color: 'success',
                        };
                    default:
                        return {
                            label: 'Desconocido',
                            icon: <Schedule />,
                            color: 'default',
                        };
                }
            };

            const statusConfig = getStatusConfig(row.status);

            return (
                <>
                    <Chip
                        label={statusConfig.label}
                        color={statusConfig.color}
                        icon={statusConfig.icon}
                        onClick={(event) => actions.onViewPopover(event, row)}
                        sx={{
                            minWidth: { xs: 'auto', sm: '100px' },
                            padding: 1,
                            '& .MuiChip-label': {
                                display: { xs: 'none', sm: 'block' },
                            },
                            '& .MuiChip-icon': {
                                margin: { xs: '0' },
                            },
                        }}
                    />
                </>
            );
        },
    },
    {
        field: 'Documentos',
        headerName: 'Documentos',
        width: 100,
        sortable: false,
        filterable: false,
        align: 'center',
        disableExport: true,
        renderCell: ({ row }) => {
            return row.documents && row.documents.length > 0 ? (
                <Tooltip title="Ver documentos" arrow placement="top">
                    <IconButton
                        onClick={() => actions.onViewDocuments(row.documents)}
                        sx={{
                            color: '#7F8185',
                        }}>
                        <PlagiarismOutlined />
                    </IconButton>
                </Tooltip>
            ) : (
                <Typography variant="caption" color="textSecondary">
                    N/A
                </Typography>
            );
        },
    },
    {
        field: 'curp',
        headerName: 'CURP',
        flex: 2,
        align: 'center',
        headerAlign: 'center',
        valueGetter: ({ row }) =>
            row.preinscription.curp || 'Curp no proporcionada',
        renderCell: ({ row }) => {
            const curp = row.preinscription.curp || 'Curp no proporcionada';
            return (
                <Stack spacing={0.5}>
                    <Typography variant="caption" color="textSecondary">
                        {curp}
                    </Typography>
                </Stack>
            );
        },
    },
    {
        field: 'birth_date',
        headerName: 'Fecha de nacimiento',
        flex: 1,
        valueGetter: ({ row }) => {
            const birthDate = DateTime.fromISO(row.preinscription.birth_date);
            return birthDate.setLocale('es').toLocaleString({
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            });
        },
    },

    {
        field: 'Datos del tutor',
        headerName: 'Datos del tutor',
        flex: 1.5,
        minWidth: 300,
        valueGetter: ({ row }) => {
            const tutor = row.preinscription;
            return `${tutor.parent_name} ${tutor.parent_last_name} ${
                tutor.parent_second_last_name || ''
            } ${tutor.parent_phone || ''}`;
        },
        renderCell: ({ row }) => {
            const tutor = row.preinscription;
            return (
                <Stack spacing={0.5}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        {`${tutor.parent_name} ${tutor.parent_last_name} ${
                            tutor.parent_second_last_name || ''
                        }`}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                        📱Numero de telefono: {tutor.parent_phone || 'N/A'}
                    </Typography>
                </Stack>
            );
        },
    },
];
