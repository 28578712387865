import React from 'react';
import {
    Box,
    Typography,
    Button,
    Chip,
    IconButton,
    Tooltip,
    Alert,
    AlertTitle,
    Paper, // Importar Paper
} from '@mui/material';
import { Map, Marker } from 'pigeon-maps';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Avatar, Card, Divider, Progress } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { getLevelName, getTurnLevel } from '../../../../../../libs/utils';

const SchoolCard = ({
    postulation,
    isMobile,
    isMediumScreen,
    handleExportPDF,
    getStatusInfo,
    getChipText,
}) => {
    return (
        <Card
            hoverable
            style={{
                marginBottom: 16,
                minWidth: isMobile ? '100%' : isMediumScreen ? 350 : 600,
                maxWidth: isMobile ? '100%' : isMediumScreen ? 350 : 600,
                width: '100%',
            }}
            className="rounded-xl overflow-hidden shadow-lg w-full"
            cover={
                <>
                    {postulation.status === 0 && (
                        <Alert
                            severity="error"
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                zIndex: 1,
                                borderRadius: 0,
                                padding: '6px 18px',
                                '& .MuiAlert-icon': {
                                    padding: 0,
                                },
                                '& .MuiAlert-message': {
                                    padding: 0,
                                },
                            }}
                        >
                            <AlertTitle>Rechazado</AlertTitle>
                        </Alert>
                    )}
                    {postulation.schools.latitude &&
                        postulation.schools.longitude && (
                            <Box className="absolute top-12 bg-white m-1 rounded-xl shadow-lg z-10 left-1/2 transform -translate-x-1/2 w-10/12 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-5/12">
                                <Typography
                                    variant="body2"
                                    className="font-bold"
                                    align="center"
                                    mt={0.5}
                                >
                                    Dirección de la escuela
                                </Typography>
                                <Typography variant="body2" align="center">
                                    {postulation.schools.street}, #
                                    {postulation.schools.inside_number},{' '}
                                    {postulation.schools.zipcode},{' '}
                                    {postulation.schools.location}
                                </Typography>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    mt={0.1}
                                >
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={() => {
                                            const url = `https://www.google.com/maps/dir/?api=1&destination=${postulation.schools.latitude},${postulation.schools.longitude}`;
                                            window.open(url, '_blank');
                                        }}
                                    >
                                        Ver indicaciones de llegada
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    {postulation.schools.latitude &&
                    postulation.schools.longitude ? (
                        <Map
                            height={isMobile ? 150 : 250}
                            style={{ paddingTop: '50px' }}
                            defaultCenter={[
                                (postulation.schools.latitude || 24.0226) +
                                    0.0025,
                                (postulation.schools.longitude || -104.6532) -
                                    0.0005,
                            ]}
                            mouseEvents={true}
                            touchEvents={true}
                            attribution={false}
                            zoomSnap={true}
                            zoomDelta={0}
                            defaultZoom={15}
                        >
                            {postulation.schools.longitude &&
                                postulation.schools.latitude && (
                                    <Marker
                                        width={50}
                                        anchor={[
                                            postulation.schools.latitude,
                                            postulation.schools.longitude,
                                        ]}
                                    >
                                        <img
                                            src={
                                                getStatusInfo(
                                                    postulation.status
                                                ).icon
                                            }
                                            width={40}
                                            height={40}
                                            alt="marker"
                                        />
                                    </Marker>
                                )}
                        </Map>
                    ) : (
                        <Paper
                            elevation={0}
                            sx={{
                                height: isMobile ? 150 : 250,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#e0e0e0',
                                borderRadius: 0,
                                paddingTop: '50px',
                                mb: '5px',
                            }}
                        >
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ fontWeight: 'bold' }}
                            >
                                Mapa no disponible
                            </Typography>
                        </Paper>
                    )}
                </>
            }
            actions={[
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    key="actions"
                    sx={{ flexDirection: isMobile ? 'column' : 'row' }}
                >
                    {postulation.status === 2 && (
                        <Tooltip
                            title="Descargar Ficha de Preinscripción en PDF"
                            arrow
                        >
                            <IconButton
                                onClick={() =>
                                    handleExportPDF(postulation.postulation_id)
                                }
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    margin: -1,
                                    width: 'auto',
                                    height: 'auto',
                                    padding: 0,
                                    borderRadius: 2,
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                        borderRadius: 0,
                                        width: 'auto',
                                        height: 'auto',
                                    },
                                }}
                            >
                                <FileDownloadOutlinedIcon
                                    style={{ width: 40, height: 40 }}
                                />
                                <Typography
                                    variant="body2"
                                    className="font-bold"
                                    sx={{ marginTop: 1 }}
                                >
                                    Descarga tu comprobante de asignación
                                </Typography>
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>,
            ]}
        >
            <Box
                display="flex"
                alignItems="center"
                gap={3}
                sx={{ margin: -2, marginLeft: 1 }}
            >
                <Meta
                    avatar={
                        <Avatar
                            sx={(theme) => ({
                                width: '40px',
                                height: '40px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                overflow: 'visible',
                                [theme.breakpoints.down('sm')]: {
                                    width: '35px',
                                    height: '35px',
                                },
                            })}
                        >
                            <Box
                                component="img"
                                src={getStatusInfo(postulation.status).icon}
                                sx={(theme) => ({
                                    width: '40px',
                                    height: '40px',
                                    marginLeft: '-19.5px',
                                    marginTop: '-4px',
                                    position: 'absolute',
                                    [theme.breakpoints.down('sm')]: {
                                        width: '35px',
                                        height: '35px',
                                        marginLeft: '-17px',
                                    },
                                })}
                            />
                        </Avatar>
                    }
                    title={
                        <Box
                            display="flex"
                            flexDirection={{
                                xs: 'column',
                                sm: 'column',
                                md: 'column',
                                lg: 'column',
                            }}
                            gap={1}
                            width="100%"
                        >
                            <Typography
                                variant="h6"
                                className="font-bold"
                                sx={(theme) => ({
                                    fontSize: '1rem',
                                    [theme.breakpoints.down('sm')]: {
                                        fontSize: '0.875rem',
                                    },
                                })}
                            >
                                {postulation.schools.name}
                            </Typography>
                            <Box display="flex" flexWrap="wrap" gap={1}>
                                <Chip
                                    label={getChipText(postulation.priority)}
                                    sx={(theme) => ({
                                        backgroundColor: 'white',
                                        borderColor: '#005A99',
                                        color: '#003F66',
                                        borderWidth: '1px',
                                        borderStyle: 'solid',
                                        fontSize: '1rem',
                                        padding: '4px 8px',
                                        [theme.breakpoints.down('sm')]: {
                                            fontSize: '0.75rem',
                                            padding: '2px 6px',
                                        },
                                        [theme.breakpoints.up('md')]: {
                                            fontSize: '0.875rem',
                                            padding: '3px 7px',
                                        },
                                    })}
                                />
                            </Box>
                        </Box>
                    }
                    description={
                        <Box
                            marginTop={1.5}
                            marginBottom={1}
                            width="100%"
                            sx={(theme) => ({
                                [theme.breakpoints.down('sm')]: {
                                    marginTop: 1,
                                    marginBottom: 0.5,
                                },
                            })}
                        >
                            <Box
                                display="flex"
                                flexWrap="wrap"
                                gap={1}
                                marginBottom={1}
                            >
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Typography
                                        variant="body2"
                                        className="font-bold"
                                    >
                                        CCT:
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        className="font-normal"
                                    >
                                        {postulation.schools.clave}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Typography
                                        variant="body2"
                                        className="font-bold"
                                    >
                                        Teléfono:
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        className="font-normal"
                                    >
                                        {postulation.schools.cellphone}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                display="flex"
                                flexWrap="wrap"
                                gap={1}
                                marginBottom={1}
                            >
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Typography
                                        variant="body2"
                                        className="font-bold"
                                    >
                                        Nivel:
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        className="font-normal"
                                    >
                                        {getLevelName(
                                            postulation.schools.level
                                        )}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Typography
                                        variant="body2"
                                        className="font-bold"
                                    >
                                        Turno:
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        className="font-normal"
                                    >
                                        {getTurnLevel(postulation.schools.turn)}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    }
                />
            </Box>
            <Divider />
            <Box style={{ marginTop: -20, marginLeft: 1 }}>
                <Typography variant="h7" className="font-medium">
                    Proceso de aceptación
                </Typography>
                <Box
                    key={postulation.postulation_id}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    style={{ padding: 0, margin: 0 }}
                >
                    <Typography
                        variant="body2"
                        style={{
                            color: getStatusInfo(postulation.status).color,
                        }}
                    >
                        {getStatusInfo(postulation.status).text}
                    </Typography>
                    <Progress
                        percent={getStatusInfo(postulation.status).percentage}
                        showInfo={false}
                        strokeColor={getStatusInfo(postulation.status).color}
                        style={{ width: '100%' }}
                    />
                </Box>
            </Box>
        </Card>
    );
};

export default SchoolCard;
