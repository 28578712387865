import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const preinscriptionsAdapter = createEntityAdapter({
    selectId: (preinscription) => preinscription.preinscription_id,
    sortComparer: (a, b) => a.preinscription_id - b.preinscription_id,
});

export const preinscriptionsSlice = createSlice({
    name: 'preinscriptions',
    initialState: preinscriptionsAdapter.getInitialState(),
    reducers: {
        addOnePreinscription: preinscriptionsAdapter.addOne,
        addManyPreinscriptions: preinscriptionsAdapter.addMany,
        upsertOnePreinscription: preinscriptionsAdapter.upsertOne,
        upsertManyPreinscriptions: preinscriptionsAdapter.upsertMany,
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return preinscriptionsAdapter.getInitialState();
        });
    },
});

export const {
    addOnePreinscription,
    addManyPreinscriptions,
    upsertOnePreinscription,
    upsertManyPreinscriptions,
} = preinscriptionsSlice.actions;

const globalizedSelectors = preinscriptionsAdapter.getSelectors(
    (state) => state.entities.preinscriptions
);

export const selectPreinscriptions = (state) =>
    globalizedSelectors.selectEntities(state);

export const selectAllPreinscriptions = (state) =>
    globalizedSelectors.selectAll(state);

export default preinscriptionsSlice.reducer;
