import React from 'react';
import { StyledButton } from '../../styles';

export default function Button(props) {
    const { text, size, color, variant, onClick, disabled, type, ...other } =
        props;

    return (
        <StyledButton
            variant={variant || 'contained'}
            size={size || 'small'}
            color={color || 'primary'}
            onClick={onClick}
            type={type || 'button'}
            disabled={disabled || false}
            {...other}>
            {text}
        </StyledButton>
    );
}
