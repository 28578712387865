import {
    VisibilityOffOutlined,
    VisibilityOutlined,
    WarningOutlined,
    FileCopyOutlined,
} from '@mui/icons-material';
import { Box, Typography, Card, CardContent, IconButton } from '@mui/material';
import { message, Tooltip } from 'antd';
import React, { useState } from 'react';
import { getLevelName } from '../../../../../libs/utils';

export const UuidMessage = ({ level, activeStep, uuid }) => {
    const [isUuidVisible, setIsUuidVisible] = useState(false);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(uuid);
        message.success({
            content:
                'Su Folio de preinscripción ha sido copiado en su portapapeles. Use Ctrl+V para pegarlo.',
            style: {
                zIndex: 9999,
            },
        });
    };

    const toggleVisibility = () => {
        setIsUuidVisible(!isUuidVisible);
    };

    return (
        <Box className="flex justify-center items-center w-full ">
            <Card className="relative w-screen my-4 p-6 bg-gradient-to-r from-blue-100 to-blue-200 border-l-4 border-blue-500 text-blue-700 shadow-lg rounded-lg cursor-pointer">
                <CardContent>
                    <Box className="flex justify-between items-center mb-4">
                        <Tooltip
                            title="Copiar Folio de preinscripción"
                            placement="top"
                            color="blue"
                        >
                            <IconButton
                                onClick={handleCopyClick}
                                className="text-blue-500"
                            >
                                <FileCopyOutlined />
                            </IconButton>
                        </Tooltip>
                        <Typography
                            variant="body1"
                            className="text-sm sm:text-lg font-bold text-center"
                        >
                            {isUuidVisible ? uuid : '************'}
                        </Typography>
                        <Tooltip
                            title={
                                isUuidVisible
                                    ? 'Ocultar Folio de preinscripción'
                                    : 'Mostrar Folio de preinscripción'
                            }
                            placement="top"
                            color="blue"
                        >
                            <IconButton
                                onClick={toggleVisibility}
                                className="text-blue-500"
                            >
                                {isUuidVisible ? (
                                    <VisibilityOffOutlined />
                                ) : (
                                    <VisibilityOutlined />
                                )}
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box className="flex justify-center items-center text-center mb-4">
                        <WarningOutlined className="text-blue-500 size-8" />
                    </Box>
                    <Box className="flex items-center">
                        <Typography
                            variant="body2"
                            className="text-sm sm:text-base font-bold"
                        >
                            Este será tu Folio de preinscripción, por lo cual es
                            necesario que lo guardes y no lo muestres a nadie
                            debido a que es único y necesario para obtener la
                            información de tu preinscripción.
                        </Typography>
                    </Box>
                    {level && activeStep !== 0 && (
                        <Box className="flex justify-center mt-4">
                            <Typography
                                variant="body2"
                                className="text-sm sm:text-base font-bold text-center"
                            >
                                {`Nivel de preinscripción: ${getLevelName(
                                    level
                                )}`}
                            </Typography>
                        </Box>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};
