import React, { useState } from 'react';

import { Close } from '@material-ui/icons';
import {
    Box,
    DialogContent,
    Divider,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { StyledDialog, StyledDialogHeader } from '../styles';
import Controls from '../controls';
import palomita from '../../../public/assets/palomita.svg';

export default function ModalComponent(props) {
    const { title, subtitle, children, OpenModal, setOpenModal } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <StyledDialog
            open={OpenModal}
            fullScreen={fullScreen}
            maxWidth="lg"
            PaperProps={{
                sx: {
                    maxHeight: '90vh',
                    minHeight: '200px',
                    width: 'auto',
                    maxWidth: '95vw',
                    m: 2,
                    borderRadius: 2,
                },
            }}>
            <StyledDialogHeader>
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                    }}>
                    <Box
                        component="img"
                        src={palomita}
                        alt="Descripción de la imagen"
                        sx={{
                            width: { xs: '40px', sm: '50px', md: '60px' },
                            height: '50px',
                            flexShrink: 0,
                            display: 'flex',
                            alignSelf: 'center',
                            margin: 'auto 0',
                        }}
                    />
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                            display: 'flex',
                            mx: 2,
                            height: '40px',
                        }}
                    />
                    <Typography
                        variant="h6"
                        component="div"
                        style={{
                            flexGrow: 1,
                            fontWeight: 'bold',
                            color: '#7F8185',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}>
                        {title}
                        <Typography
                            component="p"
                            variant="subtitle1"
                            style={{
                                fontWeight: '100',
                                color: '#7F8185',
                            }}>
                            {subtitle}
                        </Typography>
                    </Typography>
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                            display: 'flex',
                            mx: 2,
                            height: '40px',
                        }}
                    />
                    <Controls.ActionButton
                        color="secondary"
                        onClick={() => setOpenModal(false)}>
                        <Close />
                    </Controls.ActionButton>
                </Box>
            </StyledDialogHeader>
            <DialogContent
                sx={{
                    width: '100%',
                    overflow: 'auto',
                    maxHeight: 'calc(200vh - 100px)',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: '#f1f1f1',
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: '#f1f1f1',
                        borderRadius: '10px',
                        '&:hover': {
                            background: '#f1f1f1',
                        },
                    },
                }}>
                {children}
            </DialogContent>
        </StyledDialog>
    );
}
