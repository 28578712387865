import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Services from '../../../../../service/Connection';
import { Grid } from '@mui/material';
import { Divider } from '@mui/material';
import {
    Autocomplete,
    Box,
    Button,
    FormControlLabel,
    TextField,
} from '@mui/material';
import generarCurp from '../../../../../helper/CurpGenerator';
import { DatePicker, esES, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { LoadingButton } from '@mui/lab';
import { SearchRounded } from '@mui/icons-material';
import { useFeedback } from '../../../../../hooks';

export const StudentInformation = ({
    handleBack,
    handleNext,
    data,
    setData,
}) => {
    ///////////////////// HOOK ////////////////////
    const feedbackApp = useFeedback();
    ///////////////////// LOCAL STATE /////////////////////
    const [addressStates, setAddressStates] = useState([]);
    const [addressMunicipalities, setAddressMunicipalities] = useState([]);
    const [addressCities, setAddressCities] = useState([]);

    const [bornStates, setBornStates] = useState([]);
    const [bornMunicipalities, setBornMunicipalities] = useState([]);
    const [bornCities, setBornCities] = useState([]);
    const [stateSelected, setStateSelected] = useState({ code: '' });

    const [loadingStatus, setLoadingStatus] = useState(false);

    const GreyBlueSwitch = (props) => (
        <Switch
            {...props}
            sx={{
                '& .MuiSwitch-switchBase': {
                    color: 'grey',
                },
                '& .MuiSwitch-switchBase.Mui-checked': {
                    color: 'blue',
                },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: 'blue',
                },
            }}
        />
    );

    const generateSizesOptions = (maxSize) => {
        const sizes = ['SM', 'MD', 'LG', 'XG'];
        for (let i = 3; i <= maxSize; i++) {
            sizes.push(i.toString());
        }
        return sizes;
    };
    const optionSize = generateSizesOptions(40);

    const level = data.preinscription_level;
    const generateGradeOptions = (level) => {
        let gradeNumbers = [];

        switch (level) {
            case 1:
                gradeNumbers = [1, 2, 3];
                break;
            case 2:
                gradeNumbers = [1, 2, 3, 4, 5, 6];
                break;
            case 3:
                gradeNumbers = [1, 2, 3];
                break;
            case 4:
                gradeNumbers = [1, 2, 3];
                break;
            default:
                gradeNumbers = [];
                break;
        }
        const suffixes = { 1: 'ro', 2: 'do', 3: 'ro' };
        return gradeNumbers.map((grade) => ({
            value: grade.toString(),
            label: `${grade}${suffixes[grade] || 'to'} Grado`,
        }));
    };
    ////////////////////// EFFECTS //////////////////////

    useEffect(() => {
        const getBornStates = async () => {
            let bornStatesData = await Services.getStates();
            setBornStates(bornStatesData.data.data);
        };
        getBornStates();

        if (data.student?.birth_city_id) {
            getBornMunicipalities({
                state_id: data.student.birth_city_id,
            });
        }

        if (data.student?.birth_municipality_id) {
            getBornCities({
                municipality_id: data.student.birth_municipality_id,
            });
        }
    }, [data.student?.birth_city_id, data.student?.birth_municipality_id]);

    const getBornMunicipalities = async (value) => {
        setBornMunicipalities([]);
        setBornCities([]);
        if (value !== null) {
            let municipality = await Services.getMunicipalitysByStateId(
                value.state_id
            );
            setBornMunicipalities(municipality.data.data);
        }
    };

    const getBornCities = async (value) => {
        setBornCities([]);
        if (value !== null) {
            let city = await Services.getCityByMunicipalityId(
                value.municipality_id
            );
            setBornCities(city.data.data);
        }
    };

    useEffect(() => {
        const getAllStates = async () => {
            let bornStates = await Services.getStates();
            setBornStates(bornStates.data.data);
            setAddressStates(bornStates.data.data);
        };
        getAllStates();

        if (data.student?.state_id) {
            getMunicipalities({ state_id: data.student.state_id });
        }
        if (data.student?.municipality_id) {
            getCities({
                municipality_id: data.student.municipality_id,
            });
        }
    }, [data.student?.state_id, data.student?.municipality_id]);

    const getMunicipalities = async (value) => {
        setAddressMunicipalities([]);
        setStateSelected(value);
        setAddressCities([]);
        if (value !== null) {
            let municipality = await Services.getMunicipalitysByStateId(
                value.state_id
            );
            setAddressMunicipalities(municipality.data.data);
        }
    };

    const getCities = async (value) => {
        setAddressCities([]);
        if (value !== null) {
            let city = await Services.getCityByMunicipalityId(
                value.municipality_id
            );
            setAddressCities(city.data.data);
        }
    };

    const searchTracedStudent = async (setFieldValue, idStudent, id) => {
        setLoadingStatus(true);
        Services.getSearchCuraStudent(idStudent, id)
            .then((pre) => {
                let studentData = pre.data;

                const genderMapping = {
                    H: 1,
                    M: 2,
                };
                const mappedGender =
                    genderMapping[studentData.gender] ||
                    studentData.gender ||
                    '';

                const fieldMapping = {
                    name: studentData.name || '',
                    last_name: studentData.last_name || '',
                    second_last_name: studentData.second_last_name || '',
                    birth_date: studentData.date_birth || '',
                    size: studentData.uniform_size || '',
                    gender: mappedGender || '',

                    birth_city_id: studentData.studentBirthStateId,
                    birth_municipality_id:
                        studentData.studentBirthMunicipalityId,
                    born_city_id: studentData.studentBirthCityId,

                    born_state_name: studentData.studentBirthState || '',
                    born_municipality_name:
                        studentData.studentAddressMunicipality || '',
                    born_city_name: studentData.studentBirthCity || '',

                    is_indigenous: studentData.is_indigenous,
                    is_stranger: studentData.is_stranger,
                    is_immigrant: studentData.is_foreign,
                    curp: studentData?.curp,
                    home_street: studentData.street || '',
                    home_neighborhood: studentData.colony || '',
                    home_external_number: studentData.outside_number || '',
                    home_internal_number: studentData.inside_number || '',
                    home_zipcode: studentData.zipcode,

                    state_id: studentData.studentAddressStateId,
                    municipality_id: studentData.studentAddressMunicipalityId,
                    city_id: studentData.studentAddressCityId,

                    address_state_name: studentData.studentAddressState || '',
                    address_municipality_name:
                        studentData.studentAddressMunicipality || '',

                    address_city_name: studentData.studentAddressCity || '',
                };

                setData((prevData) => ({
                    ...prevData,
                    student: {
                        ...prevData.student,
                        ...fieldMapping,
                    },
                }));

                Object.keys(fieldMapping).forEach((key) => {
                    setFieldValue(key, fieldMapping[key]);
                });

                feedbackApp.showFeedback({
                    title: '🎉El alumno ha sido encontrado exitosamente 🎉',
                    severity: 'success',
                });
            })
            .catch(({ err }) => {
                feedbackApp.showFeedback({
                    title: '⚠️No se encontró alumno con esa información, inténtelo nuevamente⚠️',
                    severity: 'warning',
                });
            })
            .finally(() => {
                setLoadingStatus(false);
            });
    };

    return (
        <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 2 }}>
            <Box pt={2} pb={2}>
                <Typography
                    variant="h5"
                    align="center"
                    gutterBottom
                    style={{ color: '#919399' }}>
                    📋 Datos Personales
                </Typography>
                <Typography
                    variant="subtitle1"
                    gutterBottom
                    style={{ color: '#919399' }}>
                    Por favor, completa los siguientes campos con la información
                    del estudiante. 📝 Asegúrate de que todos los datos sean
                    precisos y estén actualizados para garantizar un proceso de
                    registro sin problemas. ✅
                </Typography>
            </Box>

            <Formik
                initialValues={{
                    name: data.student?.name || '',
                    last_name: data.student?.last_name || '',
                    second_last_name: data.student?.second_last_name || '',
                    grade: data.student?.grade || '',

                    born_city_id: data.student?.born_city_id || '',
                    birth_municipality_id:
                        data.student?.birth_municipality_id || '',
                    birth_city_id: data.student?.birth_city_id || '',

                    born_state_name: data.student?.born_state_name || '',
                    born_municipality_name:
                        data.student?.born_municipality_name || '',
                    born_city_name: data.student?.born_city_name || '',

                    birth_date: data.student?.birth_date || '',
                    size: data.student?.size || '',
                    gender: data.student?.gender || '',
                    is_indigenous: data.student?.is_indigenous || 0,
                    is_stranger: data.student?.is_stranger || 0,
                    is_immigrant: data.student?.is_immigrant || 0,
                    is_foreign: 0,
                    curp: data.student?.curp || '',
                    home_street: data.student?.home_street || '',
                    home_neighborhood: data.student?.home_neighborhood || '',
                    home_external_number:
                        data.student?.home_external_number || '',
                    home_internal_number:
                        data.student?.home_internal_number || '',
                    home_zipcode: data.student?.home_zipcode || '',

                    state_id: data.student?.state_id || '',
                    municipality_id: data.student?.municipality_id || '',
                    city_id: data.student?.city_id || '',

                    address_state_name: data.student?.address_state_name || '',

                    address_municipality_name:
                        data.student?.address_municipality_name || '',

                    address_city_name: data.student?.address_city_name || '',

                    identifier: '',
                    idStudent: '',
                }}
                validationSchema={Yup.object({
                    name: Yup.string()
                        .trim()
                        .matches(
                            /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]{1,50}$/,
                            'Solo se permiten letras, acentos y espacios, hasta un máximo de 50 caracteres'
                        )
                        .required('Campo requerido'),

                    last_name: Yup.string()
                        .trim()
                        .matches(
                            /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]{1,50}$/,
                            'Solo se permiten letras, acentos y espacios, hasta un máximo de 50 caracteres'
                        )
                        .required('Campo requerido'),

                    second_last_name: Yup.string()
                        .trim()
                        .matches(
                            /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]{1,50}$/,
                            'Solo se permiten letras, acentos y espacios, hasta un máximo de 50 caracteres'
                        ),

                    born_city_id: Yup.number().required('Campo requerido'),

                    birth_municipality_id:
                        Yup.number().required('Campo requerido'),
                    birth_city_id: Yup.number().required('Campo requerido'),

                    birth_date: Yup.date()
                        .required('Campo requerido')
                        .max(
                            new Date(
                                new Date().setFullYear(
                                    new Date().getFullYear() - 3
                                )
                            ),
                            'La fecha debe de ser hace 3 años atrás'
                        ),

                    size: Yup.string().required('Campo requerido'),
                    gender: Yup.string().required('Campo requerido'),
                    grade: Yup.string()
                        .matches(
                            /^[0-9]{1,2}$/,
                            'Debe ser un número de hasta 2 dígitos'
                        )
                        .required('Campo requerido'),

                    curp: Yup.string()
                        .nullable()
                        .matches(
                            /^[a-zA-Z0-9]{18}$/,
                            'La CURP debe contener únicamente números y letras, y debe tener exactamente 18 caracteres'
                        )
                        .test(
                            'is-required',
                            'Campo requerido',
                            function (value) {
                                const {
                                    is_indigenous,
                                    is_stranger,
                                    is_immigrant,
                                } = this.parent;
                                const areAllSwitchesOff =
                                    !is_indigenous &&
                                    !is_stranger &&
                                    !is_immigrant;
                                return areAllSwitchesOff ? !!value : true;
                            }
                        )
                        .test('is-valid-curp', function (value) {
                            const {
                                name,
                                last_name,
                                second_last_name,
                                birth_date,
                                gender,
                                birth_city_id,
                                curp,
                            } = this.parent;

                            if (!value) {
                                return true;
                            }

                            if (
                                !name ||
                                !last_name ||
                                !birth_date ||
                                !gender ||
                                !birth_city_id
                            ) {
                                return this.createError({
                                    message:
                                        'Completa todos los campos requeridos antes de validar la CURP.',
                                });
                            }

                            const stateSelected = bornStates.find(
                                (state) => state.state_id === birth_city_id
                            );

                            if (!stateSelected || !stateSelected.code) {
                                return this.createError({
                                    message: 'Selecciona un estado válido.',
                                });
                            }

                            const formattedBirthDate = new Date(birth_date)
                                .toISOString()
                                .split('T')[0];

                            const curpGenerada = generarCurp(
                                name,
                                last_name,
                                second_last_name || '',
                                formattedBirthDate,
                                gender === '1' ? 'H' : 'M',
                                stateSelected.code
                            );
                            const isCurpValid = curpGenerada === curp;

                            return (
                                isCurpValid ||
                                this.createError({
                                    message:
                                        'La CURP proporcionada no es correcta.',
                                })
                            );
                        }),
                    is_indigenous: Yup.number().oneOf([0, 1]),
                    is_stranger: Yup.number().oneOf([0, 1]),
                    is_immigrant: Yup.number().oneOf([0, 1]),

                    home_street: Yup.string()
                        .matches(
                            /^[a-zA-Z0-9áéíóúÁÉÍÓÚñÑüÜ\s']{1,50}$/,
                            'Solo se permiten letras, números y espacios, hasta un máximo de 50 caracteres'
                        )
                        .required('Campo requerido'),

                    home_neighborhood: Yup.string()
                        .matches(
                            /^[a-zA-Z0-9áéíóúÁÉÍÓÚñÑüÜ\s]{1,50}$/,
                            'Solo se permiten letras y números, hasta un máximo de 50 caracteres'
                        )
                        .required('Campo requerido'),

                    home_external_number: Yup.string()
                        .matches(
                            /^[a-zA-ZñÑ0-9./\s]{1,20}$/,
                            'Solo se permiten letras, números y espacios, hasta un máximo de 20 caracteres'
                        )
                        .required('Campo requerido'),

                    home_internal_number: Yup.string().matches(
                        /^[a-zA-ZñÑ0-9./\s]{1,20}$/,
                        'Solo se permiten letras, números y espacios, hasta un máximo de 20 caracteres'
                    ),
                    home_zipcode: Yup.string()
                        .min(5, 'Debe tener exactamente 5 dígitos')
                        .max(5, 'Debe tener exactamente 5 dígitos')
                        .matches(/^[0-9]+$/, 'Debe ser un número')

                        .required('Campo requerido'),
                    state_id: Yup.number().required('Campo requerido'),
                    municipality_id: Yup.number().required('Campo requerido'),
                    city_id: Yup.number().required('Campo requerido'),

                    identifier: Yup.string()
                        .trim()
                        .test(
                            'is-valid-format',
                            'Debe ser un Folio válido o una CURP con el formato correcto',
                            (value) => {
                                if (!value) return true;
                                value = value.toUpperCase();
                                return (
                                    /^[A-Za-zñÑ0-9\-\.]+$/.test(value) ||
                                    /^[A-Z]{4}\d{6}[HM][A-Z]{5}[A-Z\d]{2}$/.test(
                                        value
                                    )
                                );
                            }
                        ),

                    idStudent: Yup.string()
                        .trim()
                        .test(
                            'is-number',
                            'Debe ser un número válido',
                            (value) => {
                                if (!value) return true;
                                return /^\d+$/.test(value);
                            }
                        ),
                })}
                onSubmit={(values) => {
                    setData((prevData) => ({
                        ...prevData,
                        student: {
                            ...prevData.student,
                            ...values,
                        },
                    }));
                    handleNext();
                }}
                validateOnChange={true}>
                {({
                    errors,
                    touched,
                    values,
                    handleChange,
                    handleSubmit,
                    isValid,
                    setFieldValue,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Typography
                            variant="h5"
                            sx={{
                                fontWeight: 'bold',
                                color: '#333',
                                marginBottom: 3,
                            }}>
                            Buscar Estudiante
                        </Typography>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={5}>
                                <Field
                                    name="idStudent"
                                    as={TextField}
                                    type="text"
                                    label="ID Cura"
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    value={values.idStudent}
                                    error={Boolean(
                                        errors.idStudent && touched.idStudent
                                    )}
                                    helperText={
                                        errors.idStudent && touched.idStudent
                                            ? errors.idStudent
                                            : `Caracteres ingresados: ${
                                                  values.idStudent?.length || 0
                                              }/20`
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <Field
                                    name="identifier"
                                    as={TextField}
                                    type="text"
                                    label="CURP o Folio"
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    value={values.identifier}
                                    inputProps={{
                                        maxLength: 18,
                                    }}
                                    error={Boolean(
                                        errors.identifier && touched.identifier
                                    )}
                                    helperText={
                                        errors.identifier && touched.identifier
                                            ? errors.identifier
                                            : `Caracteres ingresados: ${
                                                  values.identifier?.length || 0
                                              }/18`
                                    }
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={2}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                <LoadingButton
                                    startIcon={<SearchRounded />}
                                    loadingPosition="start"
                                    loading={loadingStatus}
                                    variant="contained"
                                    disabled={
                                        !values.idStudent ||
                                        !values.identifier ||
                                        Boolean(
                                            errors.idStudent ||
                                                errors.identifier
                                        ) ||
                                        loadingStatus
                                    }
                                    onClick={() =>
                                        searchTracedStudent(
                                            setFieldValue,
                                            values.idStudent,
                                            values.identifier
                                        )
                                    }
                                    sx={{
                                        height: '56px',
                                        fontSize: '1.125rem',
                                        backgroundColor: '#1976d2',
                                        color: '#fff',
                                        borderRadius: '-8px',
                                        width: '100%',
                                        '&:hover': {
                                            backgroundColor: '#1565c0',
                                        },
                                    }}>
                                    Buscar
                                </LoadingButton>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider
                                    sx={{ marginTop: 2, marginBottom: 2 }}
                                />
                            </Grid>
                        </Grid>
                        <Box mb={2} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="name"
                                    type="text"
                                    label="Nombre(s)*"
                                    as={TextField}
                                    fullWidth
                                    variant="outlined"
                                    value={values.name}
                                    onChange={(event, newValue) => {
                                        handleChange(event);
                                        setData((prevData) => ({
                                            ...prevData,
                                            student: {
                                                ...prevData.student,
                                                name: event.target.value,
                                            },
                                        }));
                                    }}
                                    inputProps={{
                                        maxLength: 50,
                                        inputMode: 'text',
                                        pattern: '[a-zA-ZáéíóúÁÉÍÓÚñÑ\\s]*',
                                    }}
                                    error={Boolean(errors.name && touched.name)}
                                    helperText={
                                        errors.name && touched.name
                                            ? errors.name
                                            : `Caracteres ingresados: ${
                                                  values.name?.length || 0
                                              }/50`
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="last_name"
                                    type="text"
                                    label="Apellido paterno*"
                                    as={TextField}
                                    fullWidth
                                    variant="outlined"
                                    value={values.last_name}
                                    onChange={(event, newValue) => {
                                        handleChange(event);
                                        setData((prevData) => ({
                                            ...prevData,
                                            student: {
                                                ...prevData.student,
                                                last_name: event.target.value,
                                            },
                                        }));
                                    }}
                                    inputProps={{
                                        maxLength: 50,
                                        inputMode: 'text',
                                        pattern: '[a-zA-ZáéíóúÁÉÍÓÚñÑ\\s]*',
                                    }}
                                    error={Boolean(
                                        errors.last_name && touched.last_name
                                    )}
                                    helperText={
                                        errors.last_name && touched.last_name
                                            ? errors.last_name
                                            : `Caracteres ingresados: ${
                                                  values.last_name?.length || 0
                                              }/50`
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Box mb={2} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="second_last_name"
                                    type="text"
                                    label="Apellido materno"
                                    as={TextField}
                                    fullWidth
                                    variant="outlined"
                                    value={values.second_last_name}
                                    onChange={(event, newValue) => {
                                        handleChange(event);
                                        setData((prevData) => ({
                                            ...prevData,
                                            student: {
                                                ...prevData.student,
                                                second_last_name:
                                                    event.target.value,
                                            },
                                        }));
                                    }}
                                    inputProps={{
                                        maxLength: 50,
                                        inputMode: 'text',
                                        pattern: '[a-zA-ZáéíóúÁÉÍÓÚñÑ\\s]*',
                                    }}
                                    error={Boolean(
                                        errors.second_last_name &&
                                            touched.second_last_name
                                    )}
                                    helperText={
                                        errors.second_last_name &&
                                        touched.second_last_name
                                            ? errors.second_last_name
                                            : `Caracteres ingresados: ${
                                                  values.second_last_name
                                                      ?.length || 0
                                              }/50`
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={Autocomplete}
                                    noOptionsText="No hay estados disponibles"
                                    value={
                                        bornStates.find(
                                            (state) =>
                                                state.state_id ===
                                                values.birth_city_id
                                        ) || null
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.state_id === value.state_id
                                    }
                                    getOptionLabel={(option) => option.title}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            getBornMunicipalities(newValue);

                                            const isExtranjero =
                                                newValue.title === 'EXTRANJERO';
                                            setFieldValue(
                                                'is_stranger',
                                                isExtranjero ? 1 : 0
                                            );
                                            setFieldValue(
                                                'birth_city_id',
                                                newValue.state_id
                                            );
                                            setFieldValue(
                                                'born_state_name',
                                                newValue.title
                                            );

                                            setFieldValue(
                                                'birth_municipality_id',
                                                ''
                                            );
                                            setFieldValue('born_city_id', '');

                                            setFieldValue('born_city_name', '');
                                            setFieldValue(
                                                'born_municipality_name',
                                                ''
                                            );
                                            setFieldValue('born_city_name', '');

                                            setData((prevData) => ({
                                                ...prevData,
                                                student: {
                                                    ...prevData.student,
                                                    birth_city_id:
                                                        newValue.state_id,
                                                    is_stranger: isExtranjero
                                                        ? 1
                                                        : 0,
                                                    born_state_name:
                                                        newValue.title,
                                                    birth_municipality_id: '',
                                                    born_city_id: '',

                                                    born_municipality_name: '',
                                                    born_city_name: '',
                                                },
                                            }));
                                        } else {
                                            setFieldValue('born_city_id', '');
                                            setFieldValue(
                                                'birth_municipality_id',
                                                ''
                                            );
                                            setFieldValue('birth_city_id', '');
                                            setFieldValue(
                                                'born_state_name',
                                                ''
                                            );
                                            setFieldValue(
                                                'born_municipality_name',
                                                ''
                                            );
                                            setFieldValue('born_city_name', '');

                                            setData((prevData) => ({
                                                ...prevData,
                                                student: {
                                                    ...prevData.student,
                                                    born_city_id: '',
                                                    birth_municipality_id: '',
                                                    birth_city_id: '',
                                                    is_stranger: 0,
                                                    born_state_name: '',
                                                    born_municipality_name: '',
                                                    born_city_name: '',
                                                },
                                            }));

                                            setBornMunicipalities([]);
                                            setBornCities([]);
                                        }
                                    }}
                                    id="controllable-birth-states-demo"
                                    options={bornStates}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{
                                                '& > img': {
                                                    mr: 2,
                                                    flexShrink: 0,
                                                },
                                            }}
                                            {...props}>
                                            {option.title} - {option.code}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            label="Lugar de nacimiento*"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Box mb={2} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={Autocomplete}
                                    noOptionsText="No hay municipios disponibles"
                                    value={
                                        bornMunicipalities.find(
                                            (municipality) =>
                                                municipality.municipality_id ===
                                                values.birth_municipality_id
                                        ) || null
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.municipality_id ===
                                        value.municipality_id
                                    }
                                    getOptionLabel={(option) => option.title}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            getBornCities(newValue);
                                            setFieldValue(
                                                'birth_municipality_id',
                                                newValue.municipality_id
                                            );
                                            setFieldValue(
                                                'born_municipality_name',
                                                newValue.title
                                            );

                                            setFieldValue('born_city_name', '');
                                            setFieldValue('born_city_id', '');

                                            setData((prevData) => ({
                                                ...prevData,
                                                student: {
                                                    ...prevData.student,
                                                    birth_municipality_id:
                                                        newValue.municipality_id,

                                                    born_municipality_name:
                                                        newValue.title,
                                                    born_city_id: '',
                                                    born_city_name: '',
                                                },
                                            }));
                                        } else {
                                            setFieldValue(
                                                'birth_municipality_id',
                                                ''
                                            );
                                            setFieldValue('birth_city_id', '');

                                            setFieldValue(
                                                'born_municipality_name',
                                                ''
                                            );
                                            setFieldValue('born_city_name', '');

                                            setData((prevData) => ({
                                                ...prevData,
                                                student: {
                                                    ...prevData.student,
                                                    birth_municipality_id: '',
                                                    born_municipality_name: '',
                                                    birth_city_id: '',
                                                    born_city_name: '',
                                                },
                                            }));

                                            setBornCities([]);
                                        }
                                    }}
                                    id="controllable-birth-municipalities-demo"
                                    options={bornMunicipalities}
                                    disabled={bornMunicipalities.length === 0}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{
                                                '& > img': {
                                                    mr: 2,
                                                    flexShrink: 0,
                                                },
                                            }}
                                            {...props}>
                                            {option.title}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            sx={{ marginBottom: 1 }}
                                            label="Elige un municipio*"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={Autocomplete}
                                    noOptionsText="No hay ciudades disponibles"
                                    value={
                                        bornCities.find(
                                            (city) =>
                                                city.city_id ===
                                                values.born_city_id
                                        ) || null
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.city_id === value.city_id
                                    }
                                    getOptionLabel={(option) => option.title}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setFieldValue(
                                                'born_city_id',
                                                newValue.city_id
                                            );

                                            setFieldValue(
                                                'born_city_name',
                                                newValue.title
                                            );

                                            setData((prevData) => ({
                                                ...prevData,
                                                student: {
                                                    ...prevData.student,
                                                    born_city_id:
                                                        newValue.city_id,
                                                    born_city_name:
                                                        newValue.title,
                                                },
                                            }));
                                        } else {
                                            setFieldValue('born_city_id', '');
                                            setFieldValue('born_city_name', '');

                                            setData((prevData) => ({
                                                ...prevData,
                                                student: {
                                                    ...prevData.student,
                                                    born_city_id: '',
                                                    born_city_name: '',
                                                },
                                            }));
                                        }
                                    }}
                                    id="controllable-birth-cities-demo"
                                    options={bornCities}
                                    disabled={bornCities.length === 0}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{
                                                '& > img': {
                                                    mr: 2,
                                                    flexShrink: 0,
                                                },
                                            }}
                                            key={option.city_id}
                                            {...props}>
                                            {option.title}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            label="Elige una ciudad*"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Box mb={2} />
                        <Grid container spacing={2}>
                            <LocalizationProvider
                                localeText={
                                    esES.components.MuiLocalizationProvider
                                        .defaultProps.localeText
                                }
                                adapterLocale="es-MX"
                                dateAdapter={AdapterLuxon}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                    <DatePicker
                                        // sx={{ width: 375 }}
                                        label="Fecha de nacimiento*"
                                        value={
                                            values.birth_date
                                                ? DateTime.fromISO(
                                                      values.birth_date
                                                  )
                                                : null
                                        }
                                        onChange={(date) => {
                                            if (date) {
                                                const formattedDate =
                                                    date.toFormat('yyyy-MM-dd');
                                                handleChange({
                                                    target: {
                                                        name: 'birth_date',
                                                        value: formattedDate,
                                                    },
                                                });
                                                setData((prevData) => ({
                                                    ...prevData,
                                                    student: {
                                                        ...prevData.student,
                                                        birth_date:
                                                            formattedDate,
                                                    },
                                                }));
                                            }
                                        }}
                                        maxDate={DateTime.now().minus({
                                            years: 3,
                                        })}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(
                                                    errors.birth_date &&
                                                        touched.birth_date
                                                )}
                                                helperText={
                                                    errors.birth_date &&
                                                    touched.birth_date
                                                        ? errors.birth_date
                                                        : ''
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                sx={{ flex: 1 }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </LocalizationProvider>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="size"
                                    as={TextField}
                                    select
                                    label="Talla*"
                                    fullWidth
                                    variant="outlined"
                                    value={values.size}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setData((prevData) => ({
                                            ...prevData,
                                            student: {
                                                ...prevData.student,
                                                size: e.target.value,
                                            },
                                        }));
                                    }}
                                    error={Boolean(errors.size && touched.size)}
                                    helperText={
                                        errors.size && touched.size
                                            ? errors.size
                                            : ''
                                    }
                                    SelectProps={{
                                        native: true,
                                    }}>
                                    <option value="" />
                                    {optionSize.map((size) => (
                                        <option key={size} value={size}>
                                            {size}
                                        </option>
                                    ))}
                                </Field>
                            </Grid>
                        </Grid>
                        <Box mb={2} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="gender"
                                    as={TextField}
                                    select
                                    label="Género*"
                                    fullWidth
                                    variant="outlined"
                                    value={values.gender}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setData((prevData) => ({
                                            ...prevData,
                                            student: {
                                                ...prevData.student,
                                                gender: e.target.value,
                                            },
                                        }));
                                    }}
                                    error={Boolean(
                                        errors.gender && touched.gender
                                    )}
                                    helperText={
                                        errors.gender && touched.gender
                                            ? errors.gender
                                            : ''
                                    }
                                    SelectProps={{
                                        native: true,
                                    }}>
                                    <option value="" hidden disabled />
                                    <option value={1}>Masculino</option>
                                    <option value={2}>Femenino</option>
                                </Field>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="grade"
                                    as={TextField}
                                    select
                                    label="Grado al que se va a inscribir*"
                                    fullWidth
                                    variant="outlined"
                                    value={values.grade}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setData((prevData) => ({
                                            ...prevData,
                                            student: {
                                                ...prevData.student,
                                                grade: e.target.value,
                                            },
                                        }));
                                    }}
                                    error={Boolean(
                                        errors.grade && touched.grade
                                    )}
                                    helperText={
                                        errors.grade && touched.grade
                                            ? errors.grade
                                            : ''
                                    }
                                    SelectProps={{
                                        native: true,
                                    }}>
                                    <option value=""></option>
                                    {generateGradeOptions(level).map(
                                        (grade) => (
                                            <option
                                                key={grade.value}
                                                value={grade.value}>
                                                {' '}
                                                {grade.label}
                                            </option>
                                        )
                                    )}
                                </Field>
                            </Grid>
                        </Grid>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 2,
                            }}>
                            <FormControlLabel
                                control={
                                    <GreyBlueSwitch
                                        checked={values.is_indigenous === 1}
                                        onChange={(event) => {
                                            setFieldValue(
                                                'is_indigenous',
                                                event.target.checked ? 1 : 0
                                            );

                                            setData((prevData) => ({
                                                ...prevData,
                                                student: {
                                                    ...prevData.student,
                                                    is_indigenous: event.target
                                                        .checked
                                                        ? 1
                                                        : 0,
                                                },
                                            }));
                                        }}
                                    />
                                }
                                label="Indígena"
                            />
                            <FormControlLabel
                                control={
                                    <GreyBlueSwitch
                                        checked={values.is_stranger === 1}
                                        onChange={(event) => {
                                            const isStranger = event.target
                                                .checked
                                                ? 1
                                                : 0;
                                            setFieldValue(
                                                'is_stranger',
                                                isStranger
                                            );
                                            setData((prevData) => ({
                                                ...prevData,
                                                student: {
                                                    ...prevData.student,
                                                    is_stranger: isStranger,
                                                },
                                            }));

                                            if (isStranger) {
                                                setFieldValue(
                                                    'birth_city_id',
                                                    34
                                                );

                                                setFieldValue(
                                                    'birth_municipality_id',
                                                    2476
                                                );
                                                setFieldValue(
                                                    'born_city_id',
                                                    299790
                                                );

                                                setFieldValue(
                                                    'born_state_name',
                                                    'EXTRANJERO'
                                                );
                                                setFieldValue(
                                                    'born_municipality_name',
                                                    'EXTRANJERO'
                                                );
                                                setFieldValue(
                                                    'born_city_name',
                                                    'EXTRANJERO'
                                                );

                                                setData((prevData) => ({
                                                    ...prevData,
                                                    student: {
                                                        ...prevData.student,
                                                        birth_city_id: 34,
                                                        birth_municipality_id: 2476,
                                                        born_city_id: 299790,
                                                        born_state_name:
                                                            'EXTRANJERO',
                                                        born_municipality_name:
                                                            'EXTRANJERO',
                                                        born_city_name:
                                                            'EXTRANJERO',
                                                    },
                                                }));
                                            } else {
                                                setFieldValue(
                                                    'born_city_id',
                                                    ''
                                                );
                                                setFieldValue(
                                                    'birth_municipality_id',
                                                    ''
                                                );
                                                setFieldValue(
                                                    'birth_city_id',
                                                    ''
                                                );
                                                setFieldValue(
                                                    'born_state_name',
                                                    ''
                                                );
                                                setFieldValue(
                                                    'born_municipality_name',
                                                    ''
                                                );
                                                setFieldValue(
                                                    'born_city_name',
                                                    ''
                                                );

                                                setBornCities([]);
                                                setBornMunicipalities([]);

                                                setData((prevData) => ({
                                                    ...prevData,
                                                    student: {
                                                        ...prevData.student,
                                                        born_city_id: '',
                                                        birth_municipality_id:
                                                            '',
                                                        birth_city_id: '',
                                                        born_state_name: '',
                                                        born_municipality_name:
                                                            '',
                                                        born_city_name: '',
                                                    },
                                                }));
                                            }
                                        }}
                                    />
                                }
                                label="Extranjero"
                            />
                            <FormControlLabel
                                control={
                                    <GreyBlueSwitch
                                        checked={values.is_immigrant === 1}
                                        onChange={(event) => {
                                            setFieldValue(
                                                'is_immigrant',
                                                event.target.checked ? 1 : 0
                                            );
                                            setData((prevData) => ({
                                                ...prevData,
                                                student: {
                                                    ...prevData.student,
                                                    is_immigrant: event.target
                                                        .checked
                                                        ? 1
                                                        : 0,
                                                },
                                            }));
                                        }}
                                    />
                                }
                                label="Inmigrante"
                            />
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Field
                                    name="curp"
                                    type="text"
                                    label={
                                        !values.is_indigenous &&
                                        !values.is_stranger &&
                                        !values.is_immigrant
                                            ? 'CURP*'
                                            : 'CURP'
                                    }
                                    as={TextField}
                                    fullWidth
                                    variant="outlined"
                                    value={values.curp}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        handleChange(
                                            {
                                                target: {
                                                    name: 'curp',
                                                    value: value.toUpperCase(),
                                                },
                                            },
                                            setData((prevData) => ({
                                                ...prevData,
                                                student: {
                                                    ...prevData.student,
                                                    curp: value.toUpperCase(),
                                                },
                                            }))
                                        );
                                    }}
                                    inputProps={{ maxLength: 18 }}
                                    error={Boolean(errors.curp && touched.curp)}
                                    helperText={
                                        errors.curp && touched.curp
                                            ? errors.curp
                                            : `Caracteres ingresados: ${
                                                  values.curp?.length || 0
                                              }/18`
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Box pt={2} pb={2}>
                            <Typography
                                variant="h5"
                                align="center"
                                gutterBottom
                                style={{ color: '#919399' }}>
                                🏠 Datos del Domicilio
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                gutterBottom
                                style={{ color: '#919399' }}>
                                Por favor, completa los siguientes campos con la
                                información de tu domicilio. 📍 Asegúrate de que
                                todos los datos sean precisos y estén
                                actualizados.
                            </Typography>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="home_street"
                                    type="text"
                                    label="Calle*"
                                    as={TextField}
                                    fullWidth
                                    variant="outlined"
                                    value={values.home_street}
                                    onChange={(event, newValue) => {
                                        handleChange(event);
                                        setData((prevData) => ({
                                            ...prevData,
                                            student: {
                                                ...prevData.student,
                                                home_street: event.target.value,
                                            },
                                        }));
                                    }}
                                    inputProps={{
                                        maxLength: 50,
                                        inputMode: 'text',
                                        street: '[a-zA-Z0-9áéíóúÁÉÍÓÚñÑ\\s]*',
                                    }}
                                    error={Boolean(
                                        errors.home_street &&
                                            touched.home_street
                                    )}
                                    helperText={
                                        errors.home_street &&
                                        touched.home_street
                                            ? errors.home_street
                                            : `Caracteres ingresados: ${
                                                  values.home_street?.length ||
                                                  0
                                              }/50`
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="home_external_number"
                                    type="text"
                                    label="Número exterior*"
                                    as={TextField}
                                    fullWidth
                                    variant="outlined"
                                    value={values.home_external_number}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setData((prevData) => ({
                                            ...prevData,
                                            student: {
                                                ...prevData.student,
                                                home_external_number:
                                                    e.target.value,
                                            },
                                        }));
                                    }}
                                    inputProps={{
                                        maxLength: 20,
                                    }}
                                    error={Boolean(
                                        errors.home_external_number &&
                                            touched.home_external_number
                                    )}
                                    helperText={
                                        errors.home_external_number &&
                                        touched.home_external_number
                                            ? errors.home_external_number
                                            : `Caracteres ingresados: ${
                                                  values.home_external_number
                                                      ?.length || 0
                                              }/20`
                                    }
                                />
                            </Grid>

                            {/* Número interior */}
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="home_internal_number"
                                    type="text"
                                    label="Número interior"
                                    as={TextField}
                                    fullWidth
                                    variant="outlined"
                                    value={values.home_internal_number}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setData((prevData) => ({
                                            ...prevData,
                                            student: {
                                                ...prevData.student,
                                                home_internal_number:
                                                    e.target.value,
                                            },
                                        }));
                                    }}
                                    inputProps={{
                                        maxLength: 20,
                                    }}
                                    error={Boolean(
                                        errors.home_internal_number &&
                                            touched.home_internal_number
                                    )}
                                    helperText={
                                        errors.home_internal_number &&
                                        touched.home_internal_number
                                            ? errors.home_internal_number
                                            : `Caracteres ingresados: ${
                                                  values.home_internal_number
                                                      ?.length || 0
                                              }/20`
                                    }
                                />
                            </Grid>

                            {/* Colonia */}
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="home_neighborhood"
                                    type="text"
                                    label="Colonia*"
                                    as={TextField}
                                    fullWidth
                                    variant="outlined"
                                    value={values.home_neighborhood}
                                    onChange={(event, newValue) => {
                                        handleChange(event);
                                        setData((prevData) => ({
                                            ...prevData,
                                            student: {
                                                ...prevData.student,
                                                home_neighborhood:
                                                    event.target.value,
                                            },
                                        }));
                                    }}
                                    inputProps={{
                                        maxLength: 50,
                                        inputMode: 'text',
                                        meighbothood:
                                            /^[a-zA-Z0-9áéíóúÁÉÍÓÚñÑüÜ\s]{1,50}$/,
                                    }}
                                    error={Boolean(
                                        errors.home_neighborhood &&
                                            touched.home_neighborhood
                                    )}
                                    helperText={
                                        errors.home_neighborhood &&
                                        touched.home_neighborhood
                                            ? errors.home_neighborhood
                                            : `Caracteres ingresados: ${
                                                  values.home_neighborhood
                                                      ?.length || 0
                                              }/50`
                                    }
                                />
                            </Grid>

                            {/* Código Postal */}
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="home_zipcode"
                                    label="Código Postal*"
                                    as={TextField}
                                    fullWidth
                                    variant="outlined"
                                    value={values.home_zipcode}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setData((prevData) => ({
                                            ...prevData,
                                            student: {
                                                ...prevData.student,
                                                home_zipcode: e.target.value,
                                            },
                                        }));
                                    }}
                                    inputProps={{
                                        maxLength: 5,
                                        onKeyPress: (e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        },
                                    }}
                                    error={Boolean(
                                        errors.home_zipcode &&
                                            touched.home_zipcode
                                    )}
                                    helperText={
                                        errors.home_zipcode &&
                                        touched.home_zipcode
                                            ? errors.home_zipcode
                                            : `Caracteres ingresados: ${
                                                  values.home_zipcode?.length ||
                                                  0
                                              }/5`
                                    }
                                />
                            </Grid>

                            {/* Estado */}
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={Autocomplete}
                                    noOptionsText="No hay estados disponibles"
                                    value={
                                        addressStates.find(
                                            (state) =>
                                                state.state_id ===
                                                values.state_id
                                        ) || null
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.state_id === value?.state_id
                                    }
                                    getOptionLabel={(option) => option.title}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            getMunicipalities(newValue);
                                            setFieldValue(
                                                'state_id',
                                                newValue.state_id
                                            );
                                            setFieldValue(
                                                'address_state_name',
                                                newValue.title
                                            );

                                            setData((prevData) => ({
                                                ...prevData,
                                                student: {
                                                    ...prevData.student,
                                                    address_state_name:
                                                        newValue.title,
                                                    state_id: newValue.state_id,
                                                },
                                            }));
                                        } else {
                                            setFieldValue('state_id', '');
                                            setFieldValue(
                                                'municipality_id',
                                                ''
                                            );
                                            setFieldValue('city_id', '');
                                            setAddressMunicipalities([]);
                                            setAddressCities([]);
                                        }
                                    }}
                                    id="controllable-states-demo"
                                    options={addressStates}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{
                                                '& > img': {
                                                    mr: 2,
                                                    flexShrink: 0,
                                                },
                                            }}
                                            {...props}>
                                            {option.title} - {option.code}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            label="Elige un estado*"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>

                            {/* Municipio */}
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={Autocomplete}
                                    noOptionsText="No hay municipios disponibles"
                                    value={
                                        addressMunicipalities.find(
                                            (municipality) =>
                                                municipality.municipality_id ===
                                                values.municipality_id
                                        ) || null
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.municipality_id ===
                                        value?.municipality_id
                                    }
                                    getOptionLabel={(option) => option.title}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            getCities(newValue);
                                            setFieldValue(
                                                'municipality_id',
                                                newValue.municipality_id
                                            );
                                            setFieldValue(
                                                'address_municipality_name',
                                                newValue.title
                                            );
                                            setData((prevData) => ({
                                                ...prevData,
                                                student: {
                                                    ...prevData.student,
                                                    address_municipality_name:
                                                        newValue.title,
                                                    municipality_id:
                                                        newValue.municipality_id,
                                                },
                                            }));
                                        } else {
                                            setFieldValue(
                                                'municipality_id',
                                                ''
                                            );
                                            setFieldValue('city_id', '');
                                            setAddressCities([]);
                                        }
                                    }}
                                    id="controllable-states-demo"
                                    options={addressMunicipalities}
                                    disabled={
                                        addressMunicipalities.length === 0
                                    }
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{
                                                '& > img': {
                                                    mr: 2,
                                                    flexShrink: 0,
                                                },
                                            }}
                                            {...props}>
                                            {option.title}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            sx={{ marginBottom: 1 }}
                                            label="Elige un municipio*"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>

                            {/* Ciudad */}
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={Autocomplete}
                                    noOptionsText="No hay ciudades disponibles"
                                    value={
                                        addressCities.find(
                                            (city) =>
                                                city.city_id === values.city_id
                                        ) || null
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.city_id === value?.city_id
                                    }
                                    getOptionLabel={(option) => option.title}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setFieldValue(
                                                'city_id',
                                                newValue.city_id
                                            );
                                            setFieldValue(
                                                'address_city_name',
                                                newValue.title
                                            );
                                            setData((prevData) => ({
                                                ...prevData,
                                                student: {
                                                    ...prevData.student,
                                                    address_city_name:
                                                        newValue.title,
                                                    city_id: newValue.city_id,
                                                },
                                            }));
                                        } else {
                                            setFieldValue('city_id', '');
                                        }
                                    }}
                                    id="controllable-states-demo"
                                    options={addressCities}
                                    disabled={addressCities.length === 0}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{
                                                '& > img': {
                                                    mr: 2,
                                                    flexShrink: 0,
                                                },
                                            }}
                                            key={option.city_id}
                                            {...props}>
                                            {option.title}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            label="Elige una ciudad*"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Box mb={2} />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: 2,
                            }}>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    handleBack();
                                }}>
                                Regresar
                            </Button>

                            <Button
                                type="submit"
                                variant="contained"
                                disabled={!isValid}
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth',
                                    });
                                }}>
                                Siguiente
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};
