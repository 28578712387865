import { Button, Card } from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import React, { useState } from 'react';
import NoDataOverlay from '../../../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../../../components/utilities/GridToolBar';
import CustomPagination from '../../../../components/utilities/CustomPagination';
import { PrescriptionsColumns } from '../../../components/columns';
import ModalComponent from '../../../../components/ReusableComponents/modal';
import { Documents } from './Documents';
import { useSelector } from 'react-redux';
import { PREISCRIPTIONS_STATUS } from '../../../../service/const';
import PopperComponent from '../../../../components/ReusableComponents/popper';
import { Status } from './Status';
import { selectPostulationsFullData } from '../../../store/itemsSlice';
import { Cancel, CheckCircle, Schedule } from '@mui/icons-material';

export const PrescriptionsTable = () => {
    ////////////////////////// LOCAL STATE //////////////////////////

    const [documents, setDocuments] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const [arrowRef, setArrowRef] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [statusFilter, setStatusFilter] = useState(PREISCRIPTIONS_STATUS.ALL);

    //////////////////////// SHARE STATE ////////////////////////

    const allRows = useSelector(selectPostulationsFullData);

    const rows = allRows.filter((row) => {
        if (statusFilter === PREISCRIPTIONS_STATUS.ALL) return allRows;
        return row.status === statusFilter;
    });

    ////////////////////////// ACTIONS //////////////////////////

    class Actions {
        handleViewDocuments = (document) => {
            setDocuments(document);
            setIsModalOpen(true);
        };

        handleCloseModal = () => {
            setDocuments([]);
            setIsModalOpen(false);
        };

        handleOpenPopover = (event, row) => {
            setAnchorEl(event.currentTarget);
            setSelectedStudent(row);
        };

        handleClosePopover = () => {
            setAnchorEl(null);
            setSelectedStudent();
        };

        handleTabChange = (event) => {
            setStatusFilter(event);
        };
    }
    const actions = new Actions();

    //////////////////////// COLUMNS ////////////////////////

    const columns = PrescriptionsColumns({
        onViewDocuments: actions.handleViewDocuments,
        onViewPopover: actions.handleOpenPopover,
    });

    ////////////////////////// TABS //////////////////////////

    const tabs = [
        {
            label: 'Todas',
            value: PREISCRIPTIONS_STATUS.ALL,
            color: '#1565c0',
            chips: statusFilter,

            onClick: () => actions.handleTabChange(PREISCRIPTIONS_STATUS.ALL),
        },
        {
            label: 'Pendientes',
            value: PREISCRIPTIONS_STATUS.CREATED,
            color: '#e65100',
            chips: statusFilter,
            icon: <Schedule />,

            onClick: () =>
                actions.handleTabChange(PREISCRIPTIONS_STATUS.CREATED),
        },
        {
            label: 'Aceptadas',
            value: PREISCRIPTIONS_STATUS.ACCEPTED,
            color: '#1b5e20',
            chips: statusFilter,
            icon: <CheckCircle />,
            onClick: () =>
                actions.handleTabChange(PREISCRIPTIONS_STATUS.ACCEPTED),
        },
        {
            label: 'Rechazadas',
            value: PREISCRIPTIONS_STATUS.REJECTED,
            color: '#c62828',
            chips: statusFilter,
            icon: <Cancel />,

            onClick: () =>
                actions.handleTabChange(PREISCRIPTIONS_STATUS.REJECTED),
        },
    ];

    return (
        <>
            <Card
                sx={{
                    color: 'white',
                    borderRadius: '15px',
                    width: '100%',
                    height: '100%',
                    marginTop: '20px',
                }}>
                <DataGrid
                    rows={rows}
                    getRowId={(row) => row.postulation_id}
                    columns={columns}
                    pageSize={10}
                    disableSelectionOnClick
                    autoHeight
                    localeText={{
                        ...esES.components.MuiDataGrid.defaultProps.localeText,
                        toolbarColumns: '',
                        toolbarFilters: '',
                        toolbarDensity: '',
                        toolbarExport: '',
                    }}
                    components={{
                        NoRowsOverlay: NoDataOverlay,
                        NoResultsOverlay: NoDataOverlay,
                        Toolbar: GridToolBar,
                        Pagination: CustomPagination,
                    }}
                    componentsProps={{
                        panel: {
                            placement: 'bottom-end',
                        },

                        noResultsOverlay: {
                            message:
                                'No se encontraron resultados para la búsqueda',
                        },
                        noRowsOverlay: {
                            message: 'No hay datos para mostrar en esta tabla',
                        },
                        toolbar: {
                            tabs: tabs,
                        },
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        },
                    }}
                />
            </Card>

            <ModalComponent
                OpenModal={isModalOpen}
                setOpenModal={setIsModalOpen}
                title={'Documentos'}
                subtitle={'Documentos proporcionados por el alumno'}>
                <Documents
                    documents={documents}
                    onDocumentUpdate={actions.handleDocumentUpdate}
                />
            </ModalComponent>

            <PopperComponent
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                title={'Cambiar Estado'}
                subtitle={'Selecciona a que estado cambiará...'}
                arrowRef={arrowRef}
                setArrowRef={setArrowRef}>
                <Status
                    onClosePopover={actions.handleClosePopover}
                    selectedStudent={selectedStudent}
                />
            </PopperComponent>
        </>
    );
};
